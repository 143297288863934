import {useState, useEffect} from 'react'

const QuickReplies = ({setQR}) => {
  const [buttonTile1, setButtonTile1] = useState('')
  const [checked1, setChecked1] = useState(false)

  const [buttonTile2, setButtonTile2] = useState('')
  const [checked2, setChecked2] = useState(false)

  const [buttonTile3, setButtonTile3] = useState('')
  const [checked3, setChecked3] = useState(false)

  useEffect(() => {
    let jsQR = []
    if (checked1) if (!!buttonTile1) jsQR = [{type: 'QUICK_REPLY', text: buttonTile1}]
    if (checked2) if (!!buttonTile2) jsQR = [...jsQR, {type: 'QUICK_REPLY', text: buttonTile2}]
    if (checked3) if (!!buttonTile3) jsQR = [...jsQR, {type: 'QUICK_REPLY', text: buttonTile3}]

    setQR(jsQR)
  }, [buttonTile1, checked1, buttonTile2, checked2, setQR, checked3, buttonTile3])

  return (
    <>
      <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
        <div className='row'>
          <div className='col-xl-4'>
            <div className='col-xl-12'>
              <span className='fs-6 text-gray-800 fw-semibold me-2 required'>Respuesta 1</span>
            </div>
            <div className='d-flex justify-content-start flex-wrap align-items-center align-self-center'>
              <div className='me-2'>
                <input
                  className='form-control  mb-3 mb-lg-0'
                  type='text'
                  placeholder='Titulo de la respuesta'
                  value={buttonTile1}
                  onChange={(e) => setButtonTile1(e.target.value)}
                  disabled={!checked1}
                ></input>
              </div>

              <div className=''>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={checked1}
                    onChange={() => setChecked1(!checked1)}
                  />
                  <span className='form-check-label'>Habilitar</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='col-xl-12'>
              <span className='fs-6 text-gray-800 fw-semibold me-2 required'>Respuesta 2</span>
            </div>
            <div className='d-flex justify-content-start flex-wrap align-items-center align-self-center'>
              <div className='me-2'>
                <input
                  className='form-control  mb-3 mb-lg-0'
                  type='text'
                  placeholder='Titulo de la respuesta'
                  value={buttonTile2}
                  onChange={(e) => setButtonTile2(e.target.value)}
                  disabled={!checked2}
                ></input>
              </div>

              <div className=''>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={checked2}
                    onChange={() => setChecked2(!checked2)}
                  />
                  <span className='form-check-label'>Habilitar</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4'>
            <div className='col-xl-12'>
              <span className='fs-6 text-gray-800 fw-semibold me-2 required'>Respuesta 3</span>
            </div>
            <div className='d-flex justify-content-start flex-wrap align-items-center align-self-center'>
              <div className='me-2'>
                <input
                  className='form-control  mb-3 mb-lg-0'
                  type='text'
                  placeholder='Titulo de la respuesta'
                  value={buttonTile3}
                  onChange={(e) => setButtonTile3(e.target.value)}
                  disabled={!checked3}
                ></input>
              </div>

              <div className=''>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={checked3}
                    onChange={() => setChecked3(!checked3)}
                  />
                  <span className='form-check-label'>Habilitar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {QuickReplies}
