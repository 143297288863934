import { useEffect, useState, useMemo } from 'react'
import { Performance, DashboardCards, Campaigns, Usuariosgraph, CampaignsList } from '../../../api/Api'
import { InfoCard } from 'app/components/infoCard/infoCard'
import { Table } from '../../../components/table/Table'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as am5 from '@amcharts/amcharts5'
import * as am4 from '@amcharts/amcharts4/core'
import { getOptions, getPeriodList } from '../../../Utilities/Utils'
import { SelectList } from '../../../components/selectList/SelectList'
import { SlicedChart } from '../../../components/charts/slicedChart'
import { GaugeChart } from '../../../components/charts/gaugeChart'
import { ChannelSelectList } from 'app/components/selectList/ChannelSelectList'

const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

const CampaingPage = ({ selected_customerIdSaved }) => {
  const [graphData1, setGraphData1] = useState([])
  const [graphData, setGraphData] = useState([])
  const [cardData, setCardData] = useState(null)
  const [tableData, setTableData] = useState([])
  const animatedComponents = makeAnimated()
  const [periodo, setPeriodo] = useState(getPeriodList()[2])
  const [campaing, setCampaing] = useState(null)
  const [refrestCampaingList, setRefrestCampaingList] = useState(true)

  const [loadingCampaing, setLoadingCampaing] = useState(true)

  // const userId = user.id.toString()

  // const [options, setOptions] = useState([])
  // const [channelSelected, setChannelSelected] = useState()

  const handleChangeCampaing = (campaing) => {
    setCampaing(campaing)
    // getOptions(selected_customerIdSaved, campaing.value, setOptions, setChannelSelected, user.api_token)
  }

  useEffect(() => {
    setRefrestCampaingList(true)
    if (periodo?.value && campaing?.value) {
      ; (async () => {
        setLoadingCampaing(true)
        const [
          cardDataResponse,
          tableDataResponse,
          apiResponse1,
          apiResponse2
        ] = await Promise.all([
          DashboardCards({
            token: user.api_token,
            periodId: periodo.value,
            campaingId: campaing.value,
            channel: ''
          }),
          Campaigns({
            token: user.api_token,
          }),
          Performance({
            token: user.api_token,
            periodId: periodo.value,
            campaingId: campaing.value,
            channel: ''
          }),
          Usuariosgraph({
            token: user.api_token,
            periodId: periodo.value,
            campaingId: campaing.value,
            channel: ''
          })
        ]);

        setCardData(cardDataResponse.data);
        setTableData((tableDataResponse.table) ? tableDataResponse.table : [])
        setGraphData(apiResponse1.data?.graph || []);
        setGraphData1(apiResponse2.data?.graph || []);
        setLoadingCampaing(false)
      })()
    }

  }, [periodo, campaing, selected_customerIdSaved])

  // useEffect(() => {
  //   if (options.length === 0 && campaing?.value) {
  //     ; (async () => {
  //       await getOptions(selected_customerIdSaved, campaing.value, setOptions, setChannelSelected, user.api_token)
  //     })()
  //   }
  // }, [campaing, selected_customerIdSaved, options])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        className: 'min-w-100px',
      },
      {
        Header: 'Campaing',
        accessor: 'campaing',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      {
        Header: 'Finalización',
        accessor: 'finalizacion',
        className: ' min-w-100px text-center',

        Cell: ({ cell }) => <span>{cell.row.values.finalizacion}%</span>,

      },
      {
        Header: 'Satisfacción',
        accessor: 'satisfaccion',
        className: ' min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-success fs-7 fw-bold'>

            <i
              className='text-success fa-regular fa-thumbs-up'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.satisfaccion}
          </span>

        ),
      },
      {
        Header: 'Enganche',
        accessor: 'notaPromedio',
        className: ' min-w-100px text-center',
        Cell: ({ cell }) => <span>{cell.row.values.notaPromedio}%</span>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: ' min-w-100px text-center',
        Cell: ({ cell }) => (
          <span value={cell.row.values.status} className='badge badge-light-info fw-semibold me-1'>
            {cell.row.values.status}
          </span>
        ),
      }
    ],
    []
  )

  return (
    <>
      <div className='d-flex align-items-center justify-content-end gap-2 gap-lg-3 pb-6 pt-0'>
        <span className='text-gray-500 fs-7 me-2 fw-bold'>Periodo</span>
        <Select
          classNamePrefix='filter'
          components={animatedComponents}
          defaultValue={getPeriodList()[2]}
          isMulti={false}
          closeMenuOnSelect={true}
          options={getPeriodList()}
          onChange={setPeriodo}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: '#000',
              font: '#000',
              primary25: '#f1f1f4',
              primary: '#3615af',
              neutral80: '#000',
              color: '#000',
            },
          })}
        />
        <span className='text-gray-500 fs-7 me-2 fw-bold'>Campañas</span>
        <SelectList
          title={''}
          apiService={CampaignsList}
          args={{ token: user.api_token }}
          conditions={refrestCampaingList}
          setRefresh={setRefrestCampaingList}
          value={campaing}
          handleChange={(company) => handleChangeCampaing(company)}
          multiple={false}
          defaultSetterFunction={setCampaing}
        />
      </div>

      {cardData && (
        <div className='row g-5 g-xl-8 mb-5'>
          <div className='col-xl-3'>
            <InfoCard
              value={`${cardData.finalizacion.value}%`}
              title='Finalizacion'
              subtitle='Promedio de Estudiantes'
              link='#'
              classes={{
                cardClasses: 'card bg-body-white',
                subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                titleClasses: 'fw-bold mb-1 fs-6',
                value1Classes: 'text-gray-900 fw-bolder',
                value2Classes: 'text-gray-400 fw-bold',
              }}
            />
          </div>

          <div className='col-xl-3'>
            <InfoCard
              value={cardData.satisfaccion.value}
              title='Satisfacción'
              subtitle='de Estudiantes'
              link={'#'}
              classes={{
                cardClasses: 'card bg-body-white',
                subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                titleClasses: 'fw-bold mb-1 fs-6',
                value1Classes: 'text-gray-900 fw-bolder',
                value2Classes: 'text-gray-400 fw-bold',
              }}
            />
          </div>

          <div className='col-xl-3'>
            <InfoCard
              value={`${cardData.notaPromedio.value}%`}
              title='Enganche'
              subtitle='de Estudiantes'
              link={'#'}
              classes={{
                cardClasses: 'card bg-body-white',
                subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                titleClasses: 'fw-bold mb-1 fs-6',
                value1Classes: 'text-gray-900 fw-bolder',
                value2Classes: 'text-gray-400 fw-bold',
              }}
            />
          </div>

          <div className='col-xl-3'>
            <InfoCard
              value={cardData.horasFaltantesCampaniaReciente?.value}
              title='Dias faltantes'
              subtitle='Campaing más reciente'
              link={'#'}
              classes={{
                cardClasses: 'card bg-body-white',
                subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                titleClasses: 'fw-bold mb-1 fs-6',
                value1Classes: 'text-gray-900 fw-bolder',
                value2Classes: 'text-gray-400 fw-bold',
              }}
            />
          </div>
        </div>
      )}
      {!loadingCampaing && (
        <>
          <div className='app-container d-flex flex-stack mb-5 mt-10'>
            <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
                Todas las Campañas
              </h1>
            </div>
          </div>

          <div className='row g-5 g-xl-8'>
            <div className='col-xl-12'>

              <Table
                className=''
                data={tableData}
                columns={columns}
                title='Campañas'
                subtitle='Ultimas campañas realizadas'
                isLoading={loadingCampaing}
              />

            </div>
          </div>
        </>
      )}

      {!loadingCampaing && (
        <div className='row g-5 g-xl-8'>

          {graphData && (
            <div className='col-xl-6 margin-bottom'>
              <GaugeChart
                id={'performance_CampaingTab'}
                title={`Performance${graphData[0]?.category ? ': ' + graphData[0]?.category : ''}`}
                noDataMessage='No se tiene progreso'
                className={''}
                height={'300px'}
                settings={{
                  colors: [
                    { color: am5.color('#00cb9b') },
                    { color: am5.color('#7b6bac') },
                    { color: am5.color('#3615af') },
                  ],
                }}
                dataGraph={
                  graphData[0] ? { value: graphData[0]?.value, full: graphData[0]?.full } : { value: 0, full: 0 }
                }
              />
            </div>
          )}
          {graphData1 && (
            <div className='col-xl-6 margin-bottom'>
              <SlicedChart
                id='Interacción_cursos'
                title='Usuarios'
                subtitle='Interacción con los cursos'
                noDataMessage='No se tiene interacción'
                className=''
                height='300px'
                dataGraph={graphData1}
                settings={{
                  colors: [
                    am4.color('#00cb9b'),
                    am4.color('#3615af'),
                    am4.color('#ff87c0'),
                    am4.color('#fffa57'),
                    am4.color('#f9884c'),
                    am4.color('#6bb5df'),
                    am4.color('#7b6bac'),
                  ],
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export { CampaingPage }
