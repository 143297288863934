import { useEffect, useState, useMemo } from 'react'
import {
  GeneralDashboardCards,
  StudentActivityGraph,
  TimeGeneralDashboard,
  ActiveStudentsDashboardTable,
  CampaignsList,
  CourseList,
  CursosChartGeneralDashboard,
  RequestReportDataGeneralTab,
  LastNotificationsDashboard,
  ReportsList,
} from '../../../api/Api'
import { useNavigate } from 'react-router-dom'
import { InfoCard } from 'app/components/infoCard/infoCard'
import { Table } from '../../../components/table/Table'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { getPeriodList, secondsToHms } from '../../../Utilities/Utils'
import { DobleBarChart } from '../../../components/charts/dobleBarChart'
import { AreaChart } from '../../../components/charts/areaChart'
// import * as am5 from '@amcharts/amcharts5'
import * as am4 from '@amcharts/amcharts4/core'
import {color} from '@amcharts/amcharts5'
import Modal from 'react-bootstrap/Modal'
import { useQuery } from 'react-query'
import { SelectList } from '../../../components/selectList/SelectList'
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ClipLoader from 'react-spinners/ClipLoader'
import ConfirmationModal from '../../../components/modal/confirmationModal'
import NotificationAlert from '../../../components/notificationAlert/notificationAlert'



const GeneralPage = ({ selected_customerIdSaved, selectedChannel }) => {
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const [cardData, setCardData] = useState(null)
  const [graphStudentsData, setGraphStudentsData] = useState(null)
  const [timeData, setTimeData] = useState(null)
  const [graphCoursesData, setGraphCoursesData] = useState(null)
  const [tableData, setTableData] = useState([])
  const [lastNotifications, setLastNotifications] = useState([])
  const [periodo, setPeriodo] = useState(getPeriodList()[2])
  const [campaña, setCampaña] = useState(null)
  const [cursos, setCursos] = useState(null)
  const navigate = useNavigate()
  const [reportName, setReportName] = useState('')
  const [goRequest, setGoRequest] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(null)
  const [goValidateRequest, setGoValidateRequest] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [messagge, setMesaggeModal] = useState(
    <span>
      Estamos generando tu reporte <span style={{ fontWeight: 700 }}>{reportName}</span>. En unos
      minutos estará disponible en la bandeja de reportes.
    </span>
  )

  const [loadingTableData, setLoadingTableData] = useState(true)
  const [loadingLastNotifications, setLoadingLastNotifications] = useState(true)
  const [loadingGraphStudentsData, setLoadingGraphStudentsData] = useState(true)

  registerLocale('es', es)
  const now = new Date()
  const [fechaInicio, setFechaInicio] = useState(
    new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
  )
  let fechaInicioFormated =
    fechaInicio.getFullYear() +
    '-' +
    ('0' + (fechaInicio.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fechaInicio.getDate()).slice(-2)

  const [fechaFin, setFechaFin] = useState(new Date())
  let fechaFinFormated =
    fechaFin.getFullYear() +
    '-' +
    ('0' + (fechaFin.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fechaFin.getDate()).slice(-2)

  const nowHour = new Date()
  nowHour.setHours(8)
  nowHour.setMinutes(0)
  const [timeInicio, setTimeInicio] = useState(nowHour)

  const FinTimeTempHour = new Date()
  if (FinTimeTempHour.getMinutes() < 30) FinTimeTempHour.setMinutes(0)
  else FinTimeTempHour.setMinutes(30)
  const [timeFin, setTimeFin] = useState(FinTimeTempHour)

  const animatedComponents = makeAnimated()

  const userId = (user) ? user.id.toString() : null

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (periodo?.value && (selectedChannel || selectedChannel === '')) {
      ; (async () => {
        const cardDataResponse = await GeneralDashboardCards({
          token: user.api_token,
          periodId: periodo.value,
          channel: selectedChannel
        })
        setCardData(cardDataResponse.data)
      })()
        ; (async () => {
          setLoadingGraphStudentsData(true)
          const graphDataResponse = await StudentActivityGraph({
            token: user.api_token,
            periodId: periodo.value,
            channel: selectedChannel
          })
          setGraphStudentsData(graphDataResponse.data)
          setLoadingGraphStudentsData(false)
        })()
        ; (async () => {
          const timeDataResponse = await TimeGeneralDashboard({
            token: user.api_token,
            periodId: periodo.value,
            channel: selectedChannel
          })
          setTimeData(timeDataResponse.data)
        })()
        ; (async () => {
          setLoadingLastNotifications(true)
          const lastNotiResponse = await LastNotificationsDashboard({
            token: user.api_token,
            periodId: periodo.value,
            channel: selectedChannel
          })
          setLastNotifications(lastNotiResponse.data || [])
          setLoadingLastNotifications(false)
        })()
        ; (async () => {
          const timeDataResponse = await CursosChartGeneralDashboard({
            token: user.api_token,
            periodId: periodo.value,
            channel: selectedChannel
          })
          setGraphCoursesData(timeDataResponse.data)
        })()
        ; (async () => {
          setLoadingTableData(true)
          const tableDataResponse = await ActiveStudentsDashboardTable({
            token: user.api_token,
            periodId: periodo.value,
            channel: selectedChannel
          })
          setTableData(tableDataResponse.data || [])
          setLoadingTableData(false)
        })()
    }
  }, [periodo.value, userId, selectedChannel, selected_customerIdSaved])

  const queryKey = [
    'downloadDataResponse',
    {
      token: user.api_token,
      id_user: userId,
      campañaId: campaña?.map((c) => c.value).join(','),
      cursosId: cursos,
      selected_customerIdSaved,
      fechaInicio: fechaInicioFormated,
      fechaFin: fechaFinFormated,
      horaInicio: timeInicio.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      }),
      horaFin: timeFin.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      }),
      reportName: reportName,
    },
  ]

  let { data: downloadDataResponse } = useQuery(
    queryKey,
    async () =>
      await RequestReportDataGeneralTab({
        token: user.api_token,
        campañaId: campaña?.map((c) => c.value).join(','),
        cursosId: cursos,
        fechaInicio: fechaInicioFormated,
        fechaFin: fechaFinFormated,
        horaInicio: `${timeInicio.getHours().toString().padStart(2, '0')}:${timeInicio.getMinutes().toString().padStart(2, '0')}`,
        horaFin: `${timeFin.getHours().toString().padStart(2, '0')}:${timeFin.getMinutes().toString().padStart(2, '0')}`,
        reportName: reportName,
      }),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: Boolean(campaña && cursos && goRequest),
    }
  )

  let { data: validateReport } = useQuery(
    [
      'downloadDataReport',
      {
        token: user.api_token,
        reportName: reportName,
      },
    ],
    async () =>
      await ReportsList({
        token: user.api_token,
      }),
    {
      refetchInterval: 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: Boolean(goValidateRequest),
    }
  )

  useEffect(() => {
    if (validateReport !== undefined) {
      if (validateReport.data.find((row) => row.name === reportName)?.status === 'A') {
        setGoValidateRequest(false)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 5000)
      }
    }
  }, [reportName, validateReport])

  useEffect(() => {
    if (downloadDataResponse !== undefined) {
      if (downloadDataResponse?.success === true) {
        setMesaggeModal(
          <span>
            Estamos generando tu reporte <span style={{ fontWeight: 700 }}>{reportName}</span>. En
            unos minutos estará disponible en la bandeja de reportes.
          </span>
        )
        setTimeout(() => {
          setGoRequest(false)
        }, 2000)
      } else {
        setMesaggeModal(downloadDataResponse?.messagge || 'error')
      }
      setShowConfirmationModal(true)
      setShow(false)
    }
    // setDownloadData(downloadDataResponse?.data)
  }, [downloadDataResponse, reportName])

  const columns = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      {
        Header: 'Datos personales',
        accessor: 'first_name',
        className: 'min-w-100px text-gray-800 fw-bolder',

      },
      { Header: '', accessor: 'last_name', show: false },
      { Header: '', accessor: 'email', show: false },
      // {
      //   Header: 'Canal',
      //   accessor: 'numberPhone',
      //   className: 'min-w-100px',
      //   Cell: ({ cell }) => (

      //     <span className='text-success fs-7 fw-bold'>
      //       <i
      //         className='text-success fa-regular fa-comments'
      //         style={{ paddingRight: 5 }}
      //       ></i>
      //       {cell.row.values.numberPhone}
      //     </span>

      //   ),
      // },
      {
        Header: 'Cursos inscritos',
        accessor: 'enrolled_courses',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },

      {
        Header: 'Cursos finalizados',
        accessor: 'completed_courses',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },
      {
        Header: 'Tiempo de estudio',
        accessor: 'time',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-regular fa-clock'
              style={{ paddingRight: 5 }}
            ></i>
            {secondsToHms(cell.row.values.time)}
          </span>

        ),

      },
      {
        Header: 'Ultima conexión',
        accessor: 'last_conection',
        className: 'min-w-100px text-right',
        Cell: ({ cell }) => <span className='text-right '>{cell.row.values.last_conection}</span>,
      },
    ],
    []
  )

  return (
    <>
      {showAlert && (
        <NotificationAlert text={'Tu reporte se encuentra listo'} functionOpt={setReportName} />
      )}
      <div className='app-container d-flex flex-stack mb-5'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            General
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-6'>
          <button
            type='button'
            className='btn btn-sm fw-bold btn-primary'
            onClick={() => setShow(true)}
          >
            <span className='fw-bold '>
              <i className='fa-duotone fa-down-to-line'></i>Descargar
            </span>
          </button>
          <Select
            classNamePrefix='filter'
            components={animatedComponents}
            defaultValue={getPeriodList()[2]}
            isMulti={false}
            closeMenuOnSelect={true}
            options={getPeriodList()}
            onChange={setPeriodo}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}

          />
        </div>
      </div>
      {cardData && (
        <>
          <div className='row g-5 g-xl-8 mb-5'>
            <div className='col-xl-3'>
              <InfoCard
                value={`${cardData.finalizacion}%`}
                title='Finalización'
                subtitle='Promedio de Estudiantes'
                link='#'
                classes={{
                  cardClasses: 'card bg-body-white',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={cardData.promedio_microcursos_x_estudiante}
                title='Promedio'
                subtitle='Microcursos por estudiante'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconImages: '/media/Íconos-01.jpg',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={cardData.satisfaccion.value}

                title='Satisfacción'
                subtitle='de Estudiantes'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconImages: '/media/Íconos-04.jpg',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={cardData.estudiantes_ultimas_24hrs}
                title='Estudiantes'
                subtitle={'En las ultimas ' + periodo.label}
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-duotone fa-users text-primary fs-2',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>
          </div>
        </>
      )}
      <div>
        <AreaChart
          id={'actividad de Estudiantes'}
          title={'Actividad de Estudiantes'}
          className={''}
          height={'300px'}
          settings={{
            categoryField: 'date',
            series: [
              {
                name: 'Estudiantes que interacturaron',
                field: 'interactingStudents',
                fill: 0.4,
                opposite: false,
              },
              {
                name: 'Estudiantes Registrados',
                field: 'registeredStudents',
                fill: 0,
                opposite: false,
              },
              { name: 'Notificaciones', field: 'notifications', fill: 1, opposite: true },
            ],
            // colors: [am5.color('#fbe95b'), am5.color('#00cd98'), am5.color('#3514bc')],
            colors: [color('#fbe95b'), color('#00cd98'), color('#3514bc')],
            titleX: 'Días',
            titleY: 'Acumulado de estudiantes',
            titleY2: 'Notificaciones',
          }}
          dataGraph={graphStudentsData}
          loading={loadingGraphStudentsData}
        />
      </div>
      <div className='row pt-8'>

        <div className='col-xl-4'>
          {lastNotifications && (
            <>
              <div className='card card-flush' style={{ minHeight: '350px' }}>
                <div className='card-header pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-dark'>Ultimas Notificaciones</span>
                  </h3>
                </div>
                <div className='card-body pt-5'>
                  {timeData && (
                    <>
                      <div className='d-flex'>
                        <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3'>
                          <span className='text-dark fs-2 fw-bold counted'>  {secondsToHms(timeData.tiempo_promedio_estudiante)}</span>
                          <div className='fw-semibold text-gray-500'>PROM de estudio</div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3'>
                          <span className='text-dark fs-2 fw-bold counted'>
                            {secondsToHms(timeData.tiempo_total)}
                          </span>
                          <div className='fw-semibold text-gray-500'>Tiempo total</div>
                        </div>
                      </div>
                    </>
                  )} <div className='pt-5'>
                    {!loadingLastNotifications ? (
                      <>
                        {lastNotifications.length > 0 ? (
                          lastNotifications?.slice(0, 5).map((not, ind) => (
                            <div key={`${not.id - ind}`}>
                              <div className='d-flex flex-stack '>
                                <div className='text-gray-900 fw-bolder fs-6 title-capitalice'>{not.name}</div>
                                <div
                                  className='d-flex justify-content-end'
                                  style={{ textAlign: 'end' }}
                                >
                                  <span className='mb-2 fw-bold'>{not.date}</span>
                                </div>
                              </div>
                              <div className='separator my-3'></div>
                            </div>
                          ))
                        ) : (
                          <>
                            <h4>Sin Datos</h4>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className='flex-column pb-4'>
                          <span className='spinner-border spinner-border-sm align-middle text-primary'></span>
                          <span className='px-2 text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
                        </div>
                      </>
                    )}   </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='col-xl-8'>
          {graphCoursesData && (
            <div>
              <DobleBarChart
                id={'cursosGeneralTab'}
                title={'Cursos '}
                className={''}
                height={'350px'}
                settings={{
                  categoryField: 'category',
                  series: [
                    { name: 'Curso completo', field: 'cursoCompleto', opposite: false },
                    { name: 'Porcentaje de avance', field: 'porcentajeAvance', opposite: true },
                  ],
                  axisY: [
                    { name: 'Cantidad', modifier: '' },
                    { name: 'Porcentaje completado', modifier: '%' },
                  ],
                  colors: [am4.color('#00cb9b'), am4.color('#3615af')],
                }}
                dataGraph={graphCoursesData}
              />
            </div>
          )}
        </div>
      </div>

      <div className='row g-5 g-xl-8 pt-8'>
        <div className='col-xl-12'>
          <Table
            className=''
            data={tableData}
            columns={columns}
            title='Estudiantes Activos'
            showCount={true}
            isLoading={loadingTableData}
          />
        </div>
      </div>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className='row'>
            <div className='col-6'>
              <SelectList
                title={'Campaña'}
                apiService={CampaignsList}
                args={{ token: user.api_token }}
                conditions={true}
                handleChange={(campaing) => {
                  if (campaing?.length === 0) setCursos(null)
                  setCampaña(campaing)
                }}
                multiple={true}
                value={campaña}
                defaultSetterFunction={setCampaña}
              />
            </div>
            <div className='col-6'>
              <SelectList
                title={'Cursos'}
                apiService={CourseList}
                args={{
                  token: user.api_token,
                  campaingId: campaña?.map((c) => c.value).join(','),
                  todos: true,
                }}
                conditions={campaña !== null}
                value={cursos}
                handleChange={(courses) => {
                  const hasTodos =
                    courses.some((c) => c.label === 'Todos') &&
                    cursos?.some((c) => c.label !== 'Todos')
                  if (hasTodos) setCursos([{ value: '', label: 'Todos' }])
                  else {
                    const hasTodos2 = cursos?.some((c) => c.label === 'Todos')
                    if (hasTodos2) {
                      setCursos(courses.filter((c) => c.label !== 'Todos'))
                    } else {
                      setCursos(courses)
                    }
                  }

                  if (courses.length === 0) setCursos([{ value: '', label: 'Todos' }])
                  else {
                    if (courses.length === 1 && courses.value === '')
                      setCursos([{ value: '', label: 'Todos' }])
                  }
                }}
                multiple={true}
                defaultSetterFunction={setCursos}
              />
            </div>
          </div>
          <div className='row pt-8'>
            <div className='col-6'>
              <div className='row'>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Fecha de inicio:</div>
                  <DatePicker
                    className='form-control '
                    locale='es'
                    selected={fechaInicio}
                    onChange={(dateX) => dateX && setFechaInicio(dateX)}
                    dateFormat='dd/MM/yyyy'
                  />
                </div>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Hora de inicio:</div>
                  <DatePicker
                    className='form-control'
                    locale='es'
                    selected={timeInicio}
                    onChange={(time) => setTimeInicio(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                  />
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='row'>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Fecha de fin:</div>
                  <DatePicker
                    className='form-control'
                    locale='es'
                    selected={fechaFin}
                    onChange={(dateX) => dateX && setFechaFin(dateX)}
                    dateFormat='dd/MM/yyyy'
                  />
                </div>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Hora de fin:</div>
                  <DatePicker
                    className='form-control '
                    locale='es'
                    selected={timeFin}
                    onChange={(time) => setTimeFin(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='fs-8 text-gray-600 mt-8' style={{ fontStyle: 'italic' }}>
            * Las fechas y horas estan representadas en zona horaria UTC-5
          </div>
        </Modal.Body>
        <Modal.Footer style={{ margin: 'auto', display: 'block' }}>
          <div style={{ height: '40px', display: 'flex' }}>
            <input
              placeholder='nombre del reporte'
              type='text'
              name='name'
              value={reportName}
              className={'form-control form-control-solid mb-3 mb-lg-0'}
              onChange={(e) => setReportName(e.target.value)}
            />
            <button
              style={{ width: '240px' }}
              type='button'
              className='btn btn-sm fw-bold btn-primary'
              disabled={reportName === '' || goRequest}
              onClick={() => setGoRequest(true)}
            >
              <span className='fw-bold '>
                {goRequest ? (
                  <ClipLoader
                    color={'#00ca92'}
                    loading={true}
                    size={20}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  <span>
                    <i className='fa-duotone fa-down-to-bracket'></i> Generar reporte
                  </span>
                )}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {showConfirmationModal === true && (
        <ConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          messagge={messagge}
          onClickSecondary={() => navigate(`/apps/reports-management/reports`)}
          setGoValidateRequest={setGoValidateRequest}
          secondaryButtonText={'Ir a reportes'}
        />
      )}
    </>
  )
}

export { GeneralPage }
