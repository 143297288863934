import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import * as Api from 'app/api/Api'
import {useState, useEffect, useMemo} from 'react'
import {Table} from '../../../../components/table/Table'
import ApproveSVG from '../../../../../media/approve.png'
import PendingSVG from '../../../../../media/pending.png'
import RejectedSVG from '../../../../../media/rejected.png'
import {Modal} from 'react-bootstrap'
import defaultProduct from '../../../../../media/bicho4.svg'
import defaultProduct1 from '../../../../../media/bicho2.svg'
const ListTemplate = () => {
  const company = JSON.parse(useParams().company)
  const campaing = JSON.parse(useParams().campaing)
  const [tableData, setTableData] = useState([])
  const [templateIdToRemove, setTemplateIdToRemove] = useState(null)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [showRemoveCompleteModal, setShowRemoveCompleteModal] = useState(false)
  const [removeId, setRemoveId] = useState(null)
  const [isLoadingRemove, setIsLoadingRemove] = useState(false)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  useEffect(() => {
    if (company?.value && campaing?.id) {
      ;(async () => {
        const tableDataResponse = await Api.TableTemplates({
          token: user.api_token,
          campaingId: campaing.id,
          id_user: userId,
        })
        setTableData(tableDataResponse.data)
      })()
    }
  }, [company?.value, campaing?.id, user.api_token, userId, templateIdToRemove])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'appId',
        show: false,
      },
      {
        Header: '',
        accessor: 'elementName',
        show: false,
      },
      {
        Header: '',
        accessor: 'reason',
        show: false,
      },
      {
        Header: '',
        accessor: 'gupshupId',
        show: false,
      },
      {
        Header: '',
        accessor: 'facebookId',
        show: false,
      },
      {
        Header: 'TEMPLATE',
        accessor: '',
        className: 'min-w-200px',
        Cell: ({cell}) => {
          return (
            <>
              <div className='text-gray-800'>{cell.row.values.elementName}</div>
              {cell.row.values.reason && (
                <div style={{marginTop: 10}}>
                  <span style={{textTransform: 'capitalize'}}>
                    Reason: {cell.row.values.reason}
                  </span>
                </div>
              )}
              <div style={{marginTop: 10, fontSize: 12}} className=' fw-normal'>
                <span style={{textTransform: 'capitalize'}}>
                 <strong> Gupshup temp ID:</strong> {cell.row.values.gupshupId}
                </span>
              </div>
              {cell.row.values.facebookId && (
                <div style={{marginTop: 10, fontSize: 12}} className=' fw-normal'>
                  <span style={{textTransform: 'capitalize'}}>
                  <strong>  Facebook temp ID:</strong> {cell.row.values.facebookId}
                  </span>
                </div>
              )}
            </>
          )
        },
      },
      {
        Header: 'CATEGORIÍ',
        accessor: 'category',
        Cell: ({cell}) => (
          <div className="badge badge-light-primary fw-bold" style={{textTransform:'capitalize' }}>{cell.row.values.category}</div>
        ),
      },
      {
        Header: 'LENGUAJE',
        accessor: 'language',
        className: ' text-center',
        Cell: ({cell}) => (
          <div className="" style={{textTransform:'uppercase' }}>{cell.row.values.language}</div>
        ),
      },
      {
        Header: 'TIPO',
        accessor: 'type',
        Cell: ({cell}) => (
          <div className="badge badge-light fw-bold" style={{textTransform:'capitalize' }}>{cell.row.values.type}</div>
        ),
      },
      {
        Header: 'DESCRIPCIÓN',
        accessor: 'description',
        className: 'min-w-100px',
      },
      {
        Header: 'ESTADO',
        accessor: 'status',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <>
            {cell.row.values.status === 'Approved by WhatsApp' && (
              <img src={ApproveSVG} width='20px' height='20px' alt='' />
            )}
            {cell.row.values.status === 'Approval pending from WhatsApp' && (
              <img src={PendingSVG} width='20px' height='20px' alt='' />
            )}
            {cell.row.values.status === 'Rejected by WhatsApp' && (
              <img src={RejectedSVG} width='20px' height='20px' alt='' />
            )}
            <span className="fw-semibold fs-7" style={{marginLeft: 6}}>{cell.row.values.status}</span>
          </>
        ),
      },
      {
        Header: 'ACCIONES',
        accessor: '',
        className: 'w-80px text-end',
        Cell: ({cell}) => (
          <>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px'
              onClick={() => {
                setRemoveId(cell.row.values)
                setShowRemoveModal(true)
              }}
            >
              <i className='fa-duotone fa-trash'></i>
            </button>
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className=''>
          <Link
            to={`/apps/notification-detail/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}
            className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
            onClick={() => sessionStorage.setItem('defaultActiveKey', 'cursos')}
          >
          <i className="fa-solid fa-arrow-left"></i>
            <span className='fw-bold '>Regresar</span>
          </Link>
        </div>
        <div>
          <Link to={`/apps/template-create/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}>
            <button
              style={{marginLeft: 15}}
              type='button'
              className='btn btn-sm fw-bold btn-primary'
            >
              <span className='fw-bold '>Crear Template</span>
            </button>
          </Link>
        </div>
      </div>
      <>
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-6'>
          Lista de templates
        </h1>
        {tableData && <Table className='' data={tableData} columns={columns} />}
      </>

      <Modal
        className='modal fade '
        show={showRemoveModal}
        aria-hidden='true'
        centered
        onHide={() => setShowRemoveModal(false)}
      >
        <div className='modal-content '>
          <div className='modal-body '>
          <img src={defaultProduct} alt='' width='60' height='60'></img>   
            <h5 className='fw-bolder fs-1 mb-5'>Eliminar Template</h5>
            <div>
              <span>Nota: No podrá deshacer esta acción</span>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              disabled={isLoadingRemove}
              onClick={() => setShowRemoveModal(false)}
            >
              Cancel
            </button>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              disabled={isLoadingRemove}
              onClick={() => {
                ;(async () => {
                  setIsLoadingRemove(true)
                  const response = await Api.RemoveTemplate({
                    token: user.api_token,
                    appId: removeId.appId,
                    elementName: removeId.elementName,
                  })
                  setTemplateIdToRemove(removeId.elementName)
                  if (response.success) {
                    setShowRemoveModal(false)
                    setShowRemoveCompleteModal(true)
                  }
                })()
              }}
            >
              {isLoadingRemove ? 'Cargando...' : 'Eliminar'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        className='modal fade '
        show={showRemoveCompleteModal}
        aria-hidden='true'
        centered
        onHide={() => setShowRemoveCompleteModal(false)}
      >
        <div className='modal-content '>
          <div className='modal-body '>
          <img src={defaultProduct1} alt='' width='60' height='60'></img> 
            <h5 className='fw-bolder fs-1 mb-5'>Eliminación Exitosa</h5>
            <div>
              <span>Se elminó la template exitosamente</span>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setShowRemoveCompleteModal(false)
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {ListTemplate}
