
import { useState } from "react";
import { AuthenticationMethod } from "./AuthenticationMethod";
import { ValidateCode } from "./ValidateCode";

export function TwoFactorAuthentication() {
  const [showValidateSection, setShowValidateSection] = useState(false)
  const [contactData, setContactData] = useState()


  return (
    <>
      {
        !showValidateSection ?
          <AuthenticationMethod setShowValidateSection={setShowValidateSection} setContactData={setContactData} />
          :
          <ValidateCode contactData={contactData} />
      }
    </>
  )
}