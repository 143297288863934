import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {GeneralWrapper} from '../pages/dashboard/general/GeneralWrapper'
import {NotificationsWrapper} from '../pages/dashboard/notification/NotificationsWrapper'
import {CampaingWrapper} from '../pages/dashboard/campaing/CampaingWrapper'
import {CoursesWrapper} from '../pages/dashboard/course/CoursesWrapper'
import {StudentWrapper} from '../pages/dashboard/student/StudentWrapper'
import {TrackingWrapper} from '../pages/dashboard/tracking/TrackingWrapper'
import {MonitoreoPage} from '../pages/superDashboard/MonitoreoPage'
import {TrackingPage} from '../pages/superDashboard/tracking/TrackingTab'
import {DetailCourse} from '../pages/course/detailCourse'
import {DetailStudent} from '../pages/student/detailStudent'
import {DetailNotification} from '../../../src/app/modules/apps/notification-management/detailNotification'
import {CreateNotification} from '../../../src/app/modules/apps/notification-management/createNotification'
import {CreateTemplate} from '../modules/apps/notification-management/template/createTemplate'
import {PermissionsManagerPage} from '../modules/apps/permission-management/permissionsManager'
import {DetailPermissionsManager} from '../modules/apps/permission-management/detailPermissionsManager'
import {ListTemplate} from '../modules/apps/notification-management/template/listTemplate'
// import {Studients} from '../pages/studients/studients'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
// import {DetailsCourse} from '../pages/dashboard/DetailsCourse'
import {ProtectedRoute} from './ProtectedRoutes'
import {useAuth} from '../modules/auth'
import {ReportsPage} from '../pages/reports/ReportsPage'
import {MaintenancePage} from '../pages/maintenance/MaintenancePage'
import { ThanksPage } from 'app/pages/maintenance/ThanksPage'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const RolesPage = lazy(() => import('../modules/apps/role-management/RolesPage'))
  const NotificaionPage = lazy(() =>
    import('../modules/apps/notification-management/NotificationPage')
  )

  const findPermission = (permissionUser) => {
    return currentUser.data?.role.map((role) =>
      role.permissions.some((permission) => permission.id === permissionUser)
    )[0]
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/general' />} />
        <Route path='general' element={<GeneralWrapper />} />
        <Route path='notificationes' element={<NotificationsWrapper />} />
        <Route path='campaing' element={<CampaingWrapper />} />
        <Route path='cursos' element={<CoursesWrapper />} />
        <Route path='estudiantes' element={<StudentWrapper />} />
        <Route path='tracking' element={<TrackingWrapper />} />
        <Route path='detailCourse/:id' element={<DetailCourse />} />
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('USERMANAGEMENT')} />}
        >
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('ROLEMANAGEMENT')} />}
        >
          <Route
            path='apps/roles-management/*'
            element={
              <SuspensedView>
                <RolesPage />
              </SuspensedView>
            }
          />
        </Route>
        `
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('NOTIFICATION')} />}
        >
          <Route
            path='apps/notification-management/*'
            element={
              <SuspensedView>
                <NotificaionPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('NOTIFICATION')} />}
        >
          <Route
            path='apps/notification-detail/:company/:campaing'
            element={<DetailNotification />}
          />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('NOTIFICATION')} />}
        >
          <Route
            path='apps/notification-create/:company/:campaing'
            element={<CreateNotification />}
          />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('NOTIFICATION')} />}
        >
          <Route path='apps/template-list/:company/:campaing' element={<ListTemplate />} />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('NOTIFICATION')} />}
        >
          <Route path='apps/template-create/:company/:campaing' element={<CreateTemplate />} />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('PERMISSIONS')} />}
        >
          <Route
            path='apps/permission-management/permissions'
            element={<PermissionsManagerPage />}
          />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('PERMISSIONS')} />}
        >
          <Route
            path='apps/permission-management/permissions/permission/:campaingId/:validationField'
            element={<DetailPermissionsManager />}
          />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!!currentUser} />}>
          <Route path='detailStudent/:id/:campaingLabel/:campaingId' element={<DetailStudent />} />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!currentUser && findPermission('SUPERADMIN')} />}
        >
          <Route path='monitoreoAdmin' element={<MonitoreoPage />} />
          <Route path='trackingAdmin' element={<TrackingPage typeView='1' />} />
        </Route>
        <Route path='apps/reports-management/reports' element={<ReportsPage />} />`
        <Route path='apps/maintenance-management/maintenance' element={<MaintenancePage />} />`
        <Route path='apps/maintenance-management/thankYou' element={<ThanksPage />} />`
       {/* Page Not Found */}
         <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
