import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLocation, useNavigate } from 'react-router-dom'


const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<any>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    localStorage.removeItem('selected_customerId')
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  let navigate = useNavigate();
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  function GetURLParams(param: any) {
    const search = useLocation().search
    const params = new URLSearchParams(search)
    return params.get(param)
  }

  const resetToken = GetURLParams('q')

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const responseData = await getUserByToken(apiToken)
          const dataFinal = responseData.data;
          if (dataFinal?.success) {
            if (dataFinal.data?.is_first_login === false && dataFinal.data?.is_password_expired === false) {
              localStorage.setItem('auxToken', '-1')
              setCurrentUser(dataFinal)
            }
            else {
              localStorage.setItem('auxToken', dataFinal.data?.api_token)
              navigate('/auth/reset-password')
            }
          } else if(resetToken) {
            navigate(`/auth/reset-password?q=${resetToken}`)
          } else {
            return navigate("/auth");
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
          return navigate("/auth");

        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth?.api_token) {
      requestUser(auth?.api_token)
    } else {
      localStorage.removeItem('selected_customerId')
      logout()
      setShowSplashScreen(false)

      if (resetToken) {
        navigate('auth/reset-password?q=' + resetToken)
      }
      else {
        navigate('/auth')
      }

    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
