import axios from 'axios'
import { AuthModel } from './_models'
import { LOGIN_URL, GET_USER_BY_ACCESSTOKEN_URL } from '../../../api/PathNames';

// const API__PD_URL = 'https://musaqawsweb.azurewebsites.net/api/authentication/'
const API__PD_URL = 'https://musaprodwsweb.azurewebsites.net/api/authentication/'

export const REGISTER_URL = `${API__PD_URL}register`
export const REQUEST_PASSWORD_URL = `${API__PD_URL}forgotPassword`
export const RESET_PASSWORD_URL = `${window.location.protocol}//${window.location.host}/auth/reset-password` //PAGINA
export const CHANGE_PASSWORD_URL = `${API__PD_URL}changePassword`
export const CHANGE_PASSWORD_AUX_URL = `${API__PD_URL}change-password-expiration`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(data: any) {
  return axios.post(REQUEST_PASSWORD_URL, { ...data, url: RESET_PASSWORD_URL + '?q=' + data.resetToken })
}

export function changePassword(data: any) {
  return axios.post(CHANGE_PASSWORD_URL, data)
}

export function changePasswordAux(data: any, token: string) {
  return axios.post(CHANGE_PASSWORD_AUX_URL, data, {
    headers: { 'Authorization': `Bearer ${token}` },
  })
}

export function getUserByToken(token: string) {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
