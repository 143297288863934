import { useIntl } from 'react-intl'
import { useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { CampaingPage } from './CampaingPage'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const CampaingWrapper = () => {
  const intl = useIntl()
  const { first_name } = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const listClients = user.customers
  const selected_customerIdSaved = localStorage.getItem('selected_customerId') || 0

  const [clientSelected, setClientSelected] = useState(
    listClients?.find((cl) => +cl.value === +selected_customerIdSaved) ||
    (listClients?.length > 0 ? listClients[0] : null)
  )

  const onChangeClient = (val) => {
    localStorage.setItem('selected_customerId', val.value)
    setClientSelected(val)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='app-container d-flex' style={{ justifyContent: 'space-between' }}>
        <h3 className='card-title align-items-start flex-column pb-5'>
          <span className='font-weight-light fs-2x mb-3' style={{ fontWeight: '400' }}>
            Hola, <span className='fw-bold'>{first_name}</span>. ¡Qué bueno verte!
          </span>
        </h3>

        <div className='d-flex align-items-center gap-2 gap-lg- pb-6'>
          <span className='text-gray-500 fs-7 me-2 fw-bold'>Cliente</span>
          {listClients?.length > 1 ? (
            <Select
              className="react-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  text: '#000',
                  font: '#000',
                  primary25: '#f1f1f4',
                  primary: '#3615af',
                  neutral80: '#000',
                  color: '#000',
                },
              })}
              classNamePrefix='filter'
              components={makeAnimated()}
              closeMenuOnSelect={true}
              options={listClients}
              onChange={onChangeClient}
              value={clientSelected}
            />
          ) : (
            <h5>{listClients[0].label}</h5>
          )}
        </div>
      </div>

      <CampaingPage selected_customerIdSaved={selected_customerIdSaved} />
    </>
  )
}

export { CampaingWrapper }
