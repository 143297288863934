import {useEffect, useState} from 'react'
import * as Api from 'app/api/Api'
import * as am5 from '@amcharts/amcharts5'
import * as am4 from '@amcharts/amcharts4/core'
import {BarChart} from 'app/components/charts/barChart'
import {WordCloudChart} from 'app/components/charts/wordCloudChart'

const OpenAndCloseQuestions = ({id}) => {
  const [questionsData, setQuestionsData] = useState(null)
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  useEffect(() => {
    ;(async () => {
      const apiResponse = await Api.OpenAndCloseQuestions({
        courseId: id,
        type: 'survey',
        token: user.api_token,
      })
      setQuestionsData(apiResponse.data.question)
    })()
  }, [id, user.api_token])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {questionsData &&
          questionsData.map((value, index) => {
            if (value.isOpen === true) {
              return (
                <div className='col-xl-6' key={index}>
                  <WordCloudChart
                    id={value.title}
                    title={value.title}
                    className={''}
                    height={'350px'}
                    settings={{
                      categoryField: 'tag',
                      colors: [am5.color('#f8fc5e')],
                    }}
                    dataGraph={value?.graph[0]}
                    index={index}
                  />
                </div>
              )
            } else {
              return (
                <div className='col-xl-6' key={index}>
                  <BarChart
                    id={value.title}
                    title={value.title}
                    className={''}
                    height={'350px'}
                    settings={{
                      categoryField: 'option',
                      colors: [am4.color('#f8fc5e')],
                    }}
                    dataGraph={value.graph}
                  />
                </div>
              )
            }
          })}
      </div>
    </>
  )
}

export {OpenAndCloseQuestions}
