import { useEffect, useState, useMemo } from 'react'
import { TableNotifications, CampaignsList, DownloadRecients } from '../../../api/Api'
import { Table } from '../../../components/table/Table'
import { SelectList } from '../../../components/selectList/SelectList'

const NotificationsPage = ({ selected_customerIdSaved }) => {
  const [tableData, setTableData] = useState([])
  const [campaing, setCampaing] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refrestCampaingList, setRefrestCampaingList] = useState(true)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  const handleDownloadRecipients = async (notificationId, _) => {
    const reponseDownload = await DownloadRecients({
      notificationId,
      token: user.api_token
    })

    const currentDate = new Date()
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const fileName = `Destinatarios_Notificacion_${currentDate.getFullYear()}${currentMonth}${currentDate.getDate()}`
    const blob = new Blob([reponseDownload.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click()
    return reponseDownload
  }

  useEffect(() => {
    if (campaing?.value) {
      ; (async () => {
        setLoading(true)
        const tableDataResponse = await TableNotifications({
          token: user.api_token,
          campaingId: campaing.value,
          typeView: '2',
        })
        setTableData(tableDataResponse.data || [])
        setLoading(false)
      })()
    }
    
  }, [campaing, user.api_token, userId, selected_customerIdSaved])
  
  useEffect(() => {
    setRefrestCampaingList(true)
  }, [selected_customerIdSaved])

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
        className: 'min-w-100px',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-light fa-hand-wave'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.type}
          </span>

        ),
      },
      {
        Header: 'Creación',
        accessor: 'date_created',
        className: 'min-w-100px',
      },
      {
        Header: 'Alcance',
        accessor: 'alcance',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },
      {
        Header: 'Alc Sig 24H',
        accessor: 'interacccion_usuarios_24h',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },
      {
        Header: 'Es programado',
        accessor: 'is_schedule',
        className: 'text-center',
        Cell: ({ cell }) => (

          <span>
            {cell.row.values.is_schedule === 'SI' ? (
              <span className='badge badge-light-success'>
                {' '}
                <i
                  className='fa-duotone fa-check text-success'
                  style={{ paddingRight: 5 }}
                ></i>{' '}
                {cell.row.values.is_schedule}
              </span>
            ) : cell.row.values.is_schedule === 'NO' ? (
              <span className='badge badge-light-danger'>
                {' '}
                <i
                  className='fa-solid fa-xmark text-danger'
                  style={{ paddingRight: 5 }}
                ></i>{' '}
                {cell.row.values.is_schedule}
              </span>
            ) : (
              ''
            )}
          </span>

        ),
      },
      {
        Header: 'Fecha programado',
        accessor: 'date_schedule',
        className: '',
      },
      {
        Header: 'Estado',
        accessor: 'statusSend',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (
          <span>
            {cell.row.values.statusSend === 'E' ? (
              <span className='badge badge-light-success'>
                {' '}
                <i
                  className='fa-regular fa-circle-check text-success'
                  style={{ paddingRight: 5 }}
                ></i>{' '}
                Enviado
              </span>
            ) : cell.row.values.statusSend === 'P' ? (
              <span className='badge badge-light-warning'>
                {' '}
                <i
                  className='fa-duotone fa-clock-rotate-left text-warning'
                  style={{ paddingRight: 5 }}
                ></i>{' '}
                Pendiente
              </span>
            ) : (
              ''
            )}
          </span>
        ),
      },
      {
        Header: 'Acciones',
        accessor: '',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => {
          return (

            <button type='button' className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3' onClick={() => handleDownloadRecipients(cell.row.original.id, cell.row.values.name)}>
              <i className='fa-duotone fa-down-to-line'></i>
            </button>

          )
        },
      },
    ],
    []
  )

  return (
    <>
      <div className='app-container d-flex flex-stack mb-5'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            Notificaciones
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-6'>
          <SelectList
            title={'Campaña'}
            apiService={CampaignsList}
            args={{ token: user.api_token }}
            conditions={refrestCampaingList}
            handleChange={(company) => setCampaing(company)}
            multiple={false}
            defaultSetterFunction={setCampaing}
            setRefresh={setRefrestCampaingList}
            value={campaing}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <Table
            className=''
            data={tableData}
            columns={columns}
            title='Lista de notificaciones'
            isLoading={loading}
          />
        </div>
      </div>
    </>
  )
}

export { NotificationsPage }
