/* eslint-disable react/style-prop-object */
import { useEffect, useState, useMemo } from 'react'
import * as Api from 'app/api/Api'
import { PageTitle } from '../../../_metronic/layout/core'
import * as am4 from '@amcharts/amcharts4/core'
import { LineChart } from 'app/components/charts/lineChart'
import { Table } from '../../components/table/Table'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useQuery } from 'react-query'
import {
  getPeriodList2,
  getPeriodListTime,
  secondsToHms,
  numberWithCommas,
  transformDataTableToDataGraphTopUsers,
  transformDataTableToDataGraphActiveCourses,
  getAgrupationList,
} from '../../Utilities/Utils'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Modal from 'react-bootstrap/Modal'
import { SelectList } from '../../components/selectList/SelectList'
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import { BarChart } from 'app/components/charts/barChart'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CasouselSuperDashboard } from './CasouselSuperDashboard'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import ConfirmationModal from '../../components/modal/confirmationModal'
import NotificationAlert from '../../components/notificationAlert/notificationAlert'
import { ChannelSelectList } from 'app/components/selectList/ChannelSelectList'

const MonitoreoPage = () => {
  const [cliente, setCliente] = useState('')
  const [campaing, setCampaing] = useState([])
  const [cursos, setCursos] = useState([])
  const [isCustomPeridodo, setIsCustomPeridodo] = useState(true)
  const [periodo, setPeriodo] = useState(getPeriodList2()[0])
  const [periodoTiempo, setPeriodoTiempo] = useState(getPeriodListTime()[0])
  const [agrupacion, setAgrupacion] = useState(getAgrupationList()[0])
  const [isCustomPeridodoTime, setIsCustomPeridodoTime] = useState(true)
  const navigate = useNavigate()

  const [reportName, setReportName] = useState('')
  const [goRequest, setGoRequest] = useState(false)

  const [showConfirmationModal, setShowConfirmationModal] = useState(null)
  const [goValidateRequest, setGoValidateRequest] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [messagge, setMesaggeModal] = useState(
    <span>
      Estamos generando tu reporte <span style={{ fontWeight: 700 }}>{reportName}</span> . En unos
      minutos estará disponible en la bandeja de reportes.
    </span>
  )

  registerLocale('es', es)
  const now = new Date()
  const [fechaInicio, setFechaInicio] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
  )
  let fechaInicioFormated =
    fechaInicio.getFullYear() +
    '-' +
    ('0' + (fechaInicio.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fechaInicio.getDate()).slice(-2)

  let fechaInicioFormatedTitle =
    ('0' + fechaInicio.getDate()).slice(-2) +
    '/' +
    ('0' + (fechaInicio.getMonth() + 1)).slice(-2) +
    '/' +
    fechaInicio.getFullYear()

  const [fechaFin, setFechaFin] = useState(new Date())
  let fechaFinFormated =
    fechaFin.getFullYear() +
    '-' +
    ('0' + (fechaFin.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fechaFin.getDate()).slice(-2)

  let fechaFinFormatedTitle =
    ('0' + fechaFin.getDate()).slice(-2) +
    '/' +
    ('0' + (fechaFin.getMonth() + 1)).slice(-2) +
    '/' +
    fechaFin.getFullYear()

  const nowHour = new Date()
  nowHour.setHours(8)
  nowHour.setMinutes(0)
  const [timeInicio, setTimeInicio] = useState(nowHour)

  const FinTimeTempHour = new Date()
  if (FinTimeTempHour.getMinutes() < 30) FinTimeTempHour.setMinutes(0)
  else FinTimeTempHour.setMinutes(30)
  const [timeFin, setTimeFin] = useState(FinTimeTempHour)

  const [filtros, setFiltros] = useState({
    cliente: '',
    campaings: '',
    cursos: '',
    fecha_inicio: fechaInicioFormated,
    fecha_fin: fechaFinFormated,
    horaInicio: `${timeInicio.getHours().toString().padStart(2, '0')}:${timeInicio.getMinutes().toString().padStart(2, '0')}`,
    horaFin: `${timeFin.getHours().toString().padStart(2, '0')}:${timeFin.getMinutes().toString().padStart(2, '0')}`,
  })

  const animatedComponents = makeAnimated()
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  const [show, setShow] = useState(false)

  const [graphDataUserUnique, setGraphDataUserUnique] = useState(null)
  const [graphDataUsers, setGraphDataUsers] = useState(null)

  const [graphDataInteractionsNumber, setGraphDataInteractionsNumber] = useState(null)
  const [graphDataInteractions, setGraphDataInteractions] = useState(null)

  const [topUsersTable, setTopUsersTable] = useState([])
  const [topUsersGraph, setTopUsersGraph] = useState([])

  const [activeCampaingTable, setActiveCampaingTable] = useState([])
  const [activeCampaingGraph, setActiveCampaingGraph] = useState([])

  const [activeCoursesTable, setActiveCoursesTable] = useState([])
  const [activeCoursesGraph, setActiveCoursesGraph] = useState([])

  const [cardData, setCardData] = useState(null)

  const [topUsersModeTable, setTopUsersModeTable] = useState(true)
  const [activeCampaingModeTable, setActiveCampaingModeTable] = useState(true)
  const [activeCoursesModeTable, setActiveCoursesModeTable] = useState(true)

  const [options, setOptions] = useState([])
  const [channelSelected, setChannelSelected] = useState()

  const aplicarFiltros = () => {
    setFiltros({
      cliente: cliente?.value ?? '',
      campaings: campaing?.map((c) => c.value).join(','),
      cursos: cursos?.map((c) => c.value).join(','),
      fecha_inicio: fechaInicioFormated,
      fecha_fin: fechaFinFormated,
      horaInicio: `${timeInicio.getHours().toString().padStart(2, '0')}:${timeInicio.getMinutes().toString().padStart(2, '0')}`,
      horaFin: `${timeFin.getHours().toString().padStart(2, '0')}:${timeFin.getMinutes().toString().padStart(2, '0')}`,
    })
    // setShow(false)
  }

  const getOptions = async () => {
    let response = await Api.GetChannelList({
      token: user.api_token
    })
    if (response.success) {
      setOptions(response.data)
      setChannelSelected(response.data[0])
    }
  }

  const [loadingCardData, setLoadingCardData] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [loadingTopUsers, setLoadingTopUsers] = useState(true)
  const [loadingCampaingActives, setLoadingCampaingActives] = useState(true)
  const [loadingActiveCourses, setLoadingActiveCourses] = useState(true)

  useEffect(() => {
    setLoadingCardData(true)
    setLoading1(true)
    setLoading2(true)
    setLoadingTopUsers(true)
    setLoadingCampaingActives(true)
    setLoadingActiveCourses(true)

    if (options.length === 0) {
      getOptions()
    }

    const filtrosTemp = {
      customerId: filtros.cliente,
      campaingsId: filtros.campaings,
      cursosId: filtros.cursos,
      fechaInicio: filtros.fecha_inicio,
      fechaFin: filtros.fecha_fin,
      horaInicio: filtros.horaInicio,
      horaFin: filtros.horaFin,
    }
    if (channelSelected) {
      ; (async () => {
        setLoadingCardData(true)
        const cardDataResponse = await Api.DashboardCardsBySuperAdmin({
          token: user.api_token,
          id_user: userId,
          filtros: filtrosTemp,
          channel: channelSelected?.value
        })
        setCardData(cardDataResponse.data)
        setLoadingCardData(false)
          ; (async () => {
            setLoading1(true)
            const apiResponse = await Api.UserBySuperAdmin({
              id_user: userId,
              token: user.api_token,
              filtros: filtrosTemp,
              group: agrupacion.value,
              channel: channelSelected?.value
            })
            if (apiResponse.success) {
              setGraphDataUsers(apiResponse.data?.graph || [])
              setGraphDataUserUnique(apiResponse.data?.unique_users || 0)
            }
            setLoading1(false)
              ; (async () => {
                setLoading2(true)
                const apiResponse = await Api.InteractionsBySuperAdmin({
                  id_user: userId,
                  token: user.api_token,
                  filtros: filtrosTemp,
                  group: agrupacion.value,
                  channel: channelSelected?.value
                })
                if (apiResponse.success) {
                  setGraphDataInteractions(apiResponse.data?.graph || [])
                  setGraphDataInteractionsNumber(apiResponse.data?.interactions || 0)
                }
                setLoading2(false)
                  ; (async () => {
                    const apiResponse = await Api.TopUsersBySuperAdmin({
                      id_user: userId,
                      token: user.api_token,
                      filtros: filtrosTemp,
                      channel: channelSelected?.value
                    })
                    if (apiResponse.success) {
                      setTopUsersTable(apiResponse.data || [])
                      setTopUsersGraph(transformDataTableToDataGraphTopUsers(apiResponse.data))
                    }
                    setLoadingTopUsers(false)
                      ; (async () => {
                        const apiResponse = await Api.ActiveCampaingBySuperAdmin({
                          id_user: userId,
                          token: user.api_token,
                          filtros: filtrosTemp,
                          channel: channelSelected?.value
                        })
                        if (apiResponse.success) {
                          setActiveCampaingTable(apiResponse.data || [])
                          setActiveCampaingGraph(apiResponse.data)
                        }
                        setLoadingCampaingActives(false)
                          ; (async () => {
                            const tableDataResponse = await Api.ActiveCourseTableBySuperAdmin({
                              token: user.api_token,
                              id_user: userId,
                              filtros: filtrosTemp,
                              channel: channelSelected?.value
                            })
                            setActiveCoursesTable(tableDataResponse.data || [])
                            setActiveCoursesGraph(
                              transformDataTableToDataGraphActiveCourses(tableDataResponse.data)
                            )
                            setLoadingActiveCourses(false)
                          })()
                      })()
                  })()
              })()
          })()
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.api_token, userId, filtros, options, channelSelected])

  const filtrosTemp1 = {
    customerId: filtros.cliente,
    campaingsId: filtros.campaings,
    cursosId: filtros.cursos,
    fechaInicio: filtros.fecha_inicio,
    fechaFin: filtros.fecha_fin,
    horaInicio: filtros.horaInicio,
    horaFin: filtros.horaFin,
    origin: 'superadmin',
    name: reportName,
  }

  const queryKey = [
    'superDownloadDataResponse',
    {
      token: user.api_token,
      id_user: userId,
      filtros: filtrosTemp1,
    },
  ]

  let { data: downloadDataResponse } = useQuery(
    queryKey,
    async () =>
      await Api.DownloadSuperDataGeneralTab({
        token: user.api_token,
        id_user: userId,
        filtros: filtrosTemp1,
      }),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: Boolean(campaing && cursos && goRequest),
    }
  )

  useEffect(() => {
    setIsCustomPeridodo(periodo.value === 'custom')

    switch (periodo.value) {
      case '1w':
        const curr = new Date()
        setFechaInicio(new Date(curr.setDate(curr.getDate() - curr.getDay() + 1)))
        setFechaFin(new Date())
        break
      case '7d':
        const curr2 = new Date()
        setFechaInicio(new Date(curr2.setDate(curr2.getDate() - 6)))
        setFechaFin(new Date())
        break

      case '30d':
        const curr3 = new Date()
        setFechaInicio(new Date(curr3.getFullYear(), curr3.getMonth(), 1))
        setFechaFin(new Date())
        break

      case '1y':
        const curr4 = new Date()
        setFechaInicio(new Date(curr4.getFullYear(), 0, 1))
        setFechaFin(new Date())
        break

      default:
        break
    }
    if (periodo.value === 'all') {
      const iniHour = new Date()
      iniHour.setHours(0)
      iniHour.setMinutes(0)
      const finHour = new Date()
      finHour.setHours(23)
      finHour.setMinutes(30)
      setTimeInicio(iniHour)
      setTimeFin(finHour)
    }
  }, [periodo])

  useEffect(() => {
    setIsCustomPeridodoTime(periodoTiempo.value === 'custom')
    if (periodoTiempo.value === 'all') {
      const iniHour = new Date()
      iniHour.setHours(0)
      iniHour.setMinutes(0)
      const finHour = new Date()
      finHour.setHours(23)
      finHour.setMinutes(30)
      setTimeInicio(iniHour)
      setTimeFin(finHour)
    }
  }, [periodoTiempo])

  useEffect(() => {
    if (downloadDataResponse !== undefined) {
      if (downloadDataResponse?.success === true) {
        setMesaggeModal(
          <span>
            Estamos generando tu reporte <span style={{ fontWeight: 700 }}>{reportName}</span>. En
            unos minutos estará disponible en la bandeja de reportes.
          </span>
        )
        setTimeout(() => {
          setGoRequest(false)
        }, 1000)
      } else {
        setMesaggeModal(downloadDataResponse?.messagge || 'error')
      }
      setShowConfirmationModal(true)
      setShow(false)
    }
    // setDownloadData(downloadDataResponse?.data)
  }, [downloadDataResponse, reportName])

  let { data: validateReport } = useQuery(
    [
      'downloadDataReport',
      {
        token: user.api_token,
        reportName: reportName,
      },
    ],
    async () =>
      await Api.ReportsList({
        token: user.api_token,
      }),
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: Boolean(goValidateRequest),
    }
  )

  useEffect(() => {
    if (validateReport !== undefined) {
      if (validateReport.data.find((row) => row.name === reportName)?.status === 'A') {
        setGoValidateRequest(false)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 5000)
      }
    }
  }, [reportName, validateReport])

  const columnsActiveCourse = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      {
        Header: 'Cliente',
        accessor: 'customer',
        Cell: ({ cell }) => (
          <span className='text-gray-800 fw-bold '>{cell.row.values.customer}</span>
        ),
      },
      {
        Header: 'Campaña',
        accessor: 'campaing',
        className: 'min-w-100px ',
      },
      {
        Header: 'Curso',
        accessor: 'course',
        className: 'min-w-100px ',
      },
      {
        Header: 'Inscritos',
        accessor: 'inscribed',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-duotone fa-user-group-simple'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.inscribed}
          </span>

        ),
      },
      {
        Header: 'Iniciaron',
        accessor: 'started',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => <span className=''>{cell.row.values.started}</span>,
      },
      { Header: 'Completados', accessor: 'completed', className: 'min-w-100px text-center', },
      {
        Header: 'Progreso',
        accessor: 'progress',
        className: 'text-center text-gray-800 fw-bolder',
        Cell: ({ cell }) => <span className=''>{cell.row.values.progress}%</span>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros]
  )

  const columnsTopUsers = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      {
        Header: 'Canal',
        accessor: 'phone',
        Cell: ({ cell }) => (

          <span className='text-success fs-7 fw-bold'>

            <i
              className='text-success fa-regular fa-comments'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.phone}
          </span>

        ),
      },
      {
        Header: 'Usuario',
        accessor: 'user',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      {
        Header: 'Cliente',
        accessor: 'customer',
        className: 'min-w-100px ',
      },
      {
        Header: 'Campañas Activas',
        accessor: 'active_campaings',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Cursos Activos',
        accessor: 'active_courses',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Tiempo de estudio',
        accessor: 'study_time',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-regular fa-clock'
              style={{ paddingRight: 5 }}
            ></i>
            {secondsToHms(cell.row.values.study_time)}
          </span>

        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros]
  )

  const columnsActiveCampaing = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      { Header: 'Inicio', accessor: 'start_date', className: 'min-w-100px text-center', },
      { Header: 'Fin', accessor: 'end_date', className: 'min-w-100px text-center', },
      { Header: 'Dias faltantes', accessor: 'days_to_end', className: 'min-w-100px text-center', },
      {
        Header: 'Campaña',
        accessor: 'campaing',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      {
        Header: 'Interacciones',
        accessor: 'interactions',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Usuarios', accessor: 'users', className: 'min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-duotone fa-user-group-simple'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.users}
          </span>

        ),
      },
      {
        Header: 'Finalizacion',
        accessor: 'finalization',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => <span className=''>{cell.row.values.finalization}%</span>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros]
  )

  console.log(filtros)

  return (
    <>
      {showAlert && (
        <NotificationAlert text={'Tu reporte se encuentra listo'} functionOpt={setReportName} />
      )}

      <div className='app-container d-flex flex-stack mb-5'>
        <PageTitle breadcrumbs={[]}>Super Admin</PageTitle>

        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            Monitoreo
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-6'>
          <span className='text-gray-500 fs-7 me-2 fw-bold'>Canales</span>
          <ChannelSelectList
            options={options}
            channelSelected={channelSelected}
            setChannelSelected={setChannelSelected}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}
          />
          <button
            type='button'
            className='btn btn-sm fw-bold btn-primary'
            onClick={() => setShow(true)}
          >
            <span className='fw-bold '>
              <i className='fa-duotone fa-filter'></i> Filtros / Reporte
            </span>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12'>

          {!loadingCardData ? (
            <>
              {cardData ? (
                <CasouselSuperDashboard cardData={cardData} />
              ) : (
                <>
                  <span>Sin Datos</span>
                </>
              )}
            </>
          ) : (
            <>
              <div className='flex-column loadin-new'>
                <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
                <span className='text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
              </div>
            </>
          )}
        </div>

      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <LineChart
            id={'UsuariosUnicos-Monitoreo'}
            title={`Número de usuarios`}
            subtitle={` Del ${periodo.label === 'Personalizado'
              ? `${fechaInicioFormatedTitle} al ${fechaFinFormatedTitle}`
              : periodo.label
              }`}
            className={''}
            height={'200px'}
            settings={{
              categoryField: 'date',
              colors: [am4.color('#0FCA7A')],
              tooltipAf: 'usuarios',
            }}
            typeAxisX={'date'}
            dataGraph={graphDataUsers}
            info={
              <div className='d-flex flex-wrap'>
                <div className='border-gray-300 border-dashed  border rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <span className='fw-bold mb-1 fs-6'>Usuarios únicos</span>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-900 fw-bolder fs-1'>
                      {graphDataUserUnique && numberWithCommas(graphDataUserUnique)}
                    </span>
                  </div>

                </div>
              </div>
            }
            loading={loading1}
          />
        </div>

        <div className='col-xl-6'>
          <LineChart
            id={'actividad de Estudiantes'}
            title={`Actividad de Estudiantes`}
            subtitle={`Del ${periodo.label === 'Personalizado'
              ? `${fechaInicioFormatedTitle} al ${fechaFinFormatedTitle}`
              : periodo.label
              }`}
            className={''}
            height={'200px'}
            settings={{
              categoryField: 'date',
              colors: [am4.color('#3514bc')],
              tooltipAf: '',
            }}
            typeAxisX={'date'}
            dataGraph={graphDataInteractions}
            info={
              <div className='d-flex flex-wrap'>
                <div className='border-gray-300 border-dashed  border rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <span className='fw-bold mb-1 fs-6'>Interacciones</span>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-900 fw-bolder fs-1'>
                      {graphDataInteractionsNumber && numberWithCommas(graphDataInteractionsNumber)}
                    </span>
                  </div>

                </div>
              </div>

            }
            loading={loading2}
          />
        </div>
      </div>

      <div className='row' style={{ paddingTop: 20 }}>
        <div className='col-xl-12'>
          <div className='d-flex flex-row-reverse css-tab-bu'>
            <BootstrapSwitchButton
              size='xs'
              checked={topUsersModeTable}
              onlabel='Tabla'
              offlabel='Gráfico'
              onChange={(check) => setTopUsersModeTable(check)}
              width={80}
              style={'form-switch-tab'}
            />
          </div>
          {topUsersModeTable ? (
            <Table
              className=''
              data={topUsersTable}
              columns={columnsTopUsers}
              title='Top Usuarios'
              isLoading={loadingTopUsers}
            />
          ) : (
            <>
              <BarChart
                id={'Top-Usuarios-graph'}
                title={'Top Usuarios'}
                className={''}
                height={'250px'}
                settings={{
                  categoryField: 'customer',
                  colors: [am4.color('#00CA92')],
                  tooltipAf: 'total de estudio',
                  transformValueForTooltip: secondsToHms,
                }}
                dataGraph={topUsersGraph}
              />
            </>
          )}
        </div>

        <div className='col-xl-12'>
          <div className='d-flex flex-row-reverse css-tab-bu'>
            <BootstrapSwitchButton
              size='xs'
              width={80}
              checked={activeCampaingModeTable}
              onlabel='Tabla'
              offlabel='Gráfico'
              onChange={(check) => setActiveCampaingModeTable(check)}
              style='form-switch-tab'
            />{' '}
          </div>
          {activeCampaingModeTable ? (
            <Table
              className=''
              data={activeCampaingTable}
              columns={columnsActiveCampaing}
              title='Campañas Activas'
              isLoading={loadingCampaingActives}
            />
          ) : (
            <>
              <BarChart
                id={'Campaings-Activas-graph'}
                title={'Campañas Activas'}
                className={''}
                height={'250px'}
                settings={{
                  categoryField: 'campaing',
                  colors: [am4.color('#7b6bac')],
                  tooltipAf: ' interacciones',
                  transformValueForTooltip: null,
                }}
                dataGraph={activeCampaingGraph.map((val) => ({
                  campaing: val.campaing,
                  value: val.interactions,
                }))}
              />
            </>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-12'>
          <div className='d-flex flex-row-reverse css-tab-bu'>
            <BootstrapSwitchButton
              style='form-switch-tab'
              checked={activeCoursesModeTable}
              onlabel='Tabla'
              offlabel='Gráfico'
              onChange={(check) => setActiveCoursesModeTable(check)}
              size='xs'
              width={80}
            />{' '}
          </div>
          {activeCoursesModeTable ? (
            <Table
              className=''
              data={activeCoursesTable}
              columns={columnsActiveCourse}
              title='Cursos Activos'
              isLoading={loadingActiveCourses}
            />
          ) : (
            <>
              <BarChart
                id={'Cursos-Activoss-graph'}
                title={'Cursos Activo'}
                className={''}
                height={'250px'}
                settings={{
                  categoryField: 'customer',
                  colors: [am4.color('#fffa57')],
                  tooltipAf: '% progreso promedio',
                  transformValueForTooltip: null,
                }}
                dataGraph={activeCoursesGraph}
              />
            </>
          )}
        </div>
      </div>

      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-4'>
              <SelectList
                title={'Cliente'}
                apiService={Api.ClientList}
                args={{ token: user.api_token, id_user: userId, todos: true }}
                conditions={true}
                handleChange={(client) => {
                  setCliente(client)
                  setCampaing(null)
                  setCursos(null)
                }}
                value={cliente}
                defaultSetterFunction={setCliente}
              />
            </div>
            <div className='col-4'>
              <SelectList
                title={'Campañas'}
                apiService={Api.CampaignsListByClient}
                args={{ token: user.api_token, client_id: cliente?.value }}
                conditions={true}
                handleChange={(campaing) => {
                  if (campaing.length === 0) setCursos([])
                  setCampaing(campaing)
                }}
                multiple={true}
                value={campaing}
                defaultSetterFunction={setCampaing}
              />
            </div>
            {/* <div className='col-4'>
              <SelectList
                title={'Campañas'}
                apiService={Api.CampaignsListByClient}
                args={{ token: user.api_token, client_id: cliente?.value }}
                conditions={true}
                handleChange={(campaing) => {
                  if (campaing.length === 0) setCursos([])
                  setCampaing(campaing)
                }}
                multiple={true}
                value={campaing}
                defaultSetterFunction={setCampaing}
              />
            </div> */}
            <div className='col-4'>
              <SelectList
                title={'Cursos'}
                apiService={Api.CourseListWithAll}
                args={{
                  token: user.api_token,
                  id_user: userId,
                  campaingId: campaing?.map((c) => c.value).join(','),
                }}
                conditions={true}
                value={cursos}
                handleChange={(courses) => {
                  const hasTodos =
                    courses.some((c) => c.label === 'Todos') &&
                    cursos?.some((c) => c.label !== 'Todos')
                  if (hasTodos) setCursos([{ value: '', label: 'Todos' }])
                  else {
                    const hasTodos2 = cursos?.some((c) => c.label === 'Todos')
                    if (hasTodos2) {
                      setCursos(courses.filter((c) => c.label !== 'Todos'))
                    } else {
                      setCursos(courses)
                    }
                  }
                }}
                multiple={true}
                defaultSetterFunction={setCursos}
              />
            </div>
          </div>

          <div className='row pt-8'>
            <div className='col-4'>
              <span className='fw-bold fs-7 text-gray-600 mb-1'>Periodo:</span>
              <Select
                classNamePrefix='filter'
                components={animatedComponents}
                defaultValue={getPeriodList2()[0]}
                isMulti={false}
                closeMenuOnSelect={true}
                options={getPeriodList2()}
                onChange={setPeriodo}
              />
            </div>

            <div className='col-8'>
              <div className='row'>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Fecha de inicio:</div>
                  <DatePicker
                    className='form-control '
                    locale='es'
                    selected={fechaInicio}
                    onChange={(dateX) => dateX && setFechaInicio(dateX)}
                    dateFormat='dd/MM/yyyy'
                    disabled={!isCustomPeridodo}
                  />
                </div>
                <div className='col-6'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Fecha de fin:</div>
                  <DatePicker
                    className='form-control'
                    locale='es'
                    selected={fechaFin}
                    onChange={(dateX) => dateX && setFechaFin(dateX)}
                    dateFormat='dd/MM/yyyy'
                    disabled={!isCustomPeridodo}
                  />
                </div>
              </div>
            </div>
            <div className='col-12  pt-6'>
              <div className='row'>
                <div className='col-4'>
                  <span className='fw-bold fs-7 text-gray-600 mb-1'>Tiempo:</span>
                  <Select
                    classNamePrefix='filter'
                    components={animatedComponents}
                    defaultValue={getPeriodListTime()[0]}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    options={getPeriodListTime()}
                    onChange={setPeriodoTiempo}
                  />
                </div>
                <div className='col-4'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Hora de inicio:</div>
                  <DatePicker
                    className='form-control'
                    locale='es'
                    selected={timeInicio}
                    onChange={(time) => {
                      setTimeInicio(time)
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                    disabled={!isCustomPeridodoTime}
                  />
                </div>
                <div className='col-4'>
                  <div className='fw-bold fs-7 text-gray-600 mb-1'>Hora de fin:</div>
                  <DatePicker
                    className='form-control '
                    locale='es'
                    selected={timeFin}
                    onChange={(time) => setTimeFin(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption='Time'
                    dateFormat='h:mm aa'
                    disabled={!isCustomPeridodoTime}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='fs-8 text-gray-600 mt-2' style={{ fontStyle: 'italic' }}>
              * Las fechas y horas estan representadas en zona horaria UTC-5
            </div>
          </div>
          <div className='col-4'>
            <div className='fs-8 text-gray-600 mt-4'>
              <span className='fw-bold fs-7 text-gray-600 mb-1'>Agrupacion:</span>
              <Select
                classNamePrefix='filter'
                components={animatedComponents}
                defaultValue={getAgrupationList()[0]}
                isMulti={false}
                closeMenuOnSelect={true}
                options={getAgrupationList()}
                onChange={setAgrupacion}
              />
            </div>
          </div>
          <div className='col-12'>
            <div className='fs-8 text-gray-600 mt-2' style={{ fontStyle: 'italic' }}>
              * Este filtro solo afectara a las gráficas de 'Usuarios únicos' e 'Interacciones de
              usuarios'
            </div>
          </div>

          <div className='col-12 mt-6'>
            <span className='fw-bold fs-7 text-gray-600 mb-1'>Nombre del reporte:</span>
            <div className='row'>
              <div className='col-4'>
                <input
                  placeholder='ingrese el nombre'
                  type='text'
                  name='name'
                  value={reportName}
                  className={'form-control form-control-solid mb-3 mb-lg-0'}
                  onChange={(e) => setReportName(e.target.value)}
                />
              </div>
              <div className='col-4'>
                <button
                  style={{ width: '150px', margin: '0 20px' }}
                  type='button'
                  className='btn btn-sm fw-bold btn-secondary'
                  disabled={
                    reportName === '' || goRequest || campaing?.length === 0 || cursos?.length === 0
                  }
                  onClick={() => {
                    aplicarFiltros()
                    setGoRequest(true)
                  }}
                >
                  <span className='fw-bold '>
                    {goRequest ? (
                      <ClipLoader
                        color={'#00ca92'}
                        loading={true}
                        size={20}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                      />
                    ) : (
                      <span>
                        <i className='fa-duotone fa-down-to-bracket'></i>Generar reporte
                      </span>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ margin: 'auto', display: 'block' }}>
          <button
            style={{ width: '200px', margin: '0' }}
            type='button'
            className='btn btn-sm fw-bold btn-primary'
            onClick={aplicarFiltros}
          >
            <span className='fw-bold '>
              <i className='fa-duotone fa-filter'></i>Filtrar
            </span>
          </button>
        </Modal.Footer>
      </Modal>

      {showConfirmationModal === true && (
        <ConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          messagge={messagge}
          onClickSecondary={() => navigate(`/apps/reports-management/reports`)}
          setGoValidateRequest={setGoValidateRequest}
          secondaryButtonText={'Ir a reportes'}
        />
      )}
    </>
  )
}

export { MonitoreoPage }
