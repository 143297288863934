import {useEffect, useState, useMemo} from 'react'
import {TablePermissionsManagment} from '../../../api/Api'
import {Table} from '../../../components/table/Table'
import {Link} from 'react-router-dom'
import ConfirmationModal from '../../../components/modal/confirmationModal'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'

const PermissionsManagerPage = () => {
  const [tableData, setTableData] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(null)
  const [messagge, setMesaggeModal] = useState('Se completo la operacion satisfactoriamente')

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const listClients = user.customers
  const selected_customerIdSaved = localStorage.getItem('selected_customerId') || 0

  const [clientSelected, setClientSelected] = useState(
    listClients?.find((cl) => +cl.value === +selected_customerIdSaved) ||
      (listClients?.length > 0 ? listClients[0] : null)
  )

  const onChangeClient = (val) => {
    localStorage.setItem('selected_customerId', val.value)
    setClientSelected(val)
  }

  useEffect(() => {
    ;(async () => {
      const tableDataResponse = await TablePermissionsManagment({
        token: user.api_token,
        selected_customerId: clientSelected.value,
      })
      setTableData(tableDataResponse.data || [])
      if (tableDataResponse?.success === false) {
        setMesaggeModal(tableDataResponse?.message || 'error')
        setShowConfirmationModal(true)
      }
    })()
  }, [user.api_token, clientSelected])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        className: 'min-w-100px',
        show: false,
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },
      {
        Header: 'Fecha Fin',
        accessor: 'end_date',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Campo de val.',
        accessor: 'validation_field',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Cant Alumnos',
        accessor: 'num_alumnos',
        className: 'min-w-100px text-center',
      },
      {
        Header: '',
        accessor: 'actions',
        className: 'min-w-100px text-center',
        Cell: ({cell}) => (
          <Link
            to={`/apps/permission-management/permissions/permission/${cell.row.values.id}/${cell.row.values.validation_field}`}
          >
              <button type='button' className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'>
            <i className="fa-duotone fa-eye"></i>
            </button>
      
          </Link>
        ),
      },
    ],
    []
  )

  return (
    <>

        
        <div className='app-container d-flex' style={{justifyContent: 'space-between'}}>
      <h3 className='card-title align-items-start flex-column pb-5'>
          <span className='fw-bold fs-2x mb-3' style={{ fontWeight:'400' }}>
          Gestión de permisos
          </span>
        </h3>

        <div className='d-flex align-items-center gap-2 gap-lg- pb-6'>
        <span className='text-gray-500 fs-7 me-2 fw-bold'>Cliente</span>
          {listClients?.length > 1 ? (
            <Select
              components={makeAnimated()}
              closeMenuOnSelect={true}
              options={listClients}
              onChange={onChangeClient}
              value={clientSelected}
              className="react-select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
              ...theme.colors,
              text: '#000',
              font:'#000',
              primary25: '#f1f1f4',
              primary: '#3615af',
              neutral80: '#000',
              color: '#000',
              },
            })}
              classNamePrefix='filter'
            />
          ) : (
            <h5>{listClients[0].label}</h5>
          )}
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <Table className='' data={tableData}  title='Permisos' columns={columns} />
        </div>
      </div>

      {showConfirmationModal === true && (
        <ConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          messagge={messagge}
        />
      )}
    </>
  )
}

export {PermissionsManagerPage}
