import { useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

const GaugeChart = ({
  id,
  title,
  subtitle,
  className,
  dataGraph = [],
  noDataMessage,
  width,
  height,
  settings,
}) => {
  useEffect(() => {
    if (dataGraph.full > 0) {
      let root = am5.Root.new(id)

      root.setThemes([am5themes_Animated.new(root)])

      let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          startAngle: 180,
          endAngle: 360,
        })
      )

      let axisRenderer = am5radar.AxisRendererCircular.new(root, {
        innerRadius: -10,
        strokeOpacity: 1,
        strokeWidth: 15,
        strokeGradient: am5.LinearGradient.new(root, {
          rotation: 0,
          stops: settings.colors,
        }),
      })

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          max: dataGraph.full || 100,
          strictMinMax: true,
          renderer: axisRenderer,
        })
      )

      let axisDataItem = xAxis.makeDataItem({})
      axisDataItem.set('value', 1)

      axisDataItem.set(
        'bullet',
        am5xy.AxisBullet.new(root, {
          sprite: am5radar.ClockHand.new(root, {
            radius: am5.percent(99),
          }),
        })
      )

      let clockHand = am5radar.ClockHand.new(root, {
        pinRadius: 30,
        radius: am5.percent(100),
        innerRadius: 50,
        bottomWidth: 0,
        topWidth: 0,
      })

      clockHand.pin.setAll({
        fillOpacity: 0,
        strokeOpacity: 0.5,
        stroke: am5.color(0x000000),
        strokeWidth: 1,
        strokeDasharray: [2, 2],
      })
      clockHand.hand.setAll({
        fillOpacity: 0,
        strokeOpacity: 0.5,
        stroke: am5.color(0x000000),
        strokeWidth: 0.5,
      })

      let bullet = axisDataItem.set(
        'bullet',
        am5xy.AxisBullet.new(root, {
          sprite: clockHand,
        })
      )

      xAxis.createAxisRange(axisDataItem)

      let label = chart.radarContainer.children.push(
        am5.Label.new(root, {
          centerX: am5.percent(50),
          textAlign: 'center',
          centerY: am5.percent(50),
          fontSize: '1.3rem',
        })
      )

      axisDataItem.get('grid').set('visible', false)
      axisDataItem.animate({
        key: 'value',
        to: dataGraph.value || 0,
        duration: 800,
        easing: am5.ease.out(am5.ease.cubic),
      })

      bullet.get('sprite').on('rotation', function () {
        var value = axisDataItem.get('value')
        label.set('text', Math.round(value).toString() + '')
      })

      chart.appear(1000, 100)

      return () => {
        root.dispose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {dataGraph.full > 0 && <span className='text-muted fw-semobold fs-7'>{subtitle}</span>}
        </h3>

        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary text-center'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          ></button>
        </div>
      </div>

      <div className='card-body pt-0'>
        {dataGraph.full > 0 ? (
          <div id={id} style={{ width: width, height: height }}></div>
        ) : (
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 text-center">

            <div className=" fw-semibold">
              <div className="text-gray-900 fw-bold">{noDataMessage}</div>
            </div>

          </div>
        )}
      </div>
    </div>
  )
}

export { GaugeChart }
