import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
const AsideToolbar = () => {
  const { currentUser } = useAuth()

  return (
    <>
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        <div className='symbol symbol-50px'>
          <img src={toAbsoluteUrl('/media/avatars/avantar-white.jpg')} alt='' />
        </div>

        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          <div className='d-flex'>
            <div className='flex-grow-1 me-2'>
              <a className='text-primary  fs-6 fw-bold' href='/#' >
                {currentUser?.data?.first_name} {currentUser?.data?.last_name}
              </a>

              <span className='text-primary fw-bold d-block fs-8 mb-1'>{currentUser?.data?.role[0].name || ''}</span>

              <div className='d-flex align-items-center text-white fs-9'>
               <div className='bg-success card-cabe'> <span className='bullet bullet-dot me-1 bg-white'></span>online</div>
              </div>
            </div>

            <div className='me-n2'>
              <a
                href='/#'
                className='btn btn-icon btn-sm btn-active-color-white mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTSVG
                  path='/media/icons/duotune/coding/cod001.svg'
                  className='svg-icon-primary svg-icon-12'
                />
              </a>
              <HeaderUserMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { AsideToolbar }
