import {useEffect, useState, useMemo} from 'react'
import {Table} from '../../components/table/Table'
import * as Api from 'app/api/Api'
import {useParams} from 'react-router-dom'

const StudientsByCourseTable = () => {
  const {id} = useParams()
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const [tableDataStudientsByCourse, setTableDataStudientsByCourse] = useState([])

  useEffect(() => {
    ;(async () => {
      const tableDataResponse = await Api.StudientsByCourse({courseId: id, token: user.api_token})
      setTableDataStudientsByCourse(tableDataResponse.table)
    })()
  }, [id, user.api_token])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        show: false,
      },
      {
        Header: '',
        accessor: 'last_name',
        show: false,
      },
      {
        Header: 'Nombre',
        accessor: 'first_name',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <span>
            {cell.row.values.first_name} {cell.row.values.last_name}
          </span>
        ),
      },
      {
        Header: 'Teléfono',
        accessor: 'phone',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <span className='text-gray-800 fw-bold d-block fs-6'>+{cell.row.values.phone}</span>
        ),
      },
      {
        Header: '% Progreso',
        accessor: 'progress_percent',
        className: 'text-center min-w-100px',
        Cell: ({cell}) => (
          <span className='badge badge-light-info'>{cell.row.values.progress_percent}%</span>
        ),
      },
      {
        Header: 'Ultima conexión',
        accessor: 'last_conection',
      },
      {
        Header: 'Certificado',
        accessor: 'link_certificate',
        className: 'text-center min-w-50px',
        Cell: ({cell}) => (
          <>
            {!!cell.row.values.link_certificate ? (
              <a
                target='_blank'
                href={cell.row.values.link_certificate}
                download={cell.row.values.name}
                rel='noreferrer'
              ><button type='button' className='btn btn-sm fw-bold btn-primary'>
                Descargar
                 </button>
              </a>
            ) : (
              <span className='badge badge-light-danger'>No disponible</span>
            )}
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      {tableDataStudientsByCourse && (
        <div className='row g-5 g-xl-8 pt-6'>
          <div className='col-xl-12'>
            <Table
              className=''
              data={tableDataStudientsByCourse}
              columns={columns}
              title='Estudiantes'
            />
          </div>
        </div>
      )}
    </>
  )
}

export {StudientsByCourseTable}
