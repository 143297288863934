const InfoCard = ({value, base, title, link, classes, subtitle, aditionalChart = ''}) => {
  return (
    <div className={classes.cardClasses} href={link}>
      <div className='card-body pt-5'>
      <div className={classes.titleClasses}>{title}</div>
               <div className='fw-semibold fs-1 text-gray-400'>
          <div className='d-flex align-items-center'>
            <span className={classes.value1Classes}>
              {typeof value !== 'object' && (
                <>{Number.isInteger(value) ? value.toFixed(2) : value}</>
              )}
            </span>
            {base != null && (
              <>
                <span>/</span>
                <span className={classes.value2Classes}>{base}</span>
              </>
            )}
            {aditionalChart}
          </div>
        </div>
       
        <div className={classes.subtitleClasses}>{subtitle}</div>
      </div>
    </div>
  )
}

export {InfoCard}
