import React, {useEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

const AreaChart = ({
  id,
  title,
  className,
  dataGraph = null,
  width,
  height,
  settings,
  info,
  loading,
}) => {
  useEffect(() => {
    let root = am5.Root.new(id)

    if (dataGraph) {
      root.setThemes([am5themes_Animated.new(root)])

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
          layout: root.verticalLayout,
          paddingRight: 0,
          paddingTop: 30,
          paddingBottom: 0,
          paddingLeft: 0,
        })
      )

      chart.get('colors').set('colors', settings.colors)

      let data = dataGraph

      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'zoomX',
        })
      )
      cursor.lineY.set('visible', false)

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: settings.categoryField,
          startLocation: 0.2,
          endLocation: 0.8,
          renderer: am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.2,
            cellEndLocation: 0.8,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      )
      xAxis.children.push(
        am5.Label.new(root, {
          text: settings.titleX || '',
          x: am5.p50,
          centerX: am5.p50,
        })
      )

      xAxis.data.setAll(data)

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
        })
      )
      yAxis.children.push(
        am5.Label.new(root, {
          rotation: -90,
          text: settings.titleY || '',
          y: am5.p50,
          x: -25,
          centerX: am5.p50,
        })
      )

      let y2Axis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
          }),
          syncWithAxis: yAxis,
        })
      )
      y2Axis.children.push(
        am5.Label.new(root, {
          rotation: 90,
          text: settings.titleY2 || '',
          y: am5.p50,
          x: 50,
          centerX: am5.p50,
        })
      )

      function createSeries(name, field, fill, opposite) {
        let serieTemp
        let series

        if (opposite) {
          serieTemp = am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: y2Axis,
            valueYField: field,
            categoryXField: settings.categoryField,
            // maxWidth: 40,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'horizontal',
              labelText: '[bold]{name}[/]\n{categoryX}: {valueY}',
            }),
          })
          series = chart.series.push(serieTemp)
        } else {
          serieTemp = am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: settings.categoryField,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'horizontal',
              labelText: '[bold]{name}[/]\n{categoryX}: {valueY}',
            }),
          })
          series = chart.series.push(serieTemp)
          series.fills.template.setAll({
            fillOpacity: fill,
            visible: true,
          })

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                fill: series.get('fill'),
                radius: 4,
              }),
            })
          })
        }

        series.data.setAll(data)
        series.appear(1000)
      }

      settings.series.forEach((serie) => {
        createSeries(serie.name, serie.field, serie.fill, serie.opposite)
      })

   
      let legend = chart.children.push(am5.Legend.new(root, {
        nameField: "categoryX", centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 3,
          fixedWidthGrid: true
        })
      }))
      legend.markers.template.setAll({
        width: 10,
        height: 10
      });
      legend.data.setAll(chart.series.values)
      chart.appear(1000, 100)
    }
    return () => {
      root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>

        {info ?? <></>}

        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          ></button>
        </div>
      </div>

      <div className='card-body pt-0 pb-0 margin-bottom'>
        {!loading ? (
          <>{!dataGraph && <h3>Sin datos</h3>}</>
        ) : (
          <>
            <div className='flex-column pb-4'>
              <span className='spinner-border spinner-border-sm align-middle text-primary'></span>
              <span className='px-2 text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </>
        )}
        <div id={id} style={!loading ? {width: width, height: height} : {}} />
      </div>
    </div>
  )
}

export {AreaChart}
