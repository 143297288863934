import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {REQUIRED_FILED} from 'app/constants/errorConstants'
import {useNavigate} from 'react-router-dom'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Formato de correo electrónico incorrecto').required(REQUIRED_FILED),
  password: Yup.string().required(REQUIRED_FILED),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [loginMessageError, setLoginMessageError] = useState()
  const {saveAuth, setCurrentUser} = useAuth()

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data} = await login(values.email, values.password)
        if (data.success) {
          if (data.data?.is_first_login === false && data.data?.is_password_expired === false) {
            if(data.data?.double_authentication === true) {
              localStorage.setItem('temp_user', JSON.stringify(data.data))
              navigate('/auth/code-verification')
            } else {
              saveAuth(data.data)
              const {data: user} = await getUserByToken(data.data?.api_token)
              localStorage.setItem('selected_customerId', data.data.customers[0].value)
              localStorage.setItem('auxToken', -1)
              setCurrentUser(user)
            }
          } else {
            // (data.data?.double_authentication === true) && localStorage.setItem('temp_user', JSON.stringify(data.data))
            saveAuth(data.data)
            localStorage.removeItem('selected_customerId')
            localStorage.setItem('auxToken', data.data?.api_token)
            navigate('/auth/reset-password')
          }
          
        } else {
          saveAuth(undefined)
          setLoginError(true)
          setLoginMessageError(data.message)
          setStatus(data.message)
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Iniciar sesión</h1>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Correo Electronico</label>
        <input
          placeholder='Ingrese su correo'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Ingrese su contraseña'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <div className='pt-6'>
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            ¿Has olvidado tu contraseña ?
          </Link>
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Iniciar sesión</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Cargando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {loginError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{loginMessageError}</span>
          </div>
        </div>
      )}
    </form>
  )
}
