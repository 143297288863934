import {secondsToHms} from '../../Utilities/Utils'

const CasouselSuperDashboard = ({cardData}) => {
  return (
    <>
      <div className='row g-5 g-xl-8 mb-5'>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Finalización</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${cardData.finalizacion}%`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>De todos los estudiantes</div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Enganche</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${cardData.enganche}%`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>De todos los estudiantes</div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Satisfacción</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${cardData.satisfaccion}`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>De todos los estudiantes</div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Logro de aprendizaje</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>      {`${cardData.aprendizaje}`}%</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>De todos los estudiantes</div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
            <div className='fw-bold mb-1 fs-6'>Tiempo</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${secondsToHms(
                cardData.tiempo_total
              )}`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>   Tiempo total de estudio</div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Utilidad</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'> {cardData.utilidad}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>  De todos los estudiantes  </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Recomendación</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'> {cardData.recomendacion}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>  De todos los cursos </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Tiempo promedio</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${secondsToHms(
                cardData.tiempo_promedio
              )}`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>   Tiempo promedio de estudio </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card bg-body-white'>
            <div className='card-body pt-5'>
              <div className='fw-bold mb-1 fs-6'>Microcursos</div>
              <div className='fw-semibold fs-1 text-gray-400'>
                <div className='d-flex align-items-center'>
                  <span className='text-gray-900 fw-bolder'>{`${cardData.microcurso}`}</span>
                </div>
              </div>
              <div className='text-gray-500 mt-1 fw-semibold fs-6'>       Microcurso por estudiante</div>
            </div>
          </div>
        </div>
     
      </div>
    </>
  )
}

export {CasouselSuperDashboard}
