import { useEffect, useState, useMemo } from 'react'
import * as Api from 'app/api/Api'
import { getOptions, isDateAfterToday } from '../../../Utilities/Utils'
import Modal from 'react-bootstrap/Modal'
import { SelectList } from '../../../components/selectList/SelectList'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { UserListComponent } from './UserListComponent'
import { CampaingListComponent } from './CampaingListComponent'
import { CourseListComponent } from './CourseListComponent'
import { InteractionListComponent } from './InteractionListComponent'
import ConfirmationModal from '../../../components/modal/confirmationModal'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './TrackingTab.scss'
import 'react-tooltip/dist/react-tooltip.css'
import { ChannelSelectList } from 'app/components/selectList/ChannelSelectList'


const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

const TrackingPage = ({ typeView, selected_customerIdSaved, selectedChannel }) => {
  const currentDate = new Date()
  const aWeekAgoDate = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000))
  console.log(aWeekAgoDate)

  const [cliente, setCliente] = useState('')
  const [campaing, setCampaing] = useState([])
  const [cursos, setCursos] = useState([])
  const [startDate, setStartDate] = useState(aWeekAgoDate)
  const [finishDate, setFinishDate] = useState(currentDate)
  const [errorDateMessage, setErrorDateMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [filter, setFilter] = useState(null)

  const [filtros, setFiltros] = useState({
    cliente: '',
    campaings: '',
    cursos: '',
    startDate: `${aWeekAgoDate.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(aWeekAgoDate)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(aWeekAgoDate)}`,
    finishDate: `${currentDate.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(currentDate)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)}`
  })

  const userId = user.id.toString()

  const [showConfirmationModal, setShowConfirmationModal] = useState(null)

  const [show, setShow] = useState(false)
  const [userListData, setUserListData] = useState([])
  const [userSelected, setUserSelected] = useState(null)

  const [showCourseTable, setShowCourseTable] = useState(false)

  const [campaingListData, setCampaingListData] = useState([])
  const [campaingSelected, setCampaingSelected] = useState(null)
  const [campaingNameSelected, setCampaingNameSelected] = useState(null)

  const [courseListData, setCourseListData] = useState([])
  const [courseSelected, setCourseSelected] = useState(null)

  const [chatData, setChatData] = useState(null)

  const [options, setOptions] = useState([])
  const [channelSelected, setChannelSelected] = useState()

  const aplicarFiltros = () => {
    if (!finishDate) {
      setErrorMessage('Debe seleccionar una fecha final')
      setErrorDateMessage(true)
      return
    }
    setErrorDateMessage(false)
    setShow(false)
    setUserSelected(null)
    setCampaingSelected(null)
    setCampaingNameSelected(null)
    setCampaingListData([])
    setCourseSelected(null)
    setChatData(null)
    setCourseListData(null)
    setFiltros({
      cliente: typeView === '1' ? cliente?.value ?? '' : '',
      campaings: campaing?.map((c) => c.value).join(','),
      cursos: cursos?.map((c) => c.value).join(','),
      startDate: `${startDate.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(startDate)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(startDate)}`,
      finishDate: `${finishDate.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(finishDate)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(finishDate)}`
    })
  }

  // const getOptions = async () => {
  //   let response = await Api.GetChannelList({
  //     token: user.api_token
  //   })
  //   if (response.success) {
  //     setOptions(response.data)

  //     setChannelSelected(response.data[0])
  //   }
  // }

  const [loadingUserList, setLoadingUserList] = useState(true)
  const [loadingCampaingList, setLoadingCampaingList] = useState(false)
  const [loadingCourseList, setLoadingCourseList] = useState(false)
  const [loadingInteractionList, setLoadingInteractionList] = useState(false)

  useEffect(() => {
    setShow(false)
    setUserSelected(null)
    setCampaingSelected(null)
    setCampaingNameSelected(null)
    setCampaingListData(null)
    setCourseListData(null)
    setCourseSelected(null)
    setChatData(null)

    setShowCourseTable(false)
  }, [selected_customerIdSaved])

  useEffect(() => {
    const filtrosTemp = {
      customerId: filtros.cliente,
      campaingsId: filtros.campaings,
      cursosId: filtros.cursos,
      startDate: filtros.startDate,
      finishDate: filtros.finishDate
    }
      ; (async () => {
        if (filter === null || filter?.length > 2 || filter === '') {
          if (!selectedChannel && !channelSelected) {
            await getOptions('', '', setOptions, setChannelSelected, user.api_token)
          }
          setLoadingUserList(true)
            ; (async () => {
              const apiResponse = await Api.UserListForTracking({
                token: user.api_token,
                filtros: filtrosTemp,
                phone: filter?.length > 2 ? filter : '',
                typeView: typeView,
                channel: (selectedChannel) ? selectedChannel : channelSelected.value
              })
              if (apiResponse.success) {
                setUserListData(apiResponse.data || [])
              } else {
                setUserListData([])
                setCampaingListData(null)
                setShowCourseTable(false)
                setUserSelected(null)
                setCampaingSelected(null)
                setCampaingNameSelected(null)
                setCampaingListData(null)
                setCourseSelected(null)
                setChatData(null)
                setCourseListData(null)
              }
              // setIsAvailableFilter(false)
              setLoadingUserList(false)
            })()
        }
      })()
  }, [userId, filtros, typeView, selected_customerIdSaved, filter, channelSelected, selectedChannel])

  useEffect(() => {
    if (userSelected) {
      ; (async () => {
        setLoadingCampaingList(true)
        const apiResponse = await Api.CampaingListForTracking({
          id_user: userSelected,
          id_userSelected: userSelected,
          typeView: typeView,
          token: user.api_token,
          channel: (selectedChannel) ? selectedChannel : channelSelected.value
        })
        if (apiResponse.success) {
          setCampaingListData(apiResponse.data || [])
        }
        setLoadingCampaingList(false)
      })()
    }
  }, [typeView, userId, userSelected, selected_customerIdSaved, selectedChannel, channelSelected])

  useEffect(() => {
    if (campaingSelected) {
      ; (async () => {
        setLoadingCourseList(true)
        const apiResponse = await Api.CourseListForTracking({
          id_user: userSelected,
          id_campaing: campaingSelected,
          token: user.api_token,
        })
        if (apiResponse.success) {
          setCourseListData(apiResponse.data || [])
        }
        setLoadingCourseList(false)
      })()
    }
  }, [campaingSelected, userSelected, selected_customerIdSaved])

  useEffect(() => {
    if (courseSelected && showCourseTable) {
      ; (async () => {
        setLoadingInteractionList(true)
        const apiResponse = await Api.InteractionsByCourse({
          id_user: userSelected,
          id_course: courseSelected,
          token: user.api_token,
        })
        if (apiResponse.success) {
          setChatData(apiResponse.data || [])
        }
        setLoadingInteractionList(false)
      })()
    }
  }, [courseSelected, showCourseTable, userSelected, selected_customerIdSaved])

  useEffect(() => {
    if (campaingSelected && !showCourseTable) {
      ; (async () => {
        setLoadingInteractionList(true)
        const apiResponse = await Api.InteractionsByCampaing({
          id_campaing: campaingSelected,
          id_user: userSelected,
          token: user.api_token,
        })
        if (apiResponse.success === true) {
          setChatData(apiResponse.data || [])
        } else {
          setChatData([])
          setShowConfirmationModal(true)
        }
        setLoadingInteractionList(false)
      })()
    }
  }, [campaingSelected, showCourseTable, userSelected, userId])

  const columnsUserList = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      { Header: '', accessor: 'phone', show: false },
      { Header: '', accessor: 'last_interaction', show: false },
      {
        Header: '',
        accessor: 'user',
        Cell: ({ cell }) => (
          <div
            className='card-user d-flex flex-stack py-4'
            onClick={() => {
              setUserSelected(cell.row.values.id)
              setCampaingSelected(null)
              setCampaingNameSelected(null)
              setCourseSelected(null)
              setChatData(null)
              setCourseListData([])
            }}
            style={{
              cursor: 'pointer',
              backgroundColor: userSelected === cell.row.values.id ? '#f5f5f5' : 'transparent',
            }}
          >
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-30px '>
                <img src='/media/logo-user.png' alt='' />
              </div>
              <div className='ms-3'>
                <span className='fs-5 fw-bold text-gray-900  mb-2'>+{cell.row.values.phone}</span>
                <div className='text-muted fs-6'>
                  {cell.row.values.user.substring(0, 15)}
                </div>
              </div>
            </div>
            <div className='d-flex flex-column align-items-end ms-2'>
              <span className='badge badge-light fw-bold my-2'>
                {isDateAfterToday(cell.row.values.last_interaction)}
              </span>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros, userSelected]
  )

  const columnsCampaingList = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      { Header: '', accessor: 'customer', show: false },
      {
        Header: 'Campañas',

        accessor: 'campaing',
        Cell: ({ cell }) => (
          <div
            className='card-user d-flex flex-stack py-4'
            style={{
              backgroundColor: userSelected === cell.row.values.id ? '#d2cbff' : 'transparent',
            }}
          >
            <div className='d-flex  flex-column'>
              <span className='fs-5 fw-bold text-gray-900 text-capitalize'>
                {cell.row.values.customer}
              </span>
              <div className='text-muted fs-6 '>
                {cell.row.values.campaing.substring(0, 25)}
              </div>
            </div>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <span
                id={`viewCoursesTooltip-` + cell.row.values.id}
                data-tooltip-content='Ver cursos'
              >
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary btn-primary course-button'
                  onClick={() => {
                    setShowCourseTable(true)
                    setCampaingSelected(cell.row.values.id)
                    setCampaingNameSelected(cell.row.values.campaing)
                    setCourseSelected(null)
                    setChatData(null)
                  }}
                >
                  <i className='fa-duotone fa-chalkboard-user'></i>
                  <ReactTooltip
                    place='bottom'
                    anchorId={`viewCoursesTooltip-` + cell.row.values.id}
                  />
                </button>
              </span>

              <span
                id={`viewChatsTooltip-` + cell.row.values.id}
                data-tooltip-content='Ver interacciones de bienvenida'
              >
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary btn-success course-button'
                  onClick={() => {
                    setShowCourseTable(false)
                    setCampaingSelected(cell.row.values.id)
                    setCampaingNameSelected(cell.row.values.campaing)
                  }}
                >
                  <i className='fa-duotone fa-comments'></i>
                  <ReactTooltip
                    place='bottom'
                    anchorId={`viewChatsTooltip-` + cell.row.values.id}
                  />
                </button>
              </span>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros, campaingSelected]
  )

  const columnsCourseList = useMemo(
    () => [
      { Header: '', accessor: 'id', show: false },
      {
        Header: 'Cursos',
        accessor: 'course',
        Cell: ({ cell }) => (
          <div
            className='card-user d-flex flex-stack py-4'
            style={{
              backgroundColor: userSelected === cell.row.values.id ? '#d2cbff' : 'transparent',
            }}
          >
            <div className='d-flex align-items-center'>
              <span className='fs-5 fw-bold text-gray-900 text-capitalize'>
                {cell.row.values.course.substring(0, 50)}...
              </span>
            </div>
            <div className='d-flex flex-column align-items-end ms-2'>
              <span
                id={`viewChatsTooltip2-` + cell.row.values.id}
                data-tooltip-content='Ver interacciones del curso'
              >
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary btn-success course-button'
                  onClick={() => {
                    setCourseSelected(cell.row.values.id)
                    setChatData([])
                  }}
                >
                  <i className='fa-duotone fa-comments'></i>
                  <ReactTooltip
                    place='bottom'
                    anchorId={`viewChatsTooltip2-` + cell.row.values.id}
                  />
                </button>
              </span>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtros, courseSelected, campaingSelected]
  )

  return (
    <>
      {(typeView === '1') &&
        <div className='d-flex align-items-center gap-2 gap-lg- pb-6'>
          <span className='text-gray-500 fs-7 me-2 fw-bold'>Canales</span>
          <ChannelSelectList
            options={options}
            channelSelected={channelSelected}
            setChannelSelected={setChannelSelected}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}
          />
        </div>
      }
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className='containerTable'>
            <UserListComponent
              loadingUserList={loadingUserList}
              userListData={userListData}
              setShow={setShow}
              columnsUserList={columnsUserList}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </div>

        <div className='col-xl-4'>
          <div className='containerTable'>
            <CampaingListComponent
              loadingCampaingList={loadingCampaingList}
              campaingListData={campaingListData}
              columnsCampaingList={columnsCampaingList}
            />
          </div>
        </div>

        {showCourseTable && (
          <div className='col-xl-4'>
            <div className='containerTable'>
              <CourseListComponent
                loadingCourseList={loadingCourseList}
                courseListData={courseListData}
                campaingNameSelected={campaingNameSelected}
                columnsCourseList={columnsCourseList}
              />
            </div>
          </div>
        )}

        <div className='col-xl-12'>
          <>
            <InteractionListComponent
              loadingInteractionList={loadingInteractionList}
              chatData={chatData}
            />
          </>
        </div>
      </div>

      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filtros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            {typeView === '1' && (
              <div className='col-12'>
                <SelectList
                  title={'Cliente'}
                  apiService={Api.ClientList}
                  args={{ token: user.api_token, id_user: userId, todos: true }}
                  conditions={true}
                  handleChange={(client) => {
                    setCliente(client)
                    setCampaing(null)
                    setCursos(null)
                  }}
                  value={cliente}
                  defaultSetterFunction={setCliente}
                />
              </div>
            )}

            <div className='col-12'>
              <SelectList
                title={'Campañas'}
                apiService={typeView === '1' ? Api.CampaignsListByClient : Api.CampaignsList}
                args={{
                  token: user.api_token,
                  client_id: typeView === '1' ? cliente?.value || '' : '',
                  id_user: userId,
                  todos: true,
                }}
                conditions={true}
                handleChange={(campaing) => {
                  if (typeView === '1') {
                    if (campaing.length === 0) setCursos([])
                    setCampaing(campaing)
                  } else {
                    const hasTodos =
                      campaing.some((c) => c.label === 'Todos') &&
                      campaing?.some((c) => c.label !== 'Todos')
                    if (hasTodos) setCampaing([{ value: '', label: 'Todos' }])
                    else {
                      const hasTodos2 = campaing?.some((c) => c.label === 'Todos')
                      if (hasTodos2) {
                        setCampaing(campaing.filter((c) => c.label !== 'Todos'))
                      } else {
                        setCampaing(campaing)
                      }
                    }
                  }
                }}
                multiple={true}
                value={campaing}
                defaultSetterFunction={setCampaing}
              />
            </div>

            <div className='col-12'>
              <SelectList
                title={'Cursos'}
                apiService={Api.CourseListWithAll}
                args={{
                  token: user.api_token,
                  id_user: userId,
                  campaingId: campaing?.map((c) => c.value).join(','),
                }}
                conditions={true}
                value={cursos}
                handleChange={(courses) => {
                  const hasTodos =
                    courses.some((c) => c.label === 'Todos') &&
                    cursos?.some((c) => c.label !== 'Todos')
                  if (hasTodos) setCursos([{ value: '', label: 'Todos' }])
                  else {
                    const hasTodos2 = cursos?.some((c) => c.label === 'Todos')
                    if (hasTodos2) {
                      setCursos(courses.filter((c) => c.label !== 'Todos'))
                    } else {
                      setCursos(courses)
                    }
                  }
                }}
                multiple={true}
                defaultSetterFunction={setCursos}
              />
            </div>
            <div className='col-12'>
              <label className='fw-bold fs-7 text-gray-600 mb-1'>Fecha de inicio:</label>
              <DatePicker
                className='form-control '
                locale='es'
                selected={startDate}
                onChange={(dateX) => dateX && setStartDate(dateX)}
                dateFormat='dd/MM/yyyy'
              />
            </div>
            <div className='col-12'>
              <label className='fw-bold fs-7 text-gray-600 mb-1'>Fecha final:</label>
              <DatePicker
                className='form-control '
                locale='es'
                selected={finishDate}
                onChange={(dateX) => {
                  let startDateCompare = startDate.getTime()
                  let finishDateCompare = dateX.getTime()

                  if (startDateCompare >= finishDateCompare) {
                    setErrorDateMessage(true)
                  } else {
                    setErrorDateMessage(false)
                    setFinishDate(dateX)
                  }
                }}
                dateFormat='dd/MM/yyyy'
              />
              {errorDateMessage &&
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>
                    <span role='alert'>{(errorMessage) ? errorMessage : 'La fecha final debe ser mayor a la inicial.'}</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ margin: 'auto' }}>
          <button
            type='button'
            className='btn btn-sm fw-bold btn-primary'
            onClick={aplicarFiltros}
            disabled={campaing?.length === 0 || cursos?.length === 0 || errorDateMessage}
          >
            <span className='fw-bold '>Aplicar</span>
          </button>
        </Modal.Footer>
      </Modal>

      {showConfirmationModal === true && (
        <ConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          messagge={'No se encuentraron datos de bienvenida'}
        />
      )}
    </>
  )
}

export { TrackingPage }
