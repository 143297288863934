import {useParams, useNavigate, Link} from 'react-router-dom'
import * as Api from 'app/api/Api'
import {useState, useEffect, useCallback} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import {REQUIRED_FILED} from 'app/constants/errorConstants'
import {useFormik} from 'formik'
import {SelectList} from '../../../components/selectList/SelectList'
import {UserStateList, TipoEnvioList} from '../../../api/Api'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es'
import DatePicker from 'react-datepicker'
import Modal from 'react-bootstrap/Modal'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import ConfirmationModal from '../../../components/modal/confirmationModal'

const CreateNotification = () => {
  const company = JSON.parse(useParams().company)
  const campaing = JSON.parse(useParams().campaing)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()
  const navigate = useNavigate()

  const [gupshop, setGupshop] = useState(null)
  const [currentMedia, setCurrentMedia] = useState(null)
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fileReader = new FileReader()

  const [notifications, setNotifications] = useState([])
  const [wrongNumbers, setWrongNumbers] = useState([])
  const [tipoEnvio, setTipoEnvio] = useState(null)
  const [fileData, setFileData] = useState([])
  const [fileMediaUpload, setFileMediaUpload] = useState(null)
  const [fileMediaUploadUrl, setFileMediaUploadUrl] = useState(null)
  const [targetUploadMedia, setTargetUploadMedia] = useState(null)
  const [hasErrorFile, setHasErrorFile] = useState(false)
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(false)

  const [userListAvailable, setUserListAvailable] = useState(null)

  const [showConfirmationModal, setShowConfirmationModal] = useState(null)
  const [messagge, setMesaggeModal] = useState('Se completo la operacion satisfactoriamente')

  registerLocale('es', es)
  const [fechaInicio, setFechaInicio] = useState(new Date())
  const [fechaInicioFormated, setFechaInicioFormated] = useState(null)
  const [timeInicioFormated, setTimeInicioFormated] = useState(null)

  const ct = new Date()
  const dateCurrFormatted = ct.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  const minC = dateCurrFormatted.split(':')[1]
  const minH = dateCurrFormatted.split(':')[0]

  if (minC < 30) ct.setMinutes(30)
  else {
    ct.setHours(parseInt(minH) + 1)
    ct.setMinutes(0)
  }
  ct.setSeconds(0)

  const [timeInicio, setTimeInicio] = useState(ct)
  const [programado, setProgramado] = useState(false)

  const [show, setShow] = useState(false)
  const regexNumbers = /^[1-9]\d{10,12}$/

  const addUserSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FILED),
    message: Yup.string().required(REQUIRED_FILED),
    messageByTemplate: Yup.string().required(REQUIRED_FILED),
    recipients: Yup.string().required(REQUIRED_FILED),
    mobileNumbers: Yup.string()
      .notRequired()
      .test('validNumbers', 'Los números deben ser válidos (formato E.64)', (text, context) => {
        if (text === undefined || context.parent.recipients !== 'textbox') return true
        return text
          .split(',')
          .filter((item) => item !== '')
          .every((item) => regexNumbers.test(item))
      })
      .test('empyArray', 'Ingrese al menos un número', (text, context) => {
        if (text !== undefined || context.parent.recipients !== 'textbox') return true
        else
          return context.createError({
            message: `Ingrese al menos un número`,
            path: `mobileNumbers`,
          })
      }),
    allNotifications: Yup.string(),
    notification: Yup.string(),
  })

  const [userForAdd] = useState({
    name: '',
    message: 'template',
    messageByTemplate: '',
    recipients: 'textbox',
    mobileNumbers: '',
    allNotifications: false,
    notification: '',
  })

  const formik = useFormik({
    initialValues: userForAdd,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        let dataToSave = {}
        let dataToSchedule = {}
        let isScheduled = false
        let notificationType = values.recipients
        dataToSave['name'] = values.name
        dataToSave['isTemplate'] = values.message === 'template'
        dataToSave['message'] = values.messageByTemplate || ''
        dataToSave['templateId'] = gupshop?.value || ''
        dataToSave['typeSend'] = tipoEnvio?.value || ''

        if (fechaInicioFormated) {
          isScheduled = true
          dataToSchedule['date'] = fechaInicioFormated
          if (timeInicioFormated) {
            dataToSchedule['time'] = (timeInicioFormated.length > 8) ? timeInicioFormated.slice(0, -2) : timeInicioFormated
          } else {
            dataToSchedule['time'] = '00:00:00'
          }
        } else {
          dataToSchedule['date'] = ''
          dataToSchedule['time'] = ''
        }

        // eslint-disable-next-line default-case
        switch (values.recipients) {
          case 'textbox':
            const numbersTextBox = values.mobileNumbers.split(',')
            dataToSave['userState'] = ''
            dataToSave['allNotifications'] = ''
            dataToSave['recipients'] = numbersTextBox
            break
          case 'statusUser':
            dataToSave['userState'] = userListAvailable.value
            if (values.allNotifications) dataToSave['allNotifications'] = ''
            else dataToSave['allNotifications'] = values.notification
            dataToSave['recipients'] = []
            break
          case 'file':
            dataToSave['userState'] = ''
            dataToSave['allNotifications'] = ''
            dataToSave['recipients'] = fileData
            break
        }
        setLoading(true)
        const response = await Api.CreateNotification({
          campaingId: campaing.id,
          id_user: userId,
          notificationType,
          dataToSave,
          isScheduled,
          dataToSchedule,
          isMedia: fileMediaUploadUrl !== null,
          typeMedia: gupshop.type,
          mediaUrl: fileMediaUploadUrl,
        })
        resetForm()
        setLoading(false)
        if (response?.success === false) setMesaggeModal(response?.message || 'Error')
        setShowConfirmationModal(true)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleOnChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        setHasErrorFile(false)
        const csvOutput = event.target.result.split(/\r?\n|\r|\n/g)

        let wrongNumbersTemp = []
        for (let index = 0; index < csvOutput.length; index++) {
          if (regexNumbers.test(csvOutput[index]) === false) {
            wrongNumbersTemp.push(csvOutput[index])
          }
        }
        setWrongNumbers(wrongNumbersTemp)

        const isCorrect = csvOutput
          .join(',')
          .split(',')
          .filter((item) => item !== '')
          .every((item) => regexNumbers.test(item))
        if (isCorrect) {
          setHasErrorFile(false)
          setFileData(csvOutput)
        } else {
          setHasErrorFile(true)
        }
      }
      fileReader.readAsText(file)
    }
  }

  const isGupshupMedia = useCallback(
    () =>
      gupshop?.type?.toUpperCase() === 'IMAGE' ||
      gupshop?.type?.toUpperCase() === 'DOCUMENT' ||
      gupshop?.type?.toUpperCase() === 'VIDEO',
    [gupshop?.type]
  )

  const fileAcceptByMediaGupshup = () => {
    switch (gupshop?.type?.toUpperCase()) {
      case 'IMAGE':
        return '.jpge, .png'
      case 'VIDEO':
        return '.mp4'
      case 'DOCUMENT':
        return '.pdf'
      default:
        return '*'
    }
  }

  const handleOnChangeMediaFile = (e) => {
    const isSizeValidate = (type, size) => {
      switch (type) {
        case 'image/png':
          return size <= 2
        case 'image/jpge':
          return size <= 2
        case 'video/mp4':
          return size <= 20
        case 'application/pdf':
          return size <= 2
        default:
          return false
      }
    }
    if (!targetUploadMedia) setTargetUploadMedia(e.target)
    const file = e.target.files[0]
    if (file && isSizeValidate(file.type, file.size / 1024 / 1024)) {
      setFileMediaUpload(file)
    } else {
      e.target.value = null
      setFileMediaUpload(null)
      setFileMediaUploadUrl(null)
      setIsDisabledButtonSend(true)
    }
  }

  useEffect(() => {
    setFileMediaUpload(null)
    setFileMediaUploadUrl(null)
    if (targetUploadMedia) targetUploadMedia.value = null
    if (isGupshupMedia()) setIsDisabledButtonSend(true)
    else setIsDisabledButtonSend(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gupshop, isGupshupMedia])

  useEffect(() => {
    if (fileMediaUpload) {
      ;(async () => {
        const dataResponse = await Api.ValidarFileUpload({
          fileMediaUpload: fileMediaUpload,
          token: user.api_token,
        })
        setFileMediaUploadUrl(dataResponse.data?.blob_url || null)
        setIsDisabledButtonSend(false)
      })()
    }
  }, [fileMediaUpload, user.api_token])

  useEffect(() => {
    if (campaing?.id && userListAvailable?.value) {
      ;(async () => {
        const dataResponse = await Api.NotificationList({
          statusUser: userListAvailable.value,
          campaingId: campaing?.id,
          token: user.api_token,
        })
        setNotifications(dataResponse.data)
      })()
    }
  }, [campaing?.id, user.api_token, userListAvailable?.value])

  const filterPassedTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)
    const currentFormated =
      fechaInicio.getFullYear() +
      '-' +
      ('0' + parseInt(fechaInicio.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + fechaInicio.getDate()).slice(-2)

    const selectedFormated =
      selectedDate.getFullYear() +
      '-' +
      ('0' + parseInt(selectedDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + selectedDate.getDate()).slice(-2)

    if (currentFormated === selectedFormated) return currentDate.getTime() < selectedDate.getTime()
    else return true
  }

  return (
    <>
      <Link
        to={`/apps/notification-detail/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}
        className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
      >
        <i className='fa-solid fa-arrow-left'></i>
        <span className='fw-bold '>Regresar</span>
      </Link>

      <>
        <h3 className='card-title align-items-start flex-column pb-5'>
          <span className='fw-bold fs-2x mb-3'>Crear Notificación</span>
        </h3>

        <div className='card'>
          <div className='card-body'>
            <form
              id='kt_modal_add_user_form'
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className='d-flex flex-column me-n7 pe-7'>
                <div className='row'>
                  <div className='col-xl-12 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Nombre de la Notificación</label>
                    <input
                      placeholder='Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-xl-2 mb-5'>
                    <div role='group'>
                      <label className='required fw-bold fs-6 mb-2'>Mensaje por</label>
                      <div>
                        <input
                          type='radio'
                          name='message'
                          value='template'
                          onChange={formik.getFieldProps('message').onChange}
                          defaultChecked={formik.getFieldProps('message').value === 'template'}
                          required
                        />
                        <label style={{marginLeft: 6}} htmlFor='template'>
                          Plantilla
                        </label>
                      </div>
                      {formik.touched.message && formik.errors.message && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.message}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-7 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Plantillas Gupshup</label>
                    <div className='row'>
                      <div className='col-xl-11'>
                        <SelectList
                          apiService={Api.GupshopTemplateList}
                          args={{
                            token: user.api_token,
                            campaingId: campaing.id,
                            id_user: userId,
                          }}
                          conditions={campaing?.id !== null}
                          handleChange={(gupshop) => {
                            if (isGupshupMedia()) setCurrentMedia(gupshop.media)
                            else setCurrentMedia(null)
                            formik.setFieldValue('messageByTemplate', gupshop.label)
                            setGupshop(gupshop)
                          }}
                          multiple={false}
                          defaultSetterFunction={setGupshop}
                          copyDefaultSetterFunction={(label) =>
                            formik.setFieldValue('messageByTemplate', label)
                          }
                          handleCustomLabel={function (data) {
                            return (
                              <>
                                <span dangerouslySetInnerHTML={{__html: data.label}} />
                                <span
                                  style={{float: 'right'}}
                                  dangerouslySetInnerHTML={{__html: data.type}}
                                />
                              </>
                            )
                          }}
                        />
                      </div>
                      <div className='col-xl-1'>
                        {currentMedia && (
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-light btn-active-light-primary  h-25px w-25px'
                            onClick={() => {
                              window.open(currentMedia, '_blank', 'noreferrer')
                            }}
                          >
                            <i className='fa-duotone fa-download'></i>
                          </button>
                        )}
                      </div>
                    </div>

                    {!gupshop?.value && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gupshopTempId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-3 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Tipo de envio</label>
                    <SelectList
                      apiService={TipoEnvioList}
                      args={{
                        token: user.api_token,
                      }}
                      conditions={true}
                      handleChange={(envio) => setTipoEnvio(envio)}
                      multiple={false}
                      defaultSetterFunction={setTipoEnvio}
                    />
                    {tipoEnvio == null ||
                      (tipoEnvio?.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Seleccione algun tipo</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-xl-12 mb-8'>
                    <label className='required fw-bold fs-6 mb-2'>Mensaje</label>
                    <textarea
                      placeholder=''
                      {...formik.getFieldProps('messageByTemplate')}
                      type='textarea'
                      name='messageByTemplate'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={true}
                    />
                    {formik.errors.messageByTemplate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.messageByTemplate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mt-4'>
                  <div className='col-xl-12 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Destinatarios</label>
                    <div>
                      <input
                        type='radio'
                        name='recipients'
                        value='textbox'
                        onChange={formik.getFieldProps('recipients').onChange}
                        defaultChecked={formik.getFieldProps('recipients').value === 'textbox'}
                        required
                      />
                      <span
                        id='fileTooltipTextbox'
                        data-tooltip-content='Ingresar numeros en formato E.164 ejem: 51999888777'
                      >
                        <label style={{marginLeft: 6}} htmlFor='textbox'>
                          From Textbox{' '}
                          <i style={{marginLeft: 3}} className='fa-light fa-circle-info'></i>
                          <ReactTooltip place='right' anchorId='fileTooltipTextbox' />
                        </label>
                      </span>

                      <input
                        style={{marginLeft: 30}}
                        type='radio'
                        name='recipients'
                        value='statusUser'
                        onChange={formik.getFieldProps('recipients').onChange}
                        defaultChecked={formik.getFieldProps('recipients').value === 'statusUser'}
                        required
                      />
                      <label style={{marginLeft: 6}} htmlFor='statusUser'>
                        From Status User
                      </label>

                      <input
                        style={{marginLeft: 30}}
                        type='radio'
                        name='recipients'
                        value='file'
                        onChange={formik.getFieldProps('recipients').onChange}
                        defaultChecked={formik.getFieldProps('recipients').value === 'file'}
                        required
                      />
                      <span
                        id='fileTooltipfile'
                        data-tooltip-content='Ingresar numeros en formato E.164 ejem: 51999888777'
                      >
                        <label style={{marginLeft: 6}} htmlFor='file'>
                          From File{' '}
                          <i style={{marginLeft: 3}} className='fa-light fa-circle-info'></i>
                          <ReactTooltip place='right' anchorId='fileTooltipfile' />
                        </label>
                      </span>

                      {formik.touched.recipients && formik.errors.recipients && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.recipients}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {formik.getFieldProps('recipients').value === 'textbox' && (
                  <div className='row '>
                    <div className='col-xl-12 mb-5'>
                      <textarea
                        placeholder='Ingrese los numeros separados por (,)'
                        {...formik.getFieldProps('mobileNumbers')}
                        type='textarea'
                        name='mobileNumbers'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                      />
                      {formik.errors.mobileNumbers && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.mobileNumbers}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {formik.getFieldProps('recipients').value === 'statusUser' && (
                  <div className='row'>
                    <div className='col-xl-12 mb-5'>
                      <label className='required fw-bold fs-6 mb-2'>Estado de usuario</label>
                      <SelectList
                        apiService={UserStateList}
                        args={{
                          token: user.api_token,
                          id_user: user.api_token,
                          campaingId: campaing.id,
                        }}
                        conditions={true}
                        handleChange={(user) => setUserListAvailable(user)}
                        multiple={false}
                        defaultSetterFunction={setUserListAvailable}
                      />
                      {userListAvailable == null ||
                        (userListAvailable?.length === 0 && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>Seleccione algun usuario</span>
                            </div>
                          </div>
                        ))}
                      <div>
                        <div className='pt-4'>
                          <label>
                            <input
                              name='allNotifications'
                              type='checkbox'
                              onChange={formik.handleChange}
                              value='true'
                            />
                            <span style={{marginLeft: 4}}> Enviar todas las notificaciones</span>
                          </label>
                        </div>
                        {formik.getFieldProps('allNotifications').value !== true && (
                          <div className='row pt-4'>
                            {notifications.map((check, index) => {
                              return (
                                <div className='col-3  pt-2' key={index}>
                                  <input
                                    type='radio'
                                    name='notification'
                                    value={check.value}
                                    onChange={formik.getFieldProps('notification').onChange}
                                    defaultChecked={
                                      formik.getFieldProps('notification').value === check.value
                                    }
                                    required
                                  />
                                  <label style={{marginLeft: 6}} htmlFor={check.value}>
                                    {check.label}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {formik.getFieldProps('recipients').value === 'file' && (
                  <div className='row mb-7'>
                    <div className='col-xl-6 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>
                        <p
                          id='fileTooltip'
                          data-tooltip-content='Colocar los números en formato E.164 en la primera columna'
                        >
                          Seleccionar .csv <i className='fa-light fa-circle-info'></i>
                        </p>
                        <ReactTooltip place='right' anchorId='fileTooltip' />
                      </label>
                      <div>
                        <input
                          type={'file'}
                          id={'csvFileInput'}
                          accept={'.csv'}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        {hasErrorFile && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>Algún número no coincide con el formato:</span>
                              <div>
                                {wrongNumbers.map((item, index) => {
                                  return (
                                    <span key={index} role='alert'>
                                      {item}
                                      {index < wrongNumbers.length - 1 && <span>, </span>}
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isGupshupMedia() && (
                  <div className='row mt-4'>
                    <div className='col-xl-10 mb-2'>
                      <label className='required fw-bold fs-6 mb-2'>Adjuntar archivo</label>

                      <div className='mt-2'>
                        <input
                          type={'file'}
                          id={'fileMediaUpload'}
                          accept={fileAcceptByMediaGupshup()}
                          onChange={handleOnChangeMediaFile}
                        />
                      </div>
                    </div>

                    <div className='col-xl-4 mb-4'>
                      <div className='alert alert-dark mt-4' role='alert'>
                        <p>
                          <strong>Nota: </strong>
                          {gupshop?.type?.toUpperCase() === 'IMAGE' && (
                            <span>Imagen: JPEG o PNG (Max 2 MB)</span>
                          )}
                          {gupshop?.type?.toUpperCase() === 'VIDEO' && (
                            <span>Video: MP4 (Max 20 MB)</span>
                          )}
                          {gupshop?.type?.toUpperCase() === 'DOCUMENT' && (
                            <span>Documento: PDF (Max 2 MB)</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='text-right pt-2'>
                <button
                  type='button'
                  onClick={() => setShow(true)}
                  className={`btn me-3 ${programado ? 'btn-success' : 'btn-secondary'}`}
                  data-kt-users-modal-action='cancel'
                >
                  <span>{`${programado ? 'Programado' : 'Programar'}`}</span>
                  {programado ? (
                    <i style={{marginLeft: 5}} className='fa-solid fa-check'></i>
                  ) : (
                    <i style={{marginLeft: 5}} className='fa-light fa-calendar-xmark'></i>
                  )}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.touched ||
                    gupshop?.value === undefined ||
                    isDisabledButtonSend
                  }
                >
                  {loading ? (
                    <span>
                      Cargando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <span className='indicator-label'>Guardar</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>

      <Modal
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Programar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-6'>
              <div className='fw-bold fs-7 text-gray-600 mb-1'>Fecha:</div>
              <DatePicker
                className='form-control '
                locale='es'
                selected={fechaInicio}
                onChange={(dateX) => dateX && setFechaInicio(dateX)}
                dateFormat='dd/MM/yyyy'
                minDate={new Date()}
              />
            </div>
            <div className='col-3'>
              <div className='fw-bold fs-7 text-gray-600 mb-1'>Hora:</div>
              <DatePicker
                className='form-control'
                locale='es'
                selected={timeInicio}
                onChange={(time) => time && setTimeInicio(time)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='h:mm aa'
                filterTime={filterPassedTime}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{margin: 'auto'}}>
          <button
            type='button'
            className='btn btn-sm fw-bold btn-secondary'
            onClick={() => {
              setFechaInicioFormated(null)
              setTimeInicioFormated(null)
              setProgramado(false)
              setShow(false)
            }}
          >
            <span className='fw-bold '>Descartar</span>
          </button>

          <button
            type='button'
            style={{marginLeft: 6}}
            className='btn btn-sm fw-bold btn-primary'
            onClick={() => {
              setFechaInicioFormated(
                fechaInicio
                  ? fechaInicio.getFullYear() +
                      '-' +
                      ('0' + parseInt(fechaInicio.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + fechaInicio.getDate()).slice(-2)
                  : null
              )
              setTimeInicioFormated(
                timeInicio.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
              )
              setProgramado(true)
              setShow(false)
            }}
          >
            <span className='fw-bold '>Programar</span>
          </button>
        </Modal.Footer>
      </Modal>

      {showConfirmationModal === true && (
        <ConfirmationModal
          setShowConfirmationModal={() =>
            navigate(
              `/apps/notification-detail/${JSON.stringify(company)}/${JSON.stringify(campaing)}`
            )
          }
          messagge={messagge}
        />
      )}
    </>
  )
}

export {CreateNotification}
