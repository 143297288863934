import { GetChannelList } from "app/api/Api"

export function getPeriodList() {
  return [
    {
      value: '24h',
      label: '24 Hrs',
    },
    {
      value: '7d',
      label: '7 Días',
    },
    {
      value: '30d',
      label: '30 Días',
    },
    {
      value: '60d',
      label: '60 Días',
    },
    {
      value: '90d',
      label: '90 Días',
    },
  ]
}

export function getPeriodList2() {
  return [
    {
      value: 'custom',
      label: 'Personalizado',
    },
    {
      value: '1w',
      label: 'Esta semana',
    },
    {
      value: '7d',
      label: 'Ultimos 7 Días',
    },
    {
      value: '30d',
      label: 'Este mes',
    },
    {
      value: '1y',
      label: 'Este año',
    },
  ]
}

export function getPeriodListTime() {
  return [
    {
      value: 'custom',
      label: 'Personalizado',
    },
    {
      value: 'all',
      label: 'Todo el día',
    },
  ]
}

export function getAgrupationList() {
  return [
    {
      value: 'day',
      label: 'Actividad Diaria (DAU)',
    },
    {
      value: 'hour',
      label: 'Actividad horaria (HAU)',
    },
  ]
}

export function secondsToHms(d) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)

  var hDisplay = h > 0 ? h + (h === 1 ? ' hr ' : ' hrs ') : ''
  var mDisplay = m + ' min'
  return `${hDisplay} ${mDisplay}`
}

export function SplitTime(numberOfHours) {
  var Days = Math.floor(numberOfHours / 24)
  var Remainder = numberOfHours % 24
  var Hours = Math.floor(Remainder)
  var Minutes = Math.floor(60 * (Remainder - Hours))
  return `${Days}d ${Hours}hr ${Minutes}m`
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function formatDate(date) {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/'
  )
}

export function formatHour(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function isDateAfterToday(date) {
  if (isYesterday(new Date(date))) return 'Ayer'
  else {
    return new Date(date) > new Date(new Date().toDateString())
      ? formatHour(new Date(date))
      : formatDate(new Date(date))
  }
}

function isYesterday(date) {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  if (yesterday.toDateString() === date.toDateString()) {
    return true
  }

  return false
}

export function transformDataTableToDataGraphTopUsers(dataTable) {
  return dataTable.reduce((acc, x) => {
    if (acc.find((y) => y.customer === x.customer)) return acc.concat([])
    const totalProject = dataTable
      .filter((y) => y.customer === x.customer)
      .map((y) => y.study_time)
      .reduce((a, b) => a + b, 0)
    return acc.concat([
      {
        customer: x.customer,
        value: totalProject,
      },
    ])
  }, [])
}

export function transformDataTableToDataGraphActiveCourses(dataTable) {
  return dataTable.reduce((acc, x) => {
    if (acc.find((y) => y.customer === x.customer)) return acc.concat([])
    const totalProject = dataTable
      .filter((y) => y.customer === x.customer)
      .map((y) => y.progress)
      .reduce((a, b) => a + b, 0)
    const prom = totalProject / dataTable.filter((y) => y.customer === x.customer).length
    return acc.concat([
      {
        customer: x.customer,
        value: Math.round(prom * 100) / 100,
      },
    ])
  }, [])
}

export const trimString = (str, limit) => (str.length > limit) ? str.substring(0, limit).concat('...') : str

export const removeDecimals = (num) => Math.trunc(num)

export const hideEmailAddress = (email) => `${email.substring(0, 2)}*****${email.substring(email.lastIndexOf('@'))}`

export const hidePhoneNumber = (phone) => `*******${phone.substring(phone.length - 2)}`


export const getOptions = async (client = '', campaing = '', setOptions = null, setChannelSelected = null, token) => {
  let response = await GetChannelList({
    customerId: client,
    campaingId: campaing,
    token: token
  })
  if (response.success) {
    if(setOptions) setOptions(response.data)
    if(setChannelSelected) setChannelSelected(response.data[0])
  }
}