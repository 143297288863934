import {useParams, useNavigate, Link} from 'react-router-dom'
import * as Api from 'app/api/Api'
import {useState, useEffect} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import {REQUIRED_FILED} from 'app/constants/errorConstants'
import {useFormik} from 'formik'
import {SelectList} from '../../../../components/selectList/SelectList'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {ParamsSelect} from './paramsSelect'
import {CallToActions} from './callToActions'
import {QuickReplies} from './quickReplies'
import {Modal} from 'react-bootstrap'
import Picker from 'emoji-picker-react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import defaultProduct1 from '../../../../../media/bicho6.svg'

const CreateTemplate = () => {
  const company = JSON.parse(useParams().company)
  const campaing = JSON.parse(useParams().campaing)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()
  const navigate = useNavigate()
  const animatedComponents = makeAnimated()

  const [headerType, setHeaderType] = useState({value: '0', label: 'SIN CABECERA'})
  const [headerText, setHeaderText] = useState('')
  const [headerTypeMedia, setHeaderTypeMedia] = useState(null)

  const [templateLanguaje, setTemplateLanguaje] = useState(null)
  const [focusTemplateLanguaje, setFocusTemplateLanguaje] = useState(false)

  const [loading, setLoading] = useState(false)
  const [buttonSelected, setButtonSelected] = useState({value: '', label: 'Ninguna'})

  const [paramsSelected, setParamsSelected] = useState({})
  const [cTASelected, setCTASelected] = useState(null)
  const [qrSelected, setQrSelected] = useState(null)

  const [fileMediaUpload, setFileMediaUpload] = useState(null)
  const [fileMediaUploadUrl, setFileMediaUploadUrl] = useState(null)
  const [fileMediaUploadContentType, setFileMediaUploadContentType] = useState(null)
  const [targetUploadMedia, setTargetUploadMedia] = useState(null)
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(true)

  const [showPicker, setShowPicker] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const [categoryListService, setCategoryListService] = useState([])

  const onEmojiClick = (event, emojiObject) => {
    formik.setFieldValue(
      'templateFormatInput',
      formik.getFieldProps('templateFormatInput').value + event.emoji
    )
    formik.setFieldValue(
      'sampleMessage',
      formik.getFieldProps('templateFormatInput').value + event.emoji
    )
    setShowPicker(false)
  }

  const addUserSchema = Yup.object().shape({
    campaing: Yup.string().required(REQUIRED_FILED),
    templateName: Yup.string()
      .required(REQUIRED_FILED)
      .matches(new RegExp(/^[a-z0-9_]*$/), 'Error en el formato del nombre'),
    templateLabels: Yup.string().required(REQUIRED_FILED),
    templateCategory: Yup.string().required(REQUIRED_FILED).nullable(),

    withParams: Yup.string().required(REQUIRED_FILED),
    templateFormatInput: Yup.string()
      .required(REQUIRED_FILED)
      .max(1024, 'El número de caracteres máximo es de 1024')
      .test('varibales', 'Ingrese todas las variables requeridas', (text, context) => {
        if (formik.getFieldProps('withParams').value.toString() === 'false') return true
        else if (JSON.stringify(paramsSelected) === '{}') {
          return context.createError({
            message: `Seleccione al menos una variable`,
            path: `templateFormatInput`,
          })
        }

        if (paramsSelected.param1 && text?.includes(`{{1}}`)) {
          if (!paramsSelected.param2) return true
          else if (text?.includes(`{{2}}`)) return true
          else {
            return context.createError({
              message: `Ingrese todas las variables requeridas`,
              path: `templateFormatInput`,
            })
          }
        } else {
          return context.createError({
            message: `Ingrese todas las variables requeridas`,
            path: `templateFormatInput`,
          })
        }
      }),
    footer: Yup.string().max(60, 'Máximo 60 caracteres'),
    sampleMessage: Yup.string()
      .required(REQUIRED_FILED)
      .when('withParams', (withParams, schema) => {
        if (withParams) {
          return Yup.string()
            .required(REQUIRED_FILED)
            .test(
              'no equals',
              'El cuerpo y el mensaje de ejemplo no coinciden',
              function noEquals(val) {
                const templateFormatInputWords = formik
                  .getFieldProps('templateFormatInput')
                  .value.trim()
                  .split(/\s+/).length

                const sampleMessageWords = formik
                  .getFieldProps('sampleMessage')
                  .value.trim()
                  .split(/\s+/).length

                if (templateFormatInputWords === sampleMessageWords) return true
                return this.createError({
                  path: `${this.path}`,
                  message: 'El cuerpo y el mensaje de ejemplo no coinciden',
                })
              }
            )
        }
        return schema
      }),
  })

  const [userForAdd] = useState({
    campaing: campaing.name,
    templateName: '',
    templateLabels: '',
    templateCategory: null,

    withParams: false,
    templateFormatInput: '',
    footer: '',
    sampleMessage: '',
  })

  const formik = useFormik({
    initialValues: userForAdd,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        let dataToSave = {}
        dataToSave['name'] = values.templateName
        dataToSave['category'] = values.templateCategory
        dataToSave['language'] = templateLanguaje.value
        dataToSave['label'] = values.templateLabels
        dataToSave['headerType'] = headerType.value

        if (headerType.value === '1') {
          dataToSave['headerText'] = headerText
        }
        if (headerType.value === '2') {
          dataToSave['headerTypeMedia'] = headerTypeMedia.value
          dataToSave['headerUrlMedia'] = fileMediaUploadUrl
          dataToSave['headerTypeFile'] = fileMediaUploadContentType
        }

        dataToSave['containsParams'] = values.withParams
        if (values.withParams) dataToSave['bodyParams'] = paramsSelected
        dataToSave['body'] = values.templateFormatInput

        dataToSave['footer'] = values.footer

        dataToSave['typeButton'] = buttonSelected?.value
        if (buttonSelected?.value === '') dataToSave['typeButtonObject'] = []
        if (buttonSelected?.value === 'CTA') dataToSave['typeButtonObject'] = cTASelected
        if (buttonSelected?.value === 'QUICK_REPLY') dataToSave['typeButtonObject'] = qrSelected

        dataToSave['sampleMessage'] = values.sampleMessage

        setLoading(true)
        ;(async () => {
          const response = await Api.CreateTemplate({
            campaingId: campaing.id,
            id_user: userId,
            dataToSave,
          })
          if (response.data.status === 'success') {
            setShowModal(true)
            resetForm()
            setLoading(false)
          }
        })()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const isHeaderMedia = () => headerType?.label?.toUpperCase() === 'MULTIMEDIA'

  const fileAcceptByMediaGupshup = () => {
    switch (headerTypeMedia?.label?.toUpperCase()) {
      case 'IMAGE':
        return '.jpge, .png'
      case 'VIDEO':
        return '.mp4'
      case 'DOCUMENT':
        return '.pdf'
      default:
        return '*'
    }
  }

  const handleOnChangeMediaFile = (e) => {
    const isSizeValidate = (type, size) => {
      switch (type) {
        case 'image/png':
          return size <= 2
        case 'image/jpge':
          return size <= 2
        case 'video/mp4':
          return size <= 20
        case 'application/pdf':
          return size <= 2
        default:
          return false
      }
    }
    if (!targetUploadMedia) setTargetUploadMedia(e.target)
    const file = e.target.files[0]
    if (file && isSizeValidate(file.type, file.size / 1024 / 1024)) {
      setFileMediaUpload(file)
    } else {
      e.target.value = null
      setFileMediaUpload(null)
      setFileMediaUploadUrl(null)
      setFileMediaUploadContentType(null)
      setIsDisabledButtonSend(true)
    }
  }

  useEffect(() => {
    if (fileMediaUpload) {
      ;(async () => {
        const dataResponse = await Api.ValidarFileUpload({
          fileMediaUpload: fileMediaUpload,
          token: user.api_token,
        })
        setFileMediaUploadUrl(dataResponse.data?.blob_url || null)
        setFileMediaUploadContentType(dataResponse.data?.blob_content_type || null)
        setIsDisabledButtonSend(false)
      })()
    }
  }, [fileMediaUpload, user.api_token])

  useEffect(() => {
    setFileMediaUpload(null)
    setFileMediaUploadUrl(null)
    setFileMediaUploadContentType(null)
    if (targetUploadMedia) targetUploadMedia.value = null
    if (isHeaderMedia()) setIsDisabledButtonSend(true)
    else setIsDisabledButtonSend(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTypeMedia])

  useEffect(() => {
    ;(async () => {
      const tableDataResponse = await Api.TemplateCategoryList({
        token: user.api_token,
      })
      setCategoryListService(tableDataResponse.data)
    })()
  }, [user.api_token])

  return (
    <>
      <Link
        to={`/apps/template-list/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}
        className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
      >
        <i className='fa-solid fa-arrow-left'></i>
        <span className='fw-bold '>Regresar</span>
      </Link>

      <>
        <h3 className='card-title align-items-start flex-column pb-5'>
          <span className='fw-bold fs-2x mb-3'>Crear Template</span>
        </h3>

        <div className='card'>
          <div className='card-body'>
            <form
              id='kt_modal_add_user_form'
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className='row mb-4'>
                <div className='col-xl-4 ' style={{padding: 0}}>
                  <label className='required fw-bold fs-6 mb-2'>Campaña</label>
                  <input
                    {...formik.getFieldProps('campaing')}
                    type='text'
                    name='campaing'
                    className={'form-control form-control-solid mb-3 mb-lg-0'}
                    disabled={true}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='required fs-4 fw-bold text-gray-800 '>
                    Nombre del template
                  </label>

                  <div className='text-gray-600 fw-semibold d-block'>
                    Dale un nombre a tu plantilla de mensaje
                  </div>

                  <input
                    {...formik.getFieldProps('templateName')}
                    type='text'
                    name='templateName'
                    className={clsx(
                      'form-control ',
                      {
                        'is-invalid': formik.touched.templateName && formik.errors.templateName,
                      },
                      {
                        'is-valid': formik.touched.templateName && !formik.errors.templateName,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  <span className='text-gray-600 fw-semibold fs-7'>
                    Los nombres de las plantillas solo pueden contener letras minúsculas, números y
                    guiones bajos.
                  </span>
                  {formik.touched.templateName && formik.errors.templateName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.templateName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='required fs-4 fw-bold text-gray-800 '>Categorías</label>

                  <div className='text-gray-600 fw-semibold d-block mb-6 mb-4'>
                    Elija qué tipo de plantilla de mensaje desea crear.
                  </div>

                  {categoryListService.length !== 0 &&
                    categoryListService.map((category) => (
                      <div key={category.value} className='mb-4'>
                        <div className='form-check form-check-custom '>
                          <input
                            type='radio'
                            className='form-check-input'
                            name='templateCategory'
                            value={category.label}
                            onChange={formik.getFieldProps('templateCategory').onChange}
                            defaultChecked={
                              formik.getFieldProps('templateCategory').value === category.label
                            }
                            required
                          />
                          <label className='form-check-label' htmlFor={category.label}>
                            {category.label}
                          </label>
                          <div>
                            <label
                              className='fs-6 fw-normal text-gray-700 mb-5'
                              htmlFor={category.description}
                            >
                              {category.description}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  {formik.errors.templateCategory && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.templateCategory}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='required fs-4 fw-bold text-gray-800 '>Idioma</label>

                  <div className='text-gray-600 fw-semibold d-block mb-4'>
                    Elija en qué idiomas se enviará su plantilla
                  </div>

                  <SelectList
                    apiService={Api.TemplateLanguajeList}
                    args={{
                      token: user.api_token,
                      template: 'Languaje',
                    }}
                    conditions={campaing?.id !== null}
                    handleChange={(languaje) => setTemplateLanguaje(languaje)}
                    multiple={false}
                    indexDefaultValue={56}
                    defaultSetterFunction={setTemplateLanguaje}
                    handleFocus={() => setFocusTemplateLanguaje(true)}
                  />
                  {!templateLanguaje?.value && focusTemplateLanguaje && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{REQUIRED_FILED}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='required fs-4 fw-bold text-gray-800 '>
                    Etiquetas de plantilla
                  </label>

                  <div className='text-gray-600 fw-semibold d-block mb-4'>
                    Defina qué caso de uso sirve esta plantilla, por ejemplo, actualización de
                    cuenta, OTP, etc en 2-3 palabras
                  </div>

                  <input
                    {...formik.getFieldProps('templateLabels')}
                    type='text'
                    name='templateLabels'
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid': formik.touched.templateLabels && formik.errors.templateLabels,
                      },
                      {
                        'is-valid': formik.touched.templateLabels && !formik.errors.templateLabels,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.templateLabels && formik.errors.templateLabels && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.templateLabels}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='fs-4 fw-bold text-gray-800 '>
                    Cabecera <span className='badge badge-secondary'>Opcional</span>
                  </label>

                  <div className='text-gray-600 fw-semibold d-block mb-4'>
                    Agregue un título de 60 caracteres a su mensaje. No se admiten variables en el
                    encabezado.
                  </div>

                  <Select
                    classNamePrefix='filter'
                    components={animatedComponents}
                    options={[
                      {value: '0', label: 'SIN CABECERA'},
                      {value: '1', label: 'TEXTO'},
                      {value: '2', label: 'MULTIMEDIA'},
                    ]}
                    defaultValue={{value: '0', label: 'SIN CABECERA'}}
                    value={headerType}
                    headerType
                    onChange={(ht) => {
                      if (ht.value === '0') setIsDisabledButtonSend(false)
                      else {
                        setIsDisabledButtonSend(true)
                        if (ht.value === '2') setHeaderTypeMedia({value: 'IMAGE', label: 'IMAGE'})
                      }
                      setHeaderType(ht)
                    }}
                  />

                  {headerType?.value === '1' && (
                    <>
                      <label className='required fw-bold fs-6 mb-2'>Texto de cabecera</label>
                      <input
                        type='text'
                        name='headerText'
                        className={'form-control'}
                        autoComplete='off'
                        value={headerText}
                        onChange={(e) => {
                          if (e.target.value.length <= 60) {
                            setIsDisabledButtonSend(e.target.value === '')
                            setHeaderText(e.target.value)
                          }
                        }}
                        disabled={formik.isSubmitting}
                      />
                      {headerText?.replace(/ /g, '') === '' && headerType?.value === '1' && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{REQUIRED_FILED}</span>
                          </div>
                        </div>
                      )}
                      {headerText.length > 60 && headerType?.value === '1' && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Máximo 60 caracteres</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  {headerType?.value === '2' && (
                    <>
                      <div className='d-flex justify-content-start mb-6'>
                        <div className='border bg-white rounded min-w-100px py-3 px-4 my-1 me-6 form-check form-check-custom'>
                          <input
                            type='radio'
                            value='IMAGE'
                            className='form-check-input'
                            checked={headerTypeMedia.value === 'IMAGE'}
                            onChange={(e) => {
                              setHeaderTypeMedia({value: e.target.value, label: 'Image'})
                            }}
                          />
                          <div className='radio-image'>
                            <img
                              className='imagen-radio'
                              alt=''
                              src='https://www.gupshup.io/whatsapp/assets/images/icons/media_image.svg'
                            />
                            <label className='form-check-label'>Imagen</label>
                          </div>
                        </div>

                        <div className='border bg-white rounded  py-3 px-4 my-1 me-6 form-check form-check-custom'>
                          <input
                            type='radio'
                            value='VIDEO'
                            className='form-check-input'
                            checked={headerTypeMedia.value === 'VIDEO'}
                            onChange={(e) => {
                              setHeaderTypeMedia({value: e.target.value, label: 'Video'})
                            }}
                          />
                          <div className='radio-image'>
                            <img
                              className='imagen-radio'
                              alt=''
                              src='https://www.gupshup.io/whatsapp/assets/images/icons/media_video.svg'
                            />
                            <label className='form-check-label'>Video</label>
                          </div>
                        </div>

                        <div className=' border bg-white rounded  py-3 px-4 my-1 me-6 form-check form-check-custom'>
                          <input
                            type='radio'
                            value='DOCUMENT'
                            className='form-check-input'
                            checked={headerTypeMedia.value === 'DOCUMENT'}
                            onChange={(e) => {
                              setHeaderTypeMedia({value: e.target.value, label: 'Document'})
                            }}
                          />
                          <div className='radio-image'>
                            <img
                              className='imagen-radio'
                              alt=''
                              src='https://www.gupshup.io/whatsapp/assets/images/icons/media_document.svg'
                            />
                            <label className='form-check-label'>Documento</label>
                          </div>
                        </div>
                      </div>

                      <div className=' mb-4'>
                        <div className='required fs-4 fw-bold text-gray-800 mb-4'>
                          Agregar medios de muestra
                        </div>

                        <input
                          type={'file'}
                          id={'fileMediaUpload'}
                          accept={fileAcceptByMediaGupshup()}
                          onChange={handleOnChangeMediaFile}
                        />
                      </div>
                      <div className='alert alert-warning d-flex align-items-center p-5 mb-10'>
                        <div className='d-flex flex-column'>
                          <div className='text-gray-800 mb-4'>
                            <strong className='h5'>Nota:</strong> Los siguientes son los formatos de
                            archivo y sus respectivos tamaños que se aceptan para cargar
                          </div>
                          <ol className='text-gray-800'>
                            <li value='1'>Imagen: JPEG y PNG (Tamaño máximo 2 MB) </li>
                            <li>Vídeo: MP4 (Tamaño máximo 20 MB)</li>
                            <li>Documento: PDF (Tamaño máximo 2 MB) </li>
                          </ol>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <div role='group'>
                    <label className='required fs-4 fw-bold text-gray-800 '>Cuerpo</label>

                    <div className='text-gray-600 fw-semibold d-block mb-4'>
                      Introduce el texto de tu mensaje en el idioma que hayas seleccionado
                    </div>

                    <div className='form-check form-check-custom'>
                      <div className='me-5'>
                        <input
                          type='radio'
                          name='withParams'
                          value={false}
                          className='form-check-input'
                          onChange={(val) => {
                            formik.getFieldProps('withParams').onChange(val)
                            if (val.target.value.toString() === 'false')
                              formik.setFieldValue(
                                'sampleMessage',
                                formik.getFieldProps('templateFormatInput').value
                              )
                            setTimeout(() => formik.setFieldError('templateFormatInput', null), 100)
                          }}
                          defaultChecked={!formik.getFieldProps('withParams').value}
                          required
                        />
                        <label className='form-check-label' htmlFor='parametros'>
                          Sin parámetros
                        </label>
                      </div>

                      <div className=''>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='withParams'
                          value={true}
                          onChange={(val) => {
                            formik.getFieldProps('withParams').onChange(val)
                            setTimeout(() => formik.setFieldError('templateFormatInput', null), 100)
                          }}
                          defaultChecked={formik.getFieldProps('withParams').value}
                          required
                        />
                        <label className='form-check-label' htmlFor='parametros'>
                          Con parámetros
                        </label>
                      </div>
                    </div>

                    {formik.touched.withParams && formik.errors.withParams && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.withParams}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row mt-4'>
                    <div className='col-xl-12'>
                      <textarea
                        rows='5'
                        placeholder='Cuerpo de la plantilla'
                        {...formik.getFieldProps('templateFormatInput')}
                        type='textarea input-style'
                        name='templateFormatInput'
                        className='form-control'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                          formik.setFieldValue('templateFormatInput', e.target.value)
                          formik.setFieldValue('sampleMessage', e.target.value)
                        }}
                      />
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span className='mt-2 text-gray-600 fw-semibold fs-7'>
                          Caracteres: {formik.getFieldProps('templateFormatInput').value.length}
                          /1024
                        </span>
                        <label className='mt-2' style={{fontSize: '11px'}}>
                          <span className='emoji-bg' onClick={() => setShowPicker((val) => !val)} />
                          {showPicker && (
                            <Picker pickerStyle={{width: '100%'}} onEmojiClick={onEmojiClick} />
                          )}
                        </label>
                      </div>
                      {formik.errors.templateFormatInput && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.templateFormatInput}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {formik.getFieldProps('withParams').value === true.toString() && (
                  <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 mt-4'>
                    <>
                      <span
                        id='conParemtros'
                        data-tooltip-content='Seleccionar mínimo 1 , máximo los 3'
                      >
                        <label className='fs-4 fw-bold text-gray-800 ' htmlFor='withParams'>
                          Selecciona los parametros <i className='fa-light fa-circle-info'></i>
                          <ReactTooltip place='right' anchorId='conParemtros' />
                        </label>
                      </span>

                      <div>
                        <span>
                          {`Su cuerpo de  plantilla debe contener todos los numeros de los parametros que selecciono a la derecha entre doble llaves (ejemplo: Bienvenido, {{1}})`}
                        </span>
                      </div>
                    </>

                    <ParamsSelect setParamSelect={setParamsSelected}></ParamsSelect>
                  </div>
                )}
              </div>

              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className=' fs-4 fw-bold text-gray-800 '>
                    Pie de página <span className='badge badge-secondary'>Opcional</span>
                  </label>

                  <div className='text-gray-600 fw-semibold d-block mb-4'>
                    Agregue un pie de página de 60 caracteres a su mensaje. No se admiten variables
                    en el pie de página.
                  </div>

                  <input
                    {...formik.getFieldProps('footer')}
                    type='text'
                    name='footer'
                    className={'form-control '}
                    disabled={formik.isSubmitting}
                  />

                  {formik.errors.footer && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.footer}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className='fs-4 fw-bold text-gray-800 '>
                    Botones <span className='badge badge-secondary'>Opcional</span>
                  </label>

                  <Select
                    classNamePrefix='filter'
                    components={animatedComponents}
                    options={[
                      {value: '', label: 'Ninguna'},
                      {value: 'CTA', label: 'Llamada a acciones'},
                      {value: 'QUICK_REPLY', label: 'Respuestas rápidas'},
                    ]}
                    defaultValue={{value: '', label: 'Ninguna'}}
                    value={buttonSelected}
                    onChange={setButtonSelected}
                  />
                </div>
              </div>

              {buttonSelected?.value === 'CTA' && (
                <div className='row mb-4'>
                  <CallToActions setCTA={setCTASelected}></CallToActions>
                </div>
              )}

              {buttonSelected?.value === 'QUICK_REPLY' && (
                <div className='row mb-4'>
                  {' '}
                  <QuickReplies setQR={setQrSelected}></QuickReplies>
                </div>
              )}

              <div className='row mb-4'>
                <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 '>
                  <label className=' required fs-4 fw-bold text-gray-800 '>
                    Mensaje de ejemplo
                  </label>

                  <div className='text-gray-600 fw-semibold d-block mb-4'>
                    Un mensaje de muestra debe ser un mensaje completo que reemplace el marcador de
                    posición dado en la plantilla con una palabra
                    significativa/afirmación/números/especiales caracteres. El marcador de posición
                    debe comenzar y terminar con corchetes. Para ejemplo,
                    <strong>su código de verificación es 232322</strong>
                  </div>

                  <textarea
                    placeholder=''
                    {...formik.getFieldProps('sampleMessage')}
                    type='textarea'
                    name='sampleMessage'
                    rows='5'
                    className='form-control'
                    autoComplete='off'
                    disabled={
                      formik.isSubmitting ||
                      formik.getFieldProps('withParams').value.toString() === 'false'
                    }
                  />
                  {formik.errors.sampleMessage && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sampleMessage}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-12 text-right'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.touched ||
                      loading ||
                      isDisabledButtonSend
                    }
                  >
                    {loading ? (
                      <span>
                        Cargando...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <span className='indicator-label'>Enviar</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>

      <Modal
        className='modal fade '
        show={showModal}
        aria-hidden='true'
        centered
        onHide={() => setShowModal(false)}
      >
        <div className='modal-content '>
          <div className='modal-body '>
            <img src={defaultProduct1} alt='' width='60' height='60'></img>
            <h5 className='fw-bolder fs-1 mb-5'>Creación Exitosa</h5>
            <div>
              <span>Se creó la template exitosamente</span>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setShowModal(false)
                navigate(
                  `/apps/template-list/${JSON.stringify(company)}/${JSON.stringify(campaing)}`
                )
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {CreateTemplate}
