import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import './confirmationModal.scss'

const ConfirmationModal = ({
  setShowConfirmationModal,
  messagge,
  type,
  onClickSecondary,
  secondaryButtonText = null,
  setGoValidateRequest = null,
}) => {
  return (
    <>
      <Modal
        size='sm'
        show={true}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
        className='modal fade show d-block'
        role='dialog'
        tabIndex={2}
        aria-modal='true'
        centered
      >
        <Modal.Header>
          <Modal.Title>Mensaje</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{messagge ? messagge : 'Se completo la operacion satisfactoriamente'}</p>
        </Modal.Body>

        <Modal.Footer>
          {secondaryButtonText && (
            <Button variant={'secondary'} onClick={onClickSecondary}>
              {secondaryButtonText}
            </Button>
          )}
          <Button
            variant={type === false ? 'danger' : 'primary'}
            onClick={() => {
              setShowConfirmationModal(false)
              setGoValidateRequest && setGoValidateRequest(true)
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ConfirmationModal
