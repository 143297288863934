const apiUrl = 'https://musaprodwsweb.azurewebsites.net/api/';
// const apiUrl = 'https://musaqawsweb.azurewebsites.net/api/';

const apiAuthentication = apiUrl + 'authentication';
const apiDashboard = apiUrl + 'dashboard/';
const apiCampaign = apiUrl + 'campaing/';
const apiAdmin = apiUrl + 'admin/';
const apiStudient = apiUrl + 'student/';
const apiResource = apiUrl + 'resource/';
const apiGeneral = apiUrl + 'general/';
const apiNotification = apiUrl + 'notification/';
const apiTemplate = apiUrl + 'template/';
const apiGupshup = apiUrl + 'gupshup/';
const apiValidate = apiUrl + 'validate/';
const apiSAdmin = apiUrl + 'sadmin/';
const apiSGeneral = apiSAdmin + 'general/';
const apiSChat = apiSAdmin + 'chat/';
const apiReport = apiUrl + 'report/';

export const GET_USER_BY_ACCESSTOKEN_URL = `${apiAuthentication}/verify_token`
export const LOGIN_URL = `${apiAuthentication}/login`
export const SendCode = `${apiAuthentication}/otp/send`
export const ValidateCode = `${apiAuthentication}/otp/verify`

export const DashboardCards = apiDashboard + "resume";
export const Usuariosgraph = apiDashboard + "user";
export const Performance = apiDashboard + "performance";

export const Campaigns = apiDashboard + "campaing";

export const RoleList = apiAdmin + "roleList";
export const AddRole = apiAdmin + "newRole";
export const EditRole = apiAdmin + "editRole";
export const RemoveRole = apiAdmin + "removeRole";
export const PermissionList = apiAdmin + "permissionsList";
export const UsersList = apiAdmin + "userList";
export const AddUser = apiAdmin + "newUser";
export const EditUser = apiAdmin + "editUser";
export const RemoveUser = apiAdmin + "removeUser";

export const StudientsList = apiStudient + "studentList";
export const RemoveStudient = apiStudient + "removeStudent";
export const AddStudient = apiStudient + "newStudent";
export const EditStudient = apiStudient + "editStudent";
export const CompanyList = apiNotification + "companyList";
export const CourseList = apiResource + "list";
export const SCourseList = apiGeneral + "resource";
export const NotificationListByCompany = apiNotification + "campaingList ";
export const ProfileStudent = apiStudient + "profile";

export const CoursesCards = apiResource + "cards";
export const TableCourses = apiResource + "table";

export const CardsInfoByCourse = apiResource + "resume";
export const HorasFinalizacionByCourse = apiResource + "hour";
export const DiasFinalizacionByCourse = apiResource + "day";
export const StudientsByCourse = apiResource + "student";
export const DataChatBotSimulationByCourse = apiResource + "conversation";
export const OpenAndCloseQuestions = apiResource + "answer";

export const StudentsDashboardCards = apiStudient + "resume";
export const StudentsDashboardTable = apiStudient + "resumeDetail";


export const CampaignsList = apiCampaign + "list";

export const GeneralDashboardCards = apiGeneral + "cards";
export const StudentActivityGraph = apiGeneral + "studentGraph";
export const TimeGeneralDashboard = apiGeneral + "timeStudy ";
export const CursosChartGeneralDashboard = apiGeneral + "courseActivity ";
export const ActiveStudentsDashboardTable = apiGeneral + "studentTable";

export const ChannelList = apiGeneral + "channel";

export const DownloadDataGeneralTab = apiGeneral + "report";
export const TableNotifications = apiNotification + "list";
export const UserStateList = apiNotification + "status";
export const NotificationList = apiNotification + "optionList";
export const GupshopTemplateList = apiNotification + "templateList";
export const CreateNotification = apiNotification + "send";
export const ValidarFileUpload = apiNotification + "uploadmedia";
export const LastNotificationsDashboard = apiNotification + "last";

export const TemplateCategoryList = apiNotification + "templateCategory";
export const TemplateTypeList = apiNotification + "templateType";
export const TemplateLanguajeList = apiNotification + "templateLanguage";
export const TipoEnvioList = apiNotification + "typeSend ";
export const RemoveNotification = apiNotification + "remove";
export const DownloadRecients = apiNotification + "recipient";

export const NumerosVariablesList = apiTemplate + "numerosVariablesList ";
export const NombresVariablesList = apiTemplate + "nombresVariablesList ";
export const TypeCTAList = apiTemplate + "typeCTAList ";

export const TableTemplates = apiGupshup + "templateTable";
export const RemoveTemplate = apiGupshup + "templateRemove";
export const CreateTemplate = apiGupshup + "templateCreate";

export const TablePermissionsManagment = apiValidate + "campaingTable";
export const TablePermissions = apiValidate + "userTable";
export const ChangePermission = apiValidate + "changePermission";
export const AddPermission = apiValidate + "addPermission";

export const UserBySuperAdmin = apiSGeneral + "user";
export const InteractionsBySuperAdmin = apiSGeneral + "interaction";
export const TopUsersBySuperAdmin = apiSGeneral + "usertop";
export const ActiveCampaingBySuperAdmin = apiSGeneral + "campaing";
export const ActiveCourseTableBySuperAdmin = apiSGeneral + "resource";
export const DashboardCardsBySuperAdmin = apiSGeneral + "metric";
export const DownloadSuperDataGeneralTab = apiSGeneral + "report";
export const CampaignsListByClient = apiGeneral + "campaing";
export const ClientList = apiGeneral + "customer";

export const UserListForTracking = apiSChat + "user"
export const CampaingListForTracking = apiSChat + "campaing"
export const CourseListForTracking = apiSChat + "resource"
export const InteractionsByCourse = apiSChat + "resourceChat"
export const InteractionsByCampaing = apiSChat + "campaingChat"

export const DauMau = apiSGeneral + "dauMau"
export const DauMauRatio = apiSGeneral + "dauMauRatio"

export const ReportsList = apiReport + "list";
export const ReportsGenerate = apiReport + "generate";



