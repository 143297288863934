import axios from 'axios';
import * as PathNames from './PathNames';

export const Performance = async (args) => {
  let response;
  await (axios.post(PathNames.Performance,
    {
      periodId: args.periodId,
      campaingId: args.campaingId,
      canalId: args.channel
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}

export const Usuariosgraph = async (args) => {
  let response;
  await (axios.post(PathNames.Usuariosgraph,
    {
      periodId: args.periodId,
      campaingId: args.campaingId,
      canalId: args.channel
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}

export const DashboardCards = async (args) => {
  let response;
  await (axios.post(PathNames.DashboardCards,
    {
      periodId: args.periodId,
      campaingId: args.campaingId,
      canalId: args.channel
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}

export const Campaigns = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.Campaigns,
    { selected_customerId },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}

export const RoleList = async (args) => {
  let response;
  await (axios.get(PathNames.RoleList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const EditRoleRequest = async (args, token) => {
  let response;

  await (axios.post(PathNames.EditRole, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const AddRoleRequest = async (args, token) => {
  let response;
  await (axios.post(PathNames.AddRole, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const RemoveRoleRequest = async (args, token) => {
  let response;

  await (axios.post(PathNames.RemoveRole, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const PermissionList = async (args) => {
  let response;
  await (axios.get(PathNames.PermissionList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const UsersList = async (args) => {
  let response;
  await (axios.post(PathNames.UsersList, { roleList: args.roleList },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const AddUserRequest = async (args, token) => {
  let response;
  await (axios.post(PathNames.AddUser, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const EditUserRequest = async (args, token) => {
  let response;
  await (axios.post(PathNames.EditUser, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const RemoveUser = async (args, token) => {
  let response;
  await (axios.post(PathNames.RemoveUser, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const StudientsList = async (args) => {
  // let response;
  // await (axios.post(PathNames.StudientsList, {},
  //   { headers: { 'Authorization': `Bearer ${args.token}` } }
  // ).then(res => response = res.data));
  // return response;
  return { "message": "", "success": true, "data": [{ "id": "com1", "first_name": "Juan", "last_name": "Lopez Aliaga", "email": "studnet1@gmail.com", "registration_date": "12/03/1994", "whatsapp_number": "(+51) 980678654", "company": "UNMSM", "course": "Algebra", "studient_state": "Activo" }] }
}

export const RemoveStudient = async (args, token) => {
  let response;
  await (axios.post(PathNames.RemoveStudient, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const AddStudientRequest = async (args, token) => {
  let response;
  await (axios.post(PathNames.AddStudient, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const EditStudientRequest = async (args, token) => {
  let response;
  await (axios.post(PathNames.EditStudient, args,
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  ).then(res => response = res.data));
  return response;
}

export const CoursesDashboardCards = async (args) => {
  let response;
  await (axios.post(PathNames.CoursesCards, {
    campaingId: args.campaingId,
    id_user: args.id_user,
  },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}

export const TableCourses = async (args) => {
  let response;
  await (axios.post(PathNames.TableCourses, {
    campaingId: args.campaingId,
    id_user: args.id_user,
  },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));
  return response;
}



export const CourseList = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.CourseList, { campaingId: args.campaingId, selected_customerId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));

  const todos = args.todos || false;
  if (todos) {
    return {
      ...response, data: [{
        "value": "",
        "label": "Todos"
      }, ...response.data]
    }
  }
  else {
    return response;
  }
}

export const CardsInfoByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.CardsInfoByCourse, { courseId: args.courseId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const DiasFinalizacionByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.DiasFinalizacionByCourse,
    { courseId: args.courseId }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const HorasFinalizacionByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.HorasFinalizacionByCourse,
    { courseId: args.courseId }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const StudientsByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.StudientsByCourse,
    { courseId: args.courseId }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const DataChatBotSimulationByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.DataChatBotSimulationByCourse,
    {
      courseId: args.courseId,
      id_user: args.id_user,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const OpenAndCloseQuestions = async (args) => {
  let response;
  await (axios.post(PathNames.OpenAndCloseQuestions,
    {
      courseId: args.courseId,
      type: args.type,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const StudentsDashboardCards = async (args) => {
  let response;
  await (axios.post(PathNames.StudentsDashboardCards, {
    campaingId: args.campaingId,
    id_user: args.id_user,
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const StudentsDashboardTable = async (args) => {
  let response;
  await (axios.post(PathNames.StudentsDashboardTable, {
    campaingId: args.campaingId,
    id_user: args.id_user,
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const CampaignsList = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.CampaignsList,
    { selected_customerId, },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const GeneralDashboardCards = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.GeneralDashboardCards, {
    periodId: args.periodId,
    selected_customerId,
    canalId: (args.channel) ? args.channel : ''
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const StudentActivityGraph = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.StudentActivityGraph, {
    periodId: args.periodId,
    canalId: args.channel,
    selected_customerId
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}


export const TimeGeneralDashboard = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.TimeGeneralDashboard, {
    periodId: args.periodId,
    canalId: args.channel,
    selected_customerId
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const ActiveStudentsDashboardTable = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.ActiveStudentsDashboardTable, {
    periodId: args.periodId,
    selected_customerId,
    canalId: args.channel
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const CursosChartGeneralDashboard = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.CursosChartGeneralDashboard, {
    periodId: args.periodId,
    selected_customerId,
    canalId: args.channel
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const DownloadDataGeneralTab = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.DownloadDataGeneralTab, {
    campañaId: args.campañaId,
    cursosId: args.cursosId.map(c => c.value).join(),
    fechaInicio: args.fechaInicio,
    fechaFin: args.fechaFin,
    horaInicio: args.horaInicio,
    horaFin: args.horaFin,
    selected_customerId
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const RequestReportDataGeneralTab = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.ReportsGenerate, {
    campañaId: args.campañaId,
    cursosId: args.cursosId.map(c => c.value).join(),
    fechaInicio: args.fechaInicio,
    fechaFin: args.fechaFin,
    horaInicio: args.horaInicio,
    horaFin: args.horaFin,
    selected_customerId,
    name: args.reportName,
    origin: 'dashboard'
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));

  return response;
}

export const CompanyList = async (args) => {
  let response;
  await (axios.post(PathNames.CompanyList, { id_user: args.id_user },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const NotificationListByCompany = async (args) => {
  let response;
  await (axios.post(PathNames.NotificationListByCompany, { companyId: args.companyId, id_user: args.id_user },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TableNotifications = async (args) => {
  let response;
  await (axios.post(PathNames.TableNotifications, { campaingId: args.campaingId, id_user: args.id_user, typeView: args.typeView },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const UserStateList = async (args) => {
  let response;
  await (axios.post(PathNames.UserStateList, { campaingId: args.campaingId, id_user: args.id_user },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const NotificationList = async (args) => {
  let response;
  await (axios.post(PathNames.NotificationList, { statusId: args.statusUser, campaingId: args.campaingId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const GupshopTemplateList = async (args) => {
  let response;
  await (axios.post(PathNames.GupshopTemplateList, { id_user: args.id_user, campaingId: args.campaingId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const ValidarFileUpload = async (args) => {
  let response;

  const formData = new FormData()
  formData.append('file', args.fileMediaUpload, args.fileMediaUpload.name)

  await (axios.post(PathNames.ValidarFileUpload, formData,
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const CreateNotification = async (args) => {
  let response;
  await (axios.post(PathNames.CreateNotification, args,
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const LastNotificationsDashboard = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.LastNotificationsDashboard, {
    periodId: args.periodId,
    selected_customerId,
    canalId: args.channel
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TemplateCategoryList = async (args) => {
  let response;
  await (axios.post(PathNames.TemplateCategoryList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TemplateTypeList = async (args) => {
  let response;
  await (axios.post(PathNames.TemplateTypeList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));

  const respF = {
    ...response, data: [{ "value": '0', "label": "SIN CABECERA", }, ...response.data]
  };
  return respF;
}

export const TemplateLanguajeList = async (args) => {
  let response;
  await (axios.post(PathNames.TemplateLanguajeList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TipoEnvioList = async (args) => {
  let response;
  await (axios.post(PathNames.TipoEnvioList, {},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const NumerosVariablesList = async (args) => {
  let response;
  response = { "message": "", "success": true, "data": [{ "value": 1, "label": "1", }, { "value": 2, "label": "2", }, { "value": 3, "label": "3", }], "errorId": null }
  const respF = {
    ...response, data: [{ "value": 0, "label": "Sin seleccionar", }, ...response.data]
  };
  return respF;
}

export const NombresVariablesList = async (args) => {
  let response;
  response = { "message": "", "success": true, "data": [{ "value": 'username', "label": "Nombre", }, { "value": 'phone', "label": "Teléfono", }], "errorId": null }
  const respF = {
    ...response, data: [{ "value": 0, "label": "Sin seleccionar", }, ...response.data]
  };
  return respF;
}

export const TypeCTAList = async (args) => {
  return { "message": "", "success": true, "data": [{ "value": 'URL', "label": "url", }, { "value": 'PHONE', "label": "phone number", }], "errorId": null };
}

export const TableTemplates = async (args) => {
  let response;
  await (axios.post(PathNames.TableTemplates, { campaingId: args.campaingId, id_user: args.id_user },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const RemoveTemplate = async (args) => {
  let response;
  await (axios.post(PathNames.RemoveTemplate, { appId: args.appId, elementName: args.elementName },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const CreateTemplate = async (args) => {
  let response;
  await (axios.post(PathNames.CreateTemplate, args,
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TablePermissionsManagment = async (args) => {
  let response;
  await (axios.post(PathNames.TablePermissionsManagment, { selected_customerId: args.selected_customerId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const TablePermissions = async (args) => {
  let response;
  await (axios.post(PathNames.TablePermissions, { id_user: args.id_user, campaingId: args.campaingId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}


export const ChangePermission = async (args) => {
  let response;
  await (axios.post(PathNames.ChangePermission, { id_user: args.id, action: args.action, fieldValue: args.fieldValue, campaingId: args.campaingId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const AddPermission = async (args) => {
  let response;
  await (axios.post(PathNames.AddPermission, { id_user: args.id_user, campaingId: args.campaingId, type: args.type, fieldValue: args.fieldValue },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const ProfileStudent = async (args) => {
  let response;
  await (axios.post(PathNames.ProfileStudent, { id_student: args.idStudent, campaingId: args.campaingId },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const UserBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.UserBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros, group: args.group,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));

  const dataDates = response.data?.data?.map(el => ({ ...el, date: Date.parse(el.date) }));
  return { ...response, data: { ...response.data, graph: dataDates?.sort((date1, date2) => date1 - date2) } }
}

export const InteractionsBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.InteractionsBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros, group: args.group,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));

  const dataDates = response.data?.data?.map(el => ({ ...el, date: Date.parse(el.date) }));
  return { ...response, data: { ...response.data, graph: dataDates?.sort((date1, date2) => date1 - date2) } }
}

export const TopUsersBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.TopUsersBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const ActiveCampaingBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.ActiveCampaingBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const ActiveCourseTableBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.ActiveCourseTableBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const DashboardCardsBySuperAdmin = async (args) => {
  let response;
  await (axios.post(PathNames.DashboardCardsBySuperAdmin,
    {
      canalId: args.channel, id_user: args.id_user, ...args.filtros,
    }, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));
  return response;
}

export const ClientList = async (args) => {
  let response;
  await (axios.post(PathNames.ClientList,
    { id_user: args.id_user },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  if (args.todos)
    return {
      ...response, data: [{
        "value": "",
        "label": "Todos"
      }, ...response.data]
    };
  else return response;
}

export const CampaignsListByClient = async (args) => {
  let response;
  if (args.client_id !== "") {
    await (axios.post(PathNames.CampaignsListByClient,
      { id_user: args.id_user, customerId: args.client_id },
      {
        headers: { 'Authorization': `Bearer ${args.token}` },
      }
    ).then(res => response = res.data));
    return response;
  }
  else {
    return {
      message: "OK",
      success: true,
      data: [{
        value: "",
        label: "Todos"
      }]
    };
  }
}

export const CourseListWithAll = async (args) => {
  let response;
  if (args.campaingId !== "") {
    await (axios.post(PathNames.SCourseList,
      { id_user: args.id_user, campaingId: args.campaingId },
      { headers: { 'Authorization': `Bearer ${args.token}` } }
    ).then(res => response = res.data));
    return {
      ...response, data: [{
        "value": "",
        "label": "Todos"
      }, ...response.data]
    };
  }
  else {
    return {
      message: "OK",
      success: true,
      data: [{
        value: "",
        label: "Todos"
      }]
    };
  }
}

export const UserListForTracking = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.UserListForTracking,
    {
      canalId: args.channel, phone: args.phone, typeView: args.typeView, ...args.filtros, selected_customerId
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const CampaingListForTracking = async (args) => {
  const selected_customerId = localStorage.getItem('selected_customerId')
  let response;
  await (axios.post(PathNames.CampaingListForTracking,
    {
      id_user: args.id_user,
      typeView: args.typeView,
      selected_customerId,
      canalId: args.channel
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const CourseListForTracking = async (args) => {
  let response;
  await (axios.post(PathNames.CourseListForTracking,
    {
      id_user: args.id_user,
      id_campaing: args.id_campaing,
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const InteractionsByCourse = async (args) => {
  let response;
  await (axios.post(PathNames.InteractionsByCourse,
    {
      id_user: args.id_user,
      id_course: args.id_course,
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const InteractionsByCampaing = async (args) => {
  let response;
  await (axios.post(PathNames.InteractionsByCampaing,
    {
      id_user: args.id_user,
      id_campaing: args.id_campaing,
    },
    {
      headers: { 'Authorization': `Bearer ${args.token}` },
    }
  ).then(res => response = res.data));

  return response;
}

export const DauMau = async (args) => {
  let response;
  // await (axios.post(PathNames.DauMau,
  //   {
  //     id_user: args.id_user,
  //   }, {
  //   headers: { 'Authorization': `Bearer ${args.token}` },
  // }
  // ).then(res => response = res.data));

  response = {
    "message": "OK",
    "success": true,
    "data": [
      {
        "date": "01 May 2023",
        "dau": 50,
        "mau": 20
      },
      {
        "date": "01 Jun 2023",
        "dau": 50,
        "mau": 20
      },
      {
        "date": "01 Jul 2023",
        "dau": 50,
        "mau": 20
      },
      {
        "date": "01 Aug 2023",
        "dau": 50,
        "mau": 20
      },
      {
        "date": "01 Sep 2023",
        "dau": 60,
        "mau": 28
      },
      {
        "date": "01 Oct 2023",
        "dau": 55,
        "mau": 35
      }
    ]
  }

  const dataDates = response.data?.map(el => ({ ...el, date: Date.parse(el.date) }));
  return { ...response, data: dataDates?.sort((date1, date2) => date1 - date2) }
}

export const DauMauRatio = async (args) => {
  let response;
  // await (axios.post(PathNames.DauMauRatio,
  //   {
  //     id_user: args.id_user,
  //   }, {
  //   headers: { 'Authorization': `Bearer ${args.token}` },
  // }
  // ).then(res => response = res.data));

  response = {
    "message": "OK",
    "success": true,
    "data": [
      {
        "date": "16 Aug 2023",
        "value": 370
      },
      {
        "date": "17 Aug 2023",
        "value": 355
      },
      {
        "date": "18 Aug 2023",
        "value": 1617
      },
      {
        "date": "19 Aug 2023",
        "value": 333
      },
      {
        "date": "20 Aug 2023",
        "value": 108
      },
      {
        "date": "21 Aug 2023",
        "value": 753
      },
      {
        "date": "22 Aug 2023",
        "value": 366
      }
    ]
  }

  const dataDates = response.data?.map(el => ({ ...el, date: Date.parse(el.date) }));
  return { ...response, data: dataDates?.sort((date1, date2) => date1 - date2) }
}

export const ReportsList = async (args) => {
  let response;
  await (axios.post(PathNames.ReportsList,
    {}, {
    headers: { 'Authorization': `Bearer ${args.token}` },
  }
  ).then(res => response = res.data));

  return response;
}

export const DownloadSuperDataGeneralTab = async (args) => {
  let response;
  await (axios.post(PathNames.ReportsGenerate, {
    id_user: args.id_user, ...args.filtros,
  },
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}

export const RemoveNotification = async (args) => {
  try {
    const response = await axios.post(
      PathNames.RemoveNotification,
      {
        "notificationId": args.notificationId
      },
      {
        headers: { 'Authorization': `Bearer ${args.token}` }
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const DownloadRecients = async (args) => {
  try {
    const response = await axios.post(
      PathNames.DownloadRecients,
      {
        "notificationId": args.notificationId
      },
      {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${args.token}`,
        }
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export const SendCode = async (args) => {
  try {
    const response = await axios.post(
      PathNames.SendCode,
      {
        'id_user': args.id_user,
        'method': args.method
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const ValidateCode = async (args) => {
  try {
    const response = await axios.post(
      PathNames.ValidateCode,
      {
        'code': args.code
      }
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const GetChannelList = async (args) => {
  let response;
  const params = {
    ...(args.customerId && { customerId: args.customerId }),
    ...(args.campaingId && { campaingId: args.campaingId }),
  };

  await (axios.get(PathNames.ChannelList, {params},
    { headers: { 'Authorization': `Bearer ${args.token}` } }
  ).then(res => response = res.data));
  return response;
}
