import { useEffect, useRef, useState } from "react"
import { ValidateCode as ValidateCodeRequest } from "app/api/Api"
import { useAuth } from "../core/Auth"
import { getUserByToken } from "../core/_requests"

const numberOfDigits = 6

export function ValidateCode({contactData}) {

  const [digits, setDigits] = useState(new Array(numberOfDigits).fill(""))
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [disabledFields, setDisabledFields] = useState(false)
  const digitsReference = useRef([])
  const { saveAuth, setCurrentUser } = useAuth()

  const handleFieldChange = (value, index) => {
    let newDigitsArray = [...digits]
    newDigitsArray[index] = value
    setDigits(newDigitsArray)

    if (value && index < numberOfDigits - 1) {
      digitsReference.current[index + 1].focus()
    }
  }

  const handleFieldKeyUp = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      digitsReference.current[index - 1].focus()
    }

    if ((e.key === 'Enter' || e.code === 'Space') && e.target.value && index < numberOfDigits - 1) {
      digitsReference.current[index + 1].focus()
    }
  }

  const handlePaste = (e, _) => {
    let clipboardCode = e.clipboardData.getData('text')
    if(!clipboardCode) return

    let splitedCode = clipboardCode.split('')
    if(splitedCode.length === 6) {
      setDigits(splitedCode)
    }
  }

  const validateCode = async () => {
    setLoading(true)
    setDisabledFields(true)
    setErrorMessage()
    const response = await ValidateCodeRequest({
      code: digits.join('')
    })
    if (response.success) {
      saveAuth(response.data)
      const { data: user } = await getUserByToken(response.data?.api_token)
      localStorage.setItem('selected_customerId', response.data.customers[0].value)
      localStorage.setItem('auxToken', -1)
      localStorage.removeItem('temp_user')
      setCurrentUser(user)
    } else {
      setErrorMessage(response.message)
      setDisabledFields(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (digits.every(digit => digit)) {
      validateCode()
    }
  }, [digits])

  return (
    <form>
      <div className='text-center mb-10'>
        <div>
        <h1 className='text-dark mb-3'>Ingrese el código de verificación</h1>
        <span>El codigo se ha enviado a {contactData}</span>
        </div>
        <div className="d-flex gap-5">
          {digits.map((digit, index) => (
            <div>
              <input
                key={index}
                value={digit}
                maxLength={1}
                pattern="[0-9]"
                ref={(reference) => (digitsReference.current[index] = reference)}
                onChange={(e) => handleFieldChange(e.target.value, index)}
                onKeyUp={(e) => handleFieldKeyUp(e, index)}
                onPaste={(e) => handlePaste(e, index)}
                className="col-1 form-control"
                disabled={disabledFields}
              />
            </div>
          ))}
        </div>
        {errorMessage &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errorMessage}</span>
            </div>
          </div>
        }
        {loading &&
          <span className='indicator-progress' style={{ display: 'block' }}>
            Cargando...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        }
      </div>
    </form>
  )
}