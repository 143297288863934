import {useEffect} from 'react'
import $ from 'jquery'

const MaintenancePage = () => {
  useEffect(() => {
    $('#kt_content').append($('#formularioMantenimiento'))
    $('#formularioMantenimiento').css('display', 'block')

    return () => $('#formularioMantenimiento').css('display', 'none')
  }, [])

  return (
    <>
      <h3 className='card-title align-items-start flex-column pb-5'>
        <span className='fw-bold fs-2x mb-3'>Soporte</span>
      </h3>

      <div style={{justifyContent: 'space-between', display: 'flex'}}>
        <h5 style={{paddingTop: '0.5rem'}}>Aqui podras crear un ticket de soporte</h5>
        <button
          onClick={() => window.location.reload()}
          style={{marginLeft: '3rem'}}
          className='btn btn-sm fw-bold btn-primary'
        >
          <i className='fa fa-refresh' aria-hidden='true'></i>Reiniciar
        </button>
      </div>

      <div className=''>{/* <ScriptTag isHydrating={true} type='text/javascript' src='/support.js' /> */}</div>
    </>
  )
}

export {MaintenancePage}
