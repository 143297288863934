/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import {AsideToolbar} from './AsideToolbar'
import { useNavigate } from 'react-router-dom'

const AsideDefault: FC = () => {
  const navigate = useNavigate();
  const {classes} = useLayout()

  return (
    <div
      id='kt_aside'
      className='aside'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Aside Toolbarl */}
      <div className='aside-toolbar flex-column-auto' id='kt_aside_toolbar'>
        <AsideToolbar />
      </div>
      {/* end::Aside Toolbarl */}
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid overflow-scroll'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto py-5' id='kt_aside_footer'>
        <button
          className='btn btn-primary w-100'
          onClick={()=> {
            navigate('/apps/maintenance-management/maintenance')
            // window.location.reload();
          }
          }
          // href='/apps/maintenance-management/maintenance'
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Check out the complete documentation with over 100 components'
        >
           <span className='btn-icon'>
          <i className="fa-duotone fa-headset"></i>
          </span>
          <span className='btn-label'>Crear Ticket de Soporte</span>
         
        </button>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
