import React from 'react'
import * as Excel from 'exceljs/dist/exceljs.min.js';



const downloadFile = (workbook, fileNameDownload) => {
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileNameDownload + ".xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}

const exportExcelFIle = (tabs, columns, fileNameDownload, data) => {

  const workbook = new Excel.Workbook();

  tabs.forEach(tab => {
    const sheet = workbook.addWorksheet(tab.name);

    tab.columnsList?.forEach(key => {
      sheet.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: '96C8FB' },
        bgColor: { argb: '96C8FB' }
      }
      sheet.getCell(key).width = 400
    }
    );

    sheet.columns = tab.columns;

    const promise = Promise.all(
      tab.data?.map(async (row) => sheet.addRow(row))
    );

    promise.then(() => { });
  });
  downloadFile(workbook, fileNameDownload);
};

export default function DownloadExcelComponent({ tabs, columns, fileNameDownload, data, columnsList, titleButton = null }) {
  return (
    <>
      <button type='button' className='btn btn-sm fw-bold btn-primary' onClick={() => exportExcelFIle(tabs, columns, fileNameDownload, data, columnsList)}>
        <span className='fw-bold '>
          <i className="fa-duotone fa-down-to-bracket"></i>
          {titleButton ? titleButton : 'Descargar'}
        </span>
      </button>

    </>
  )
}
