import {Table} from '../../../components/table/Table'
import Filter from '../../../components/filter/Filter'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const UserListComponent = ({
  loadingUserList,
  userListData,
  setShow,
  columnsUserList,
  filter,
  setFilter,
}) => {
  return (
    <>
      {!loadingUserList ? (
        <>
          {userListData ? (
            <div className='card card-flush'>
              <Table
                className='table-0-padding'
                title={
                  <div>
                    <span
                      style={{marginRight: 10}}
                      id={`message-userList`}
                      data-tooltip-content='Interacciones de los últimos 7 días, puede buscar un número directamente'
                    >
                      <i className='fa-light fa-circle-info'></i>
                      <ReactTooltip
                        style={{zIndex: 10}}
                        place='right'
                        anchorId={`message-userList`}
                      />
                    </span>
                    <span className='fs-6 fw-bold text-gray-900 me-1 mb-2 lh-1'>Tracking</span>
                  </div>
                }
                toolbar={
                  <div className='d-flex align-items-center gap-2 gap-lg-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary btn-secondary course-button'
                      onClick={() => setShow(true)}
                    >
                      <span className='fw-bold '>
                        <i className='fa-duotone fa-filter-list'></i>
                      </span>
                    </button>
                  </div>
                }
                data={userListData}
                columns={columnsUserList}
                hidePagination={false}
                pgSize={5}
                hideFilter={true}
                customFilter={
                  <Filter
                    searchTypeInput={'number'}
                    autoFocus={true}
                    filter={filter}
                    setFilter={setFilter}
                  ></Filter>
                }
              />
            </div>
          ) : (
            <h4> No hay datos</h4>
          )}
        </>
      ) : (
        <div className='card'>
          <div className='card-body'>
            <div className='flex-column loadin-new'>
              <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
              <span className='text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {UserListComponent}
