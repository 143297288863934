import {useState, useEffect, useMemo} from 'react'
import * as Api from 'app/api/Api'
import {Table} from '../../components/table/Table'

const ReportsPage = () => {
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const [tableData, setTableData] = useState([])
  const [run, setRun] = useState(true)

  useEffect(() => {
    if (run === true) {
      let tableDataResponse
      ;(async () => {
        tableDataResponse = await Api.ReportsList({
          token: user.api_token,
        })
        setTableData(tableDataResponse.data || [])
        setRun(false)
      })()
      setTimeout(() => {
        if (tableDataResponse?.data?.find((row) => row?.status !== 'A') !== undefined) setRun(true)
      }, 5000)
    }
  }, [user.api_token, run])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        show: false,
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        className: 'min-w-100px',
        Cell: ({cell}) => <span>{cell.row.values.name}</span>,
      },
      {
        Header: 'Fecha',
        accessor: 'date',
        className: 'min-w-100px',
      },
      {
        Header: 'Origen',
        accessor: 'origin',
        className: 'min-w-100px',
      },
      {
        Header: 'Estado',
        accessor: 'status',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <span
            style={{
              color:
                cell.row.values.status === 'A'
                  ? '#1fcb1f'
                  : cell.row.values.status === 'P'
                  ? '#b9b913'
                  : 'red',
            }}
          >
            {cell.row.values.status === 'A'
              ? 'Disponible'
              : cell.row.values.status === 'P'
              ? 'Pendiente'
              : 'Fallido'}
          </span>
        ),
      },
      {
        Header: '',
        accessor: 'link',
        className: 'text-center min-w-50px',
        Cell: ({cell}) => (
          <>
            {cell.row.values.status === 'A' ? (
              <a
                target='_blank'
                href={cell.row.values.link}
                download={cell.row.values.name}
                rel='noreferrer'
              >
                <button type='button' className='btn btn-sm fw-bold btn-primary'>
                <i className='fa-duotone fa-down-to-line'></i>Descargar
                </button>
              </a>
            ) : (
              <span className='badge badge-light-danger'>No disponible</span>
            )}
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      <h3 className='card-title align-items-start flex-column pb-5'>
        <span className='fw-bold fs-2x mb-3'>Reportes</span>
      </h3>

      <Table className='' data={tableData}   title='Reporte General' columns={columns} />
    </>
  )
}

export {ReportsPage}
