import {NumerosVariablesList, NombresVariablesList} from '../../../../api/Api'
import {useState, useEffect} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const ParamsSelect = ({setParamSelect}) => {
  const [numerosVariablesListService, setNumerosVariablesListService] = useState([])
  const [nombresVariablesListService, setNombresVariablesListService] = useState([])
  const animatedComponents = makeAnimated()
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const [numeroVariables1, setNumeroVariables1] = useState(null)
  const [numeroVariables2, setNumeroVariables2] = useState(null)
  // const [numeroVariables3, setNumeroVariables3] = useState(null)

  const [nombreVariables1, setNombreVariables1] = useState(null)
  const [nombreVariables2, setNombreVariables2] = useState({value: 0, label: 'Sin seleccionar'})
  // const [nombreVariables3, setNombreVariables3] = useState(null)

  useEffect(() => {
    ;(async () => {
      const dataResponse = await NumerosVariablesList({
        token: user.api_token,
      })
      setNumerosVariablesListService(dataResponse?.data || [])
    })()
    ;(async () => {
      const dataResponse = await NombresVariablesList({
        token: user.api_token,
      })
      setNombresVariablesListService(dataResponse?.data || [])
    })()
  }, [user.api_token])

  const onChangeByNumeroVariables1 = (selected) => {
    let na = [...numerosVariablesListService]
    if (numeroVariables1 !== null && numeroVariables1?.value !== 0) na = [...na, numeroVariables1]
    setNumerosVariablesListService(
      na
        .filter(function (el) {
          if (selected.value === 0) return true
          return el.value !== selected.value
        })
        .sort((a, b) => a.value > b.value)
    )
    setNumeroVariables1(selected)
  }
  const onChangeByNumeroVariables2 = (selected) => {
    let na = [...numerosVariablesListService]
    if (numeroVariables2 !== null && numeroVariables2?.value !== 0) na = [...na, numeroVariables2]
    setNumerosVariablesListService(
      na
        .filter(function (el) {
          if (selected.value === 0) return true
          return el.value !== selected.value
        })
        .sort((a, b) => a.value > b.value)
    )
    setNumeroVariables2(selected)
  }
  // const onChangeByNumeroVariables3 = (selected) => {
  //   let na = [...numerosVariablesListService]
  //   if (numeroVariables3 !== null && numeroVariables3?.value !== 0) na = [...na, numeroVariables3]
  //   setNumerosVariablesListService(
  //     na
  //       .filter(function (el) {
  //         if (selected.value === 0) return true
  //         return el.value !== selected.value
  //       })
  //       .sort((a, b) => a.value > b.value)
  //   )
  //   setNumeroVariables3(selected)
  // }

  const onChangeByNombreVariables1 = (selected) => {
    let na = [...nombresVariablesListService]
    if (nombreVariables1 !== null && nombreVariables1?.value !== 0) na = [...na, nombreVariables1]
    setNombresVariablesListService(
      na
        .filter(function (el) {
          if (selected.value === 0) return true
          return el.value !== selected.value
        })
        .sort((a, b) => a.value > b.value)
    )
    setNombreVariables1(selected)
  }
  const onChangeByNombreVariables2 = (selected) => {
    let na = [...nombresVariablesListService]
    if (nombreVariables2 !== null && nombreVariables2?.value !== 0) na = [...na, nombreVariables2]
    setNombresVariablesListService(
      na
        .filter(function (el) {
          if (selected.value === 0) return true
          return el.value !== selected.value
        })
        .sort((a, b) => a.value > b.value)
    )
    setNombreVariables2(selected)
  }
  // const onChangeByNombreVariables3 = (selected) => {
  //   let na = [...nombresVariablesListService]
  //   if (nombreVariables3 !== null && nombreVariables3?.value !== 0) na = [...na, nombreVariables3]
  //   setNombresVariablesListService(
  //     na
  //       .filter(function (el) {
  //         if (selected.value === 0) return true
  //         return el.value !== selected.value
  //       })
  //       .sort((a, b) => a.value > b.value)
  //   )
  //   setNombreVariables3(selected)
  // }

  useEffect(() => {
    if (nombreVariables1?.value === 0)
      onChangeByNombreVariables2({value: 0, label: 'Sin seleccionar'})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nombreVariables1])

  useEffect(() => {
    const onSetParamSelect = () => {
      let paramsFinal = {}
      if (nombreVariables1?.value !== undefined && nombreVariables1?.value !== 0)
        paramsFinal.param1 = nombreVariables1?.value

      if (nombreVariables2?.value !== undefined && nombreVariables2?.value !== 0)
        paramsFinal.param2 = nombreVariables2?.value
      else paramsFinal.param2 = ''

      // if (
      //   numeroVariables3?.value !== undefined &&
      //   numeroVariables3?.value !== 0 &&
      //   nombreVariables3?.value !== undefined &&
      //   nombreVariables3?.value !== 0
      // )
      //   paramsFinal = [
      //     ...paramsFinal,
      //     { numero: numeroVariables3?.value, nombre: nombreVariables3?.value },
      //   ]
      setParamSelect(paramsFinal)
    }

    onSetParamSelect()
  }, [numeroVariables1, numeroVariables2, nombreVariables1, nombreVariables2, setParamSelect])

  return (
    <div className='row'>
      <div className='col-6'>
        {numerosVariablesListService && nombresVariablesListService && (
          <>
            <div className='row mt-4'>
              <div className='col-4'>
                <span className='fs-6 text-gray-800 fw-semibold '>Número de variable</span>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={numerosVariablesListService}
                  defaultValue={{value: 1, label: '1'}}
                  onChange={onChangeByNumeroVariables1}
                  isDisabled
                />
              </div>

              <div className='col-4'>
                <span className='fs-6 text-gray-800 fw-semibold '>Nombre de variable</span>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={nombresVariablesListService}
                  defaultValue={{value: 0, label: 'Sin seleccionar'}}
                  onChange={onChangeByNombreVariables1}
                />
              </div>

              {/* <div className='col-4'>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={numerosVariablesListService}
                  defaultValue={{value: 0, label: 'Sin seleccionar'}}
                  onChange={onChangeByNumeroVariables3}
                />
              </div> */}
            </div>
          </>
        )}
      </div>

      <div className='col-6'>
        {numerosVariablesListService && nombresVariablesListService && (
          <>
            <div className='row mt-4'>
              <div className='col-4'>
                <span className='fs-6 text-gray-800 fw-semibold '>Número de variable</span>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={numerosVariablesListService}
                  defaultValue={{value: 1, label: '2'}}
                  onChange={onChangeByNumeroVariables2}
                  isDisabled
                />
              </div>

              <div className='col-4'>
                <span className='fs-6 text-gray-800 fw-semibold '>Nombre de variable</span>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={nombresVariablesListService}
                  defaultValue={{value: 0, label: 'Sin seleccionar'}}
                  onChange={onChangeByNombreVariables2}
                  value={nombreVariables2}
                  isDisabled={nombreVariables1?.value === 0 || nombreVariables1 === null}
                />
              </div>
              {/* <div className='col-4'>
                <Select
                  classNamePrefix='filter'
                  components={animatedComponents}
                  options={nombresVariablesListService}
                  defaultValue={{value: 0, label: 'Sin seleccionar'}}
                  onChange={onChangeByNombreVariables3}
                />
              </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {ParamsSelect}
