import { useEffect, useState, useMemo } from 'react'
import { CoursesDashboardCards, TableCourses, CampaignsList } from '../../../api/Api'
import { Link } from 'react-router-dom'
import { InfoCard } from 'app/components/infoCard/infoCard'
import { Table } from '../../../components/table/Table'
import { SelectList } from '../../../components/selectList/SelectList'

const CoursesPage = ({ selected_customerIdSaved }) => {
  const [cardData, setCardData] = useState(null)
  const [tableData, setTableData] = useState([])
  const [campaing, setCampaing] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refrestCampaingList, setRefrestCampaingList] = useState(true)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  useEffect(() => {
    setRefrestCampaingList(true)
    if (campaing?.value) {
      ; (async () => {
        const cardDataResponse = await CoursesDashboardCards({
          token: user.api_token,
          campaingId: campaing.value,
          id_user: userId,
        })
        setCardData(cardDataResponse.data)
      })()
        ; (async () => {
          setLoading(true)
          const tableDataResponse = await TableCourses({
            token: user.api_token,
            campaingId: campaing.value,
            id_user: userId,
          })
          setTableData(tableDataResponse.data.table)
          setLoading(false)
        })()
    }
  }, [campaing, user.api_token, userId, selected_customerIdSaved])

  // useEffect(() => {
    
  // }, [selected_customerIdSaved])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        show: false,
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      {
        Header: 'Inscritos',
        accessor: 'inscribed',
        className: 'text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-duotone fa-user-group-simple'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.inscribed}
          </span>

        ),
      },
      {
        Header: 'Iniciaron',
        accessor: 'started_course',
        className: 'text-center',
      },
      {
        Header: 'Completados',
        accessor: 'completed',
        className: 'text-center',
      },
      {
        Header: '% Progreso',
        accessor: 'progress_percent',
        className: 'text-center text-gray-800 fw-bolder',
        Cell: ({ cell }) => <span>{cell.row.values.progress_percent}%</span>,
      },
      {
        Header: 'Detalles',
        accessor: 'actionver',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (
          <Link to={`/detailCourse/${cell.row.values.id}`}>
            {' '}
            <button type='button' className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'>
              <i className="fa-duotone fa-eye"></i>
            </button>
          </Link>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='app-container d-flex flex-stack'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            Todos los Cursos
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-0'>
          <SelectList
            title={'Campaña'}
            apiService={CampaignsList}
            args={{ token: user.api_token }}
            conditions={refrestCampaingList}
            setRefresh={setRefrestCampaingList}
            handleChange={(company) => setCampaing(company)}
            value={campaing}
            multiple={false}
            defaultSetterFunction={setCampaing}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}
          />
        </div>
      </div>

      {cardData && (
        <>
          <div className=' fs-6 text-gray-400 fw-bold ms-1'>
            Tienes {cardData.coursesCount} cursos en total
          </div>
          <div className='row g-5 g-xl-8 mb-5 mt-1'>
            <div className='col-xl-4'>
              <InfoCard
                value={`${cardData.finalizacion.value}%`}

                title='Finalizacion'
                subtitle='Promedio de Estudiantes'
                link='#'
                classes={{
                  cardClasses: 'card bg-body-white',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-4'>
              <InfoCard
                value={cardData.satisfaccion.value}
                title='Satisfacción'
                subtitle='de Estudiantes'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-4'>
              <InfoCard
                value={`${cardData.notaPromedio.value}%`}
                subtitle='de Estudiantes'
                title='Enganche'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  subtitleClasses: 'text-gray-500 mt-1 fw-semibold fs-6',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <Table
            className=''
            data={tableData}
            columns={columns}
            title='Cursos'
            isLoading={loading}
          />
        </div>
      </div>
    </>
  )
}

export { CoursesPage }
