import {KTSVG} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const {currentUser} = useAuth()
  const findPermission = (permissionUser) => {
    const hvePermission = currentUser.data?.role.map((role) =>
      role.permissions.some((permission) => permission.id === permissionUser)
    )
    return hvePermission === undefined ? false : hvePermission[0]
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-dark text-uppercase fs-8 ls-1'>Dashboard</span>
        </div>
      </div>
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/general'
          icon='fa-duotone fa-grid-horizontal'
          title={'General'}
        />
      )}
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/notificationes'
          icon='fa-duotone fa-envelope-open'
          title={'Notificationes'}
        />
      )}
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/campaing'
          icon='fa-duotone fa-message-bot'
          title={'Campañas'}
        />
      )}
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/cursos'
          icon='fa-duotone fa-chalkboard-user'
          title={'Cursos'}
        />
      )}
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/estudiantes'
          icon='fa-duotone fa-user-group-simple'
          title={'Estudiantes'}
        />
      )}
      {findPermission('DASHBOARD') && (
        <AsideMenuItem
          to='/tracking'
          icon='fa-duotone fa-message-captions'
          title={'Tracking'}
        />
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-dark text-uppercase fs-8 ls-1'>SUPER ADMIN</span>
        </div>
      </div>
      {findPermission('SUPERADMIN') && (
       <>
        <AsideMenuItem
          to='/monitoreoAdmin'
          icon='fa-duotone fa-gauge-max'
          title={'Monitoreo'}
        />
        <AsideMenuItem
          to='/trackingAdmin'
          icon='fa-duotone fa-message-captions'
          title={'Tracking'}
        />
       </>
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-dark text-uppercase fs-8 ls-1'>REPORTES</span>
        </div>
      </div>
      <AsideMenuItem
        to='/apps/reports-management/reports'
        icon='fa-duotone fa-files'
        title='Reportes'
      />

      {/* <AsideMenuItem
        to='/studients'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.STUDIENTS'})}
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-dark text-uppercase fs-8 ls-1'>Configuración</span>
        </div>
      </div>
      {findPermission('PERMISSIONS') && (
        <AsideMenuItem
          to='/apps/permission-management/permissions'
          icon='fa-duotone fa-shield-halved'
          title='Permisos'
        />
      )}
      {findPermission('USERMANAGEMENT') && (
        <AsideMenuItem
          to='/apps/user-management/users'
          icon='fa-duotone fa-user-shield'
          title='Usuarios'
        />
      )}
      {findPermission('ROLEMANAGEMENT') && (
        <AsideMenuItem
          to='/apps/roles-management/roles'
          icon='fa-duotone fa-user-lock'
          title='Roles'
        />
      )}
      {findPermission('NOTIFICATION') && (
        <AsideMenuItem
          to='/apps/notification-management/notification'
          icon='fa-duotone fa-bell-exclamation'
          title='Notificaciones'
        />
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a target='blank' className='menu-link' href='/#'>
          <span className='menu-icon'>
            <i className='fa-duotone fa-brackets-curly' />
          </span>
          <span className='menu-title'>Changelog 2.0 Musa</span>
        </a>
      </div>
    </>
  )
}
