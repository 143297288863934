import {formatDate, formatHour} from '../../../Utilities/Utils'

const InteractionListComponent = ({loadingInteractionList, chatData}) => {
  return (
    <>
      {!loadingInteractionList ? (
        <>
          {chatData != null && (
            <>
              {chatData?.length > 0 ? (
                <>
                  <div className='row g-5 g-xl-8 mb-10'>
                    <div className='col-xl-12'>
                      <div className='card' id='kt_chat_messenger'>
                        <div className='card-header' id='kt_chat_messenger_header'>
                          <div className='card-title'>
                            <div className='d-flex justify-content-center flex-column me-3'>
                              <span className='fs-4 fw-bold text-gray-900 me-1 lh-1'>
                                Historico del Curso
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className='card-body bg-whatsapp'
                          style={{
                            backgroundImage: `url("/media/bg-whatsapp.jpg")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }}
                        >
                          <div
                            className='scroll-y me-n5 pe-5 h-500px'
                            data-kt-scroll-activate='{default: false, lg: true}'
                          >
                            {chatData?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className='d-flex mb-5 rounded'
                                  style={{
                                    alignSelf: item.author === 'bot' ? 'flex-start' : 'flex-end',
                                    justifyContent:
                                      item.author === 'bot' ? 'flex-start' : 'flex-end',
                                  }}
                                >
                                  <div
                                    key={index}
                                    className={
                                      item.author === 'bot'
                                        ? 'bg-light-gris rounded'
                                        : 'bg-light-verde rounded'
                                    }
                                  >
                                    <div className='p-3'>
                                      {item.message}
                                      <div
                                        className='text-muted fs-7'
                                        id={'fileTooltipfile' + index}
                                      >
                                        <i className='fa-duotone fa-check-double me-3'></i>
                                        {formatDate(new Date(item.date)) +
                                          '  ' +
                                          formatHour(new Date(item.date))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <h4> No hay datos</h4>
              )}
            </>
          )}
        </>
      ) : (
        <div className='card'>
          <div className='card-body'>
            <div className='flex-column loadin-new'>
              <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
              <span className='text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {InteractionListComponent}
