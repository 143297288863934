import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const SelectList = ({
  title,
  apiService,
  args,
  conditions,
  handleChange,
  multiple,
  defaultSetterFunction,
  copyDefaultSetterFunction = null,
  defaultValue = true,
  indexDefaultValue = 0,
  handleCustomLabel = null,
  handleFocus,
  value,
  disabled = false,
  setListReady,
  setRefresh = null
}) => {
  let {data, isLoading} = useQuery([title, args], async () => await apiService(args), {
    enabled: conditions,
    refetchOnMount: true
  })

  const [dataOptions, setDataOptions] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    if (data) {
      if (!data.success) {
        setError(true)
      } else {
        setDataOptions(data.data)
        setError(false)
        setListReady && setListReady(true)
        if (!multiple) {
          if (data.data?.length > 0) {
            defaultSetterFunction(data.data[indexDefaultValue] || data.data[0])
            if (copyDefaultSetterFunction)
              copyDefaultSetterFunction(data.data[indexDefaultValue].label || data.data[0].label)
          }
        } else {
          let resultFinal = []
          Array.isArray(data.data[0])
            ? data.data.defaultId.forEach((value) => {
                let result = data.data.filter((obj) => obj.label === value)[0].label
                resultFinal.push(result)
              })
            : resultFinal.push(data.data[0])
          defaultSetterFunction(resultFinal)
          if (copyDefaultSetterFunction) copyDefaultSetterFunction(resultFinal.label)
        }
      }
      if(setRefresh) setRefresh(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultSetterFunction, multiple, title, conditions])

  let label = title ? title.charAt(0).toUpperCase() + title.slice(1) : ''

  const animatedComponents = makeAnimated()

  const getOptionValue = (idDefault) => {
    let resultFinal
    if (!multiple) return dataOptions.filter((obj) => obj.value === idDefault.value)
    else {
      resultFinal = []
      Array.isArray(idDefault)
        ? idDefault.forEach((value) => {
            let result = dataOptions.filter((obj) => obj.value === value)
            resultFinal.push(result[0])
          })
        : resultFinal.push(idDefault)

      return resultFinal
    }
  }

  if (isLoading) return <span>Cargando...</span>

  return (
    <>
      {data?.data && (
        <>
          {label && <div className='fw-bold fs-7 text-gray-600 mb-1'>{label}:</div>}
          <Select
            classNamePrefix='filter'
            components={animatedComponents}
            value={error ? null : value}
            defaultValue={
              defaultValue
                ? getOptionValue(
                  dataOptions.length > indexDefaultValue
                      ? dataOptions[indexDefaultValue]
                      : dataOptions[0]
                  )
                : dataOptions[0]
            }
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
              ...theme.colors,
              text: '#000',
              font:'#000',
              primary25: '#f1f1f4',
              primary: '#3615af',
              neutral80: '#000',
              color: '#000',
              },
            })}
            isMulti={multiple}
            closeMenuOnSelect={!multiple}
            options={dataOptions}
            onChange={handleChange}
            onFocus={handleFocus}
            isDisabled={disabled}
            getOptionLabel={handleCustomLabel ? handleCustomLabel : (option) => `${option.label}`}
          />
        </>
      )}
    </>
  )
}

export {SelectList}
