import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import * as Api from 'app/api/Api'
import {useState, useEffect, useMemo} from 'react'
import {Table} from '../../../components/table/Table'
import {Modal} from 'react-bootstrap'
import defaultProduct1 from '../../../../media/bicho6.svg'
import DownloadExcelComponent from '../../../components/download/downloadExcel'

const DetailPermissionsManager = () => {
  const campaingId = useParams().campaingId
  const validationField = useParams().validationField

  const [tableData, setTableData] = useState([])
  const [typeSelected, setTypeSelected] = useState('text')
  const [mobileNumbers, setMobileNumbers] = useState('')
  const [isMobileNumerHasentErrror, setIsMobileNumerHasentErrror] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const regexNumbers = /^[1-9]\d{10,12}$/

  const fileReader = new FileReader()
  const [hasErrorFile, setHasErrorFile] = useState(false)
  const [fileData, setFileData] = useState([])

  const [idChangeStatus, setIdChangeStatus] = useState(null)
  const [idNewStatus, setIdNewStatus] = useState(null)
  const [dataReport, setDataReport] = useState(null)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  useEffect(() => {
    if (idChangeStatus === null || idNewStatus === null) {
      ;(async () => {
        const tableDataResponse = await Api.TablePermissions({
          token: user.api_token,
          id_user: userId,
          campaingId: campaingId,
        })
        setTableData(tableDataResponse.data || [])

        setDataReport([
          {
            data:
              tableDataResponse.data?.map((el) => ({
                id: el.id,
                name: el.name,
                phone: el.phone,
                last_interaction: el.last_interaction,
                access: el.access ? 'SI' : 'NO',
              })) || [],
            columns: [
              {header: 'Id', key: 'id'},
              {header: 'Nombre', key: 'name', width: 20},
              {header: 'Telefono', key: 'phone', width: 20},
              {
                header: 'Ultima interaccion',
                key: 'last_interaction',
                width: 22,
                style: {numFmt: 'dd/mm/yyyy\\ h:mm:ss\\ AM/PM'},
              },
              {header: 'Accesos', key: 'access'},
            ],
            name: 'Permisos',
          },
        ])
      })()
    }
  }, [campaingId, user.api_token, userId, idChangeStatus, idNewStatus])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        className: 'min-w-100px ',
        show: false,
      },
      {
        Header: 'Canal',
        accessor: 'name',
        Cell: ({cell}) => (
       
          <span className='text-success fs-7 fw-bold'>

            <i
              className='text-success fa-regular fa-comments'
              style={{paddingRight: 5}}
            ></i>
{cell.row.values.name}
          </span>
       
   ), 
      },
      {
        Header: 'Canal',
        accessor: 'phone',
        className: 'min-w-100px',
        show: false,
      },
      {
        Header: 'Ult. interacción',
        accessor: 'last_interaction',
        className: 'min-w-100px text-center',
      },
      {
        Header: 'Acceos',
        accessor: 'access',
        className: 'min-w-100px text-center ',
        Cell: ({cell}) => (
          <>
            {cell.row.values.access ? (
              <i className='fa-regular fa-circle-check text-success'></i>
            ) : (
              <i className='text-danger fa-regular fa-circle-xmark'></i>
            )}
          </>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        className: 'min-w-100px text-center',
        Cell: ({cell}) => (
          <>
            {cell.row.values.phone === '' && (
              <>
                {cell.row.values.access ? (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold btn-danger'
                    onClick={() => {
                      setIdChangeStatus(cell.row.values.id)
                      ;(async () => {
                        await Api.ChangePermission({
                          token: user.api_token,
                          id: cell.row.values.id,
                          action: 'removeAccess',
                          fieldValue: cell.row.values.name,
                          campaingId: campaingId,
                        })
                        setIdChangeStatus(null)
                      })()
                    }}
                  >
                    <span className='fw-bold '>
                      {cell.row.values.id === idChangeStatus ? (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      ) : (
                       <i className="fa-sharp fa-solid fa-user-minus"></i>
                      )}
                    </span>
                  </button>
                ) : (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold btn-success'
                    onClick={() => {
                      setIdChangeStatus(cell.row.values.id)
                      ;(async () => {
                        await Api.ChangePermission({
                          token: user.api_token,
                          id: cell.row.values.id,
                          action: 'addAccess',
                          fieldValue: cell.row.values.name,
                          campaingId: campaingId,
                        })
                        setIdChangeStatus(null)
                      })()
                    }}
                  >
                    <span className='fw-bold '>
                      {cell.row.values.id === idChangeStatus ? (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      ) : (
                        <i className="fa-solid fa-user-plus"></i>
                      )}
                    </span>
                  </button>
                )}
              </>
            )}
          </>
        ),
      },
    ],
    [campaingId, idChangeStatus, user.api_token]
  )

  useEffect(() => {
    setIsMobileNumerHasentErrror(mobileNumbers !== '')
  }, [mobileNumbers, regexNumbers])

  const handleOnChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        setHasErrorFile(false)
        const csvOutput = event.target.result.split(/\r?\n|\r|\n/g)
        const isCorrect = !!csvOutput
        if (isCorrect) {
          setHasErrorFile(false)
          setFileData(csvOutput)
        } else {
          setHasErrorFile(true)
        }
      }
      fileReader.readAsText(file)
    }
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className=''>
          <Link
            to={`/apps/permission-management/permissions`}
            className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
          >
            <i className='fa-solid fa-arrow-left'></i>
            <span className='fw-bold '>Regresar</span>
          </Link>
        </div>
        <div>
          <button
            style={{marginLeft: 15}}
            type='button'
            className='btn btn-sm fw-bold btn-success'
            onClick={() => {
              setIdNewStatus(1)
              setShowModal(true)
            }}
          >
            <span className='fw-bold '><i className="fa-solid fa-user-plus"></i> Permiso</span>
          </button>
        </div>
      </div>

      <>
      <div className='app-container d-flex flex-stack mb-5'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            General
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-6'>
          {!dataReport ? (
            <button disabled type='button' className='btn btn-sm fw-bold btn-primary'>
              <span className='fw-bold '>
                <i className='fa-duotone fa-down-to-bracket'></i> No hay datos reportados.
              </span>
            </button>
          ) : (
            <DownloadExcelComponent tabs={dataReport} fileNameDownload='Reporte Musa Permisos' />
          )}
               </div>
               </div>
        {tableData && <Table className='' data={tableData}  title='Detalles permisos' columns={columns} />}

    
      </>

      <Modal
        className='modal fade '
        show={showModal}
        aria-hidden='true'
        centered
        onHide={() => setShowModal(false)}
      >
        <div className='modal-content '>
          <div className='modal-header'>
            <h5 className='modal-title'>Añadir permiso</h5>
          </div>
          <div className='modal-body '>
            <div className='row mb-4'>
              <div className='col-xl-12 '>
                <label className='required fw-bold fs-6 mb-2'>Validación por</label>
                <input
                  type='text'
                  value={validationField}
                  className={'form-control form-control-solid mb-3 mb-lg-0 mayus'}
                  disabled={true}
                />
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-xl-12 '>
                <div role='group' className='mb-2'>
                  <label className='required fw-bold fs-6 mb-2'>Permisos</label>
                  <div className='form-check form-check-custom form-check-sm'>
                    <input
                      type='radio'
                      name='type'
                      value={typeSelected === 'text'}
                      onChange={(e) => setTypeSelected('text')}
                      className='form-check-input'
                      defaultChecked={true}
                    />
                    <span>
                      <label style={{marginLeft: 6}} htmlFor='text'>
                        Texto
                      </label>
                    </span>

                    <input
                      type='radio'
                      className='form-check-input'
                      style={{marginLeft: 20}}
                      name='type'
                      value={typeSelected === 'file'}
                      onChange={(e) => setTypeSelected('file')}
                      defaultChecked={false}
                    />
                    <span>
                      <label style={{marginLeft: 6}} htmlFor='text'>
                        Archivo
                      </label>
                    </span>
                  </div>
                </div>
                <div>
                  {typeSelected === 'text' && (
                    <div className='row '>
                      <div className='col-xl-12 mb-5'>
                        <textarea
                          placeholder='Ingrese los numeros separados por (,)'
                          value={mobileNumbers}
                          type='textarea'
                          name='mobileNumbers'
                          onChange={(e) => {
                            setMobileNumbers(e.target.value)
                          }}
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {!isMobileNumerHasentErrror && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>Sin datos</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {typeSelected === 'file' && (
                    <div className='row mb-7'>
                      <div className='col-xl-6 mb-5'>
                        <label className='fw-bold fs-6 mb-2'>
                          <p>Seleccionar .csv</p>
                        </label>
                        <div>
                          <input
                            type={'file'}
                            id={'csvFileInput'}
                            accept={'.csv'}
                            onChange={handleOnChange}
                          />
                        </div>
                        <div>
                          <>
                            {fileData.join(',') === '' && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>Sin datos</span>
                                </div>
                              </div>
                            )}

                            {hasErrorFile && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>Algún número no coincide con el formato</span>
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              disabled={loading}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Descartar
            </button>
            <button
              type='button'
              className='btn btn-primary'
              disabled={loading}
              onClick={() => {
                if (
                  (typeSelected === 'text' && isMobileNumerHasentErrror) ||
                  (typeSelected === 'file' && !hasErrorFile && fileData.join(',') !== '')
                ) {
                  ;(async () => {
                    setLoading(true)
                    const dataResponse = await Api.AddPermission({
                      token: user.api_token,
                      id_user: userId,
                      campaingId: campaingId,
                      type: typeSelected,
                      fieldValue: typeSelected === 'text' ? mobileNumbers : fileData.join(','),
                    })
                    setShowModal(false)
                    if (dataResponse.success) setShowSuccessModal(true)
                    setLoading(false)
                    setMobileNumbers('')
                  })()
                }
              }}
            >
              {loading ? 'Cargando...' : 'Guardar'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        className='modal fade '
        show={showSuccessModal}
        aria-hidden='true'
        centered
        onHide={() => setShowSuccessModal(false)}
      >
        <div className='modal-content '>
          <div className='modal-body '>
            <img src={defaultProduct1} alt='' width='60' height='60'></img>
            <h5 className='fw-bolder fs-1 mb-5'>Operación Exitosa</h5>
            <div>
              <span>Se añadió exitosamente</span>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setIdNewStatus(null)
                setShowSuccessModal(false)
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {DetailPermissionsManager}
