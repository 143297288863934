import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {Link, useLocation} from 'react-router-dom'
import {changePassword, changePasswordAux} from '../core/_requests'
import {REQUIRED_FILED, REQUIRED_PASSWORD_MIN_FILED} from 'app/constants/errorConstants'

const loginSchema = Yup.object().shape({
  newPassword1: Yup.string()
    .required(REQUIRED_FILED)
    .min(8, REQUIRED_PASSWORD_MIN_FILED)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,20}$/,
      'Contraseña insegura, siga las recomendaciones de abajo'
    ),
  newPassword2: Yup.string()
    .required(REQUIRED_FILED)
    .min(8, REQUIRED_PASSWORD_MIN_FILED)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,20}$/,
      'Contraseña insegura, siga las recomendaciones de abajo'
    ),
})

const initialValues = {
  newPassword1: '',
  newPassword2: '',
}

export function ChangePassword() {
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [changePasswordStatus, setChangePasswordStatus] = useState(false)
  const [passwordsError, setPasswordsError] = useState(false)
  const [loginMessageError, setLoginMessageError] = useState()

  const user = (JSON.parse(localStorage.getItem('temp_user'))) ? JSON.parse(localStorage.getItem('temp_user')) : JSON.parse(localStorage.getItem('kt-auth-react-v'))
  let auxToken = localStorage.getItem('auxToken', -1)
  if (auxToken === null) auxToken = -1
  
  function GetURLParams(param) {
    const search = useLocation().search
    const params = new URLSearchParams(search)
    return params.get(param)
  }

  const resetToken = GetURLParams('q')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (values.newPassword1 === values.newPassword2) setLoading(true)
      setPasswordsError(values.newPassword1 !== values.newPassword2)
      if (values.newPassword1 === values.newPassword2)
        try {
          let dataF
          if (+auxToken === -1) {
            const {data} = await changePassword({
              newPassword: values.newPassword1,
              resetToken: resetToken,
            })
            dataF = data
          } else {
            const {data} = await changePasswordAux(
              {
                newPassword: values.newPassword1,
                idUser: user.id
              },
              auxToken
            )
            dataF = data
          }

          if (dataF?.success) {
            setChangePasswordStatus(true)
          } else {
            setLoginError(true)
            setLoginMessageError(dataF.message)
            setStatus(dataF.message)
            setSubmitting(false)
          }
          setLoading(false)
        } catch (error) {
          console.error(error)
          setStatus('The login detail is incorrect')
          setSubmitting(false)
          setLoading(false)
        }
    },
  })

  useEffect(() => {
    if (changePasswordStatus === true) localStorage.setItem('auxToken', -1)
  }, [changePasswordStatus])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Cambiar contraseña</h1>
        {+auxToken !== -1 && changePasswordStatus === false && (
          <div
            style={{
              padding: '1.2rem',
              borderRadius: '10px',
              border: '1px solid #F2F2F2',
              textAlign: 'left',
              backgroundColor: '#F2F2F2',
            }} 
          >
            <span>
              Por reglas de seguridad es necesario actualizar la contraseña:
              <ul>
                <li>En el primer acceso a la web.</li>
                <li>Cambiar como minimo cada 90 dias.</li>
              </ul>
            </span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Contraseña</label>
        <input
          placeholder='Ingrese su nueva contraseña'
          {...formik.getFieldProps('newPassword1')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.newPassword1 && formik.errors.newPassword1},
            {'is-valid': formik.touched.newPassword1 && !formik.errors.newPassword1}
          )}
          type='password'
          name='newPassword1'
          autoComplete='off'
        />
        {formik.touched.newPassword1 && formik.errors.newPassword1 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword1}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirme contraseña</label>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          name='newPassword2'
          placeholder='Confirme su contraseña'
          {...formik.getFieldProps('newPassword2')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.newPassword2 && formik.errors.newPassword2},
            {'is-valid': formik.touched.newPassword2 && !formik.errors.newPassword2}
          )}
        />
        {formik.touched.newPassword2 && formik.errors.newPassword2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword2}</span>
            </div>
          </div>
        )}

        <div className='fv-plugins-message-container pt-4'>
          <div className='fv-help-block'>
            <span role='alert' style={{fontStyle: 'normal', color: '#000', fontSize: '13px', fontWeight:'500'}}>
              La contraseña debe tener entre 8 y 20 caracteres, una mayuscula, una minuscula, un
              numero y un caracter especial (!, #, $, %, etc)
            </span>
          </div>
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Cargando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          {passwordsError && <span role='alert'>Las contraseñas deben coincidir</span>}
          {loginError && <span role='alert'>{loginMessageError}</span>}
          {changePasswordStatus && (
            <span  style={{color: '#000', fontSize: '14px', fontWeight:'600', lineHeight:'17px'}}>
              Contraseña cambiada satisfactoriamente,
              <Link
                style={{paddingLeft: '5px', paddingRight: '5px', lineHeight:'17px',paddingTop: '0px',paddingBottom: '3px'}}
                to='/auth/login'
                className='btn btn-bg-white text-primary'
              >
                Inicie Sesión
              </Link>
              nuevamente
            </span>
          )}
        </div>
      </div>
    </form>
  )
}
