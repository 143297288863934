import {useState, useEffect} from 'react'

const CallToActions = ({setCTA}) => {
  const [buttonTile1, setButtonTile1] = useState('')
  const [buttonValue1, setButtonValue1] = useState('')
  const [checked1, setChecked1] = useState(false)

  const [buttonTile2, setButtonTile2] = useState('')
  const [buttonValue2, setButtonValue2] = useState('')
  const [checked2, setChecked2] = useState(false)

  const errorInCTA1 = () => {
    if (checked1) {
      if (!!buttonTile1 && !!buttonValue1) return false
      else return true
    } else return false
  }

  const errorInCTA2 = () => {
    if (checked2) {
      if (!!buttonTile2 && !!buttonValue2) return false
      else return true
    } else return false
  }

  useEffect(() => {
    let jsCTA = []
    if (checked1) if (!errorInCTA1()) jsCTA = [{type: 'URL', text: buttonTile1, url: buttonValue1}]

    if (checked2)
      if (!errorInCTA2())
        jsCTA = [...jsCTA, {type: 'PHONE_NUMBER', text: buttonTile2, phone_number: buttonValue2}]

    setCTA(jsCTA)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonTile1, buttonValue1, checked1, buttonTile2, buttonValue2, checked2, setCTA])

  return (
    <>
      <div className='col-xl-12 bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
        <div className='row '>
          <div className='col-xl-12'>
            <span className='fs-6 text-gray-800 fw-semibold me-5 required'>Call to action 1</span>
            {errorInCTA1() && (
              <label className='' style={{color: 'red', fontSize: '10px'}}>
                Debes llenar todos los campos
              </label>
            )}
          </div>
          <div className='d-flex justify-content-start flex-wrap align-items-center align-self-center'>
            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='text'
                value={'URL'}
                disabled
              ></input>
            </div>

            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='text'
                placeholder='Button title'
                value={buttonTile1}
                onChange={(e) => setButtonTile1(e.target.value)}
                disabled={!checked1}
              ></input>
            </div>

            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='text'
                placeholder='Button value'
                value={buttonValue1}
                onChange={(e) => setButtonValue1(e.target.value)}
                disabled={!checked1}
              ></input>
            </div>

            <div className=''>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={checked1}
                  onChange={() => setChecked1(!checked1)}
                />
                <span className='form-check-label'>Habilitar</span>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-xl-12'>
            <span className='fs-6 text-gray-800 fw-semibold required me-5'>Call to action 2</span>
            {errorInCTA2() && (
              <label className='' style={{color: 'red', fontSize: '10px'}}>
                Debes llenar todos los campos
              </label>
            )}
          </div>
          <div className='d-flex justify-content-start flex-wrap align-items-center align-self-center'>
            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='text'
                value={'PHONE'}
                disabled
              ></input>
            </div>

            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='text'
                placeholder='Button title'
                value={buttonTile2}
                onChange={(e) => setButtonTile2(e.target.value)}
                disabled={!checked2}
              ></input>
            </div>

            <div className='me-5'>
              <input
                className='form-control  mb-3 mb-lg-0'
                type='number'
                placeholder='Button value'
                value={buttonValue2}
                onChange={(e) => setButtonValue2(e.target.value)}
                disabled={!checked2}
              ></input>
            </div>

            <div className='me-5'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={checked2}
                  onChange={() => setChecked2(!checked2)}
                />
                <span className='form-check-label'>Habilitar</span>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>
    </>
  )
}

export {CallToActions}
