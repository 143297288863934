import { useEffect, useState, useMemo } from 'react'
import { StudentsDashboardCards, StudentsDashboardTable, CampaignsList } from '../../../api/Api'
import { InfoCard } from 'app/components/infoCard/infoCard'
import { Link } from 'react-router-dom'
import { Table } from '../../../components/table/Table'
import { SelectList } from '../../../components/selectList/SelectList'
import { secondsToHms } from '../../../Utilities/Utils'

const StudentPage = ({ selected_customerIdSaved }) => {
  const [cardData, setCardData] = useState(null)
  const [tableData, setTableData] = useState([])
  const [campaing, setCampaing] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refrestCampaingList, setRefrestCampaingList] = useState(true)

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()

  useEffect(() => {
    
    console.log(campaing)
    if (campaing?.value) {
      setCampaing(campaing)
        ; (async () => {
          const cardDataResponse = await StudentsDashboardCards({
            token: user.api_token,
            campaingId: campaing.value,
            id_user: userId,
          })
          setCardData(cardDataResponse.data)
        })()
        ; (async () => {
          setLoading(true)
          const tableDataResponse = await StudentsDashboardTable({
            token: user.api_token,
            campaingId: campaing.value,
            id_user: userId,
          })
          setTableData(tableDataResponse.data.table)
          setLoading(false)
        })()
    }
  }, [campaing, user.api_token, userId, selected_customerIdSaved])
  
  useEffect(() => {
    setRefrestCampaingList(true)
  }, [selected_customerIdSaved])

  const columns = useMemo(
    () => [
      { Header: '', accessor: 'id_student', show: false },
      {
        Header: 'Datos personales',
        accessor: 'first_name',
        className: 'min-w-100px text-gray-800 fw-bolder',
      },
      { Header: '', accessor: 'last_name', show: false },
      { Header: '', accessor: 'email', show: false },
      {
        Header: 'Canal',
        accessor: 'phone',
        className: 'min-w-100px',
        Cell: ({ cell }) => (

          <span className='text-success fs-7 fw-bold'>

            <i
              className='text-success fa-regular fa-comments'
              style={{ paddingRight: 5 }}
            ></i>
            {cell.row.values.phone}
          </span>

        ),
      },
      {
        Header: 'Cursos inscritos',
        accessor: 'enrolled_courses',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },

      {
        Header: 'Cursos finalizados',
        accessor: 'completed_courses',
        className: 'min-w-100px text-center text-gray-800 fw-bolder',
      },
      {
        Header: 'Tiempo de estudio',
        accessor: 'time',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (

          <span className='text-info fs-7 fw-bold'>

            <i
              className='fa-regular fa-clock'
              style={{ paddingRight: 5 }}
            ></i>
            {secondsToHms(cell.row.values.time)}
          </span>

        ),
      },
      {
        Header: 'Ultima conexión',
        accessor: 'last_conection',
        className: 'min-w-100px text-right',
        Cell: ({ cell }) => <span className='text-right '>{cell.row.values.last_conection}</span>,
        sortType: (a, b) => {
          let firstDate = new Date(a.values.last_conection).getTime()
          let secondDate = new Date(b.values.last_conection).getTime()

          if (firstDate < secondDate) {
            return -1
          } else {
            return 1
          }
        }
      },
      {
        Header: '',
        accessor: 'actionver',
        className: 'min-w-100px text-center',
        Cell: ({ cell }) => (
          <Link
            to={`/detailStudent/${cell.row.values.id_student}/${campaing.label}/${campaing.value}`}
          >
            <button type='button' className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'>
              <i className="fa-duotone fa-eye"></i>
            </button>
          </Link>
        ),
      },
    ],
    [campaing?.label, campaing?.value]
  )

  return (
    <>
      <div className='app-container d-flex flex-stack mb-5'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
            Estudiantes
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3 pb-6'>
          <SelectList
            title={'Campaña'}
            apiService={CampaignsList}
            args={{ token: user.api_token }}
            conditions={refrestCampaingList}
            setRefresh={setRefrestCampaingList}
            handleChange={(company) => {
              console.log(company)
              setCampaing(company)
            }}
            value={campaing}
            multiple={false}
            defaultSetterFunction={setCampaing}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#000',
                font: '#000',
                primary25: '#f1f1f4',
                primary: '#3615af',
                neutral80: '#000',
                color: '#000',
              },
            })}
          />
        </div>
      </div>

      {cardData && (
        <>
          <div className='row g-5 g-xl-8 mb-5'>
            <div className='col-xl-3'>
              <InfoCard
                value={cardData.totalStudents}
                title='Total de estudiantes'
                link='#'
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-duotone fa-users text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={cardData.global_progress}
                title='Avance global'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-solid fa-arrow-up-right-dots text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={secondsToHms(cardData.average_time_per_student)}
                title='Tiempo promedio de estudio'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-regular fa-clock text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-3'>
              <InfoCard
                value={secondsToHms(cardData.studying_time)}
                title='Tiempo total de estudio'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-duotone fa-hourglass-start text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <Table
            className=''
            data={tableData}
            columns={columns}
            title='Tiempo de estudio'
            isLoading={loading}
          />
        </div>
      </div>
    </>
  )
}

export { StudentPage }
