import React from 'react'
import {Alert, AlertContainer} from 'react-bs-notifier'

const NotificationAlert = ({text, functionOpt}) => {
  setTimeout(() => {
    functionOpt('')
  }, 3000)

  return (
    <AlertContainer position={'top-right'} timeout={5000}>
      <Alert type='info'>{text || 'Alerta'}</Alert>
    </AlertContainer>
  )
}

export default NotificationAlert
