import {Table} from '../../../components/table/Table'

const CourseListComponent = ({
  loadingCourseList,
  courseListData,
  campaingNameSelected,
  columnsCourseList,
}) => {
  return (
    <>
      {!loadingCourseList ? (
        <>
          {courseListData ? (
            <div className='card card-flush'>
              <Table
                className='table-0-padding'
                title={
                  <div>
                    {campaingNameSelected ? (
                      <span className='fs-6 fw-bold text-gray-900 me-1 mb-2 lh-1'>
                        Cursos de <span className=' text-primary '>{campaingNameSelected}</span>
                      </span>
                    ) : (
                      <span>Seleccione una campaña</span>
                    )}
                  </div>
                }
                data={courseListData}
                columns={columnsCourseList}
                hidePagination={false}
                pgSize={5}
                hideFilter={false}
              />
            </div>
          ) : (
            <h4> No hay datos</h4>
          )}
        </>
      ) : (
        <div className='card'>
          <div className='card-body'>
            <div className='flex-column loadin-new'>
              <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
              <span className='text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {CourseListComponent}
