import React, {useEffect} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5wc from '@amcharts/amcharts5/wc'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

const WordCloudChart = ({
  id,
  title,
  className,
  dataGraph = null,
  width,
  height,
  subtitle,
  index,
}) => {
  const chartID = id
  useEffect(() => {
    let root

    if (dataGraph) {
      root = am5.Root.new(chartID+'-'+index)
      root.setThemes([am5themes_Animated.new(root)])

      let series = root.container.children.push(
        am5wc.WordCloud.new(root, {
          categoryField: 'tag',
          valueField: 'weight',
          maxFontSize: am5.percent(15),
        })
      )

      series.labels.template.setAll({
        fontFamily: 'Courier New',
      })

      setInterval(function () {
        am5.array.each(series.dataItems, function (dataItem) {
          let value = Math.random() * 65
          value = value - Math.random() * value
          dataItem.set('value', value)
          dataItem.set('valueWorking', value)
        })
      }, 5000)

      let data = dataGraph
      series.data.setAll(data)
    }

    return () => {
      root.dispose()
    }
  }, [chartID, dataGraph, index])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-muted fw-semobold fs-7'>{subtitle}</span>
        </h3>
      </div>
      <div className='card-body pt-0 pb-0'>
        <div id={chartID+'-'+index} style={{height: height, width: width}} />
      </div>
    </div>
  )
}

export {WordCloudChart}
