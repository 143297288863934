import { useMemo } from 'react'
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table'
import Filter from '../filter/Filter'
import './table.css'

const Table = ({
  idContent,
  columns,
  data,
  title,
  toolbar,
  subtitle,
  className,
  hideFilter = false,
  hidePagination = false,
  pgSize,
  pgIndex,
  trheadStyle = [],
  showCount = false,
  isLoading = false,
  customFilter = null,
}) => {
  columns = useMemo(() => columns, [columns])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pgSize ? pgSize : 10,
        pageIndex: pgIndex ? pgIndex : 0,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id
          else return null
        }),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex } = state

  if (trheadStyle?.length === 0) headerGroups.forEach(() => trheadStyle.push({}))

  return (
    <div className={`card ${className}`}>
      {title && (
        <div className='card-header border-0 pt-5'>
          <div className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-0'>
              {title}
              {showCount && <span className='ms-2'>  ({data.length})</span>}
            </span>


            {subtitle && <span className='text-muted mt-0 fw-semobold fs-7'>{subtitle}</span>}

            {isLoading && (
              
                <div className='flex-column pt-6'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2 text-primary'></span>
                  <span className='px-2 text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
                </div>
              
            )}

          </div>

          {toolbar && <div className='d-flex align-items-center'>{toolbar}</div>}
        </div>
      )}
      {!isLoading && (
        <div className='card-body pt-0 pb-0'>
          <div className='d-flex justify-content-between pb-2'>
            {!hideFilter && <Filter filter={globalFilter} setFilter={setGlobalFilter}></Filter>}
            {customFilter}
          </div>
          <div className='table-responsive'>
            <table
              {...getTableProps()}
              className='table align-middle table-row-dashed'
              id={idContent}
            >
              <thead className=''>
                {headerGroups.map((headerGroup, indexTr) => (
                  <tr
                    className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 '
                    style={trheadStyle[indexTr]?.length === 0 ? {} : trheadStyle[indexTr]}
                    key={indexTr}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, indexTh) => (
                      <th
                        key={indexTh}
                        {...column.getHeaderProps([
                          {
                            className: column.className,
                            style: column.style,
                          },
                        ])}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-arrow-down'
                                  viewBox='0 0 16 16'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16'
                                  height='16'
                                  fill='currentColor'
                                  className='bi bi-arrow-up'
                                  viewBox='0 0 16 16'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'
                                  />
                                </svg>
                              </>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className=''>
                {(page.length > 0 &&
                  page.map((row, indexRow) => {
                    prepareRow(row)
                    return (
                      <tr key={indexRow} className={'align-items-center'}>
                        {row.cells.map((cell, indexCell) => {
                          return (
                            <td
                              key={indexCell}
                              {...cell.getCellProps([
                                {
                                  style: cell.column.style,
                                },
                              ])}
                              className={
                                'align-items-center text-gray-600 fw-semibold ' +
                                cell.column.className
                              }
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })) || (
                    <tr key={'DataEmpyTR'}>
                      <td key={'DataEmpyTD'}>Sin Datos</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>

          {!hidePagination && (
            <>
              <button
                onClick={() => previousPage()}
                className='btn btn-link btn-color-gray-500  me-5 mb-2 btn-white'
                disabled={!canPreviousPage}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-arrow-left-short'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'
                  />
                </svg>
              </button>
              <span className='me-5 text-primary'>
                Paginas
                <strong>
                  {' ' + (~~pageIndex + 1).toString()} al {pageOptions.length + ' '}
                </strong>
              </span>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className='btn btn-link btn-color-gray-500  me-5 mb-2 btn-white'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-arrow-right-short'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'
                  />
                </svg>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export { Table }
