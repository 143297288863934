import {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { trimString } from 'app/Utilities/Utils'

const DobleBarChart = ({
  id,
  title,
  className,
  dataGraph = null,
  width,
  height,
  settings,
  typeAxisX = 'category',
  loading,
}) => {
  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart)

    if (dataGraph) {
      am4core.useTheme(am4themes_animated)
      chart.colors.list = settings.colors

      if (typeAxisX === 'category') {
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.startLocation = 0
        categoryAxis.renderer.cellStartLocation = 0.4
        categoryAxis.renderer.cellEndLocation = 0.6
        categoryAxis.renderer.labels.template.fontSize = 12
        categoryAxis.dataFields.category = settings.categoryField
        
        categoryAxis.renderer.labels.template.adapter.add("textOutput", function(text) {
          return trimString(text.charAt(0).toUpperCase() + text.slice(1), 20)
        });
        let label = categoryAxis.renderer.labels.template
        label.wrap = true
        label.maxWidth = 120
        categoryAxis.tooltip.label.maxWidth = 120
        categoryAxis.tooltip.label.wrap = true
      } else if (typeAxisX === 'date') {
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.renderer.grid.template.location = 0.5
        dateAxis.renderer.labels.template.location = 0.5
        dateAxis.dateFormatter = new am4core.DateFormatter()
        dateAxis.dateFormatter.dateFormat = 'dd-MM-yy'
        dateAxis.renderer.grid.template.strokeWidth = 0

        dateAxis.renderer.minGridDistance = 20

        dateAxis.renderer.cellStartLocation = 0.3
        dateAxis.renderer.cellEndLocation = 0.7
      }

      function createAxisAndSeries(field, name, axisY, opposite) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.min = 0
        if (chart.yAxes.indexOf(valueAxis) !== 0) {
          valueAxis.syncWithAxis = chart.yAxes.getIndex(0)
        }
        valueAxis.title.text = axisY.name
        valueAxis.title.rotation = 270
        valueAxis.title.align = 'center'
        valueAxis.title.valign = 'middle'
        
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
          return text + axisY.modifier
        })

        let series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = field
        if (typeAxisX === 'category') {
          series.dataFields.categoryX = settings.categoryField
          series.tooltipText = '{name}: [bold]{valueY}[/]'
        } else if (typeAxisX === 'date') {
          series.dataFields.dateX = settings.categoryField
          series.tooltipText = `{dateX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`
        }
        series.strokeWidth = 2
        series.columns.template.maxWidth = 20

        series.yAxis = valueAxis
        series.name = name

        series.tensionX = 0.8
        series.showOnInit = true

        valueAxis.renderer.line.strokeOpacity = 1
        valueAxis.renderer.line.strokeWidth = 2
        valueAxis.renderer.line.stroke = series.stroke
        valueAxis.renderer.labels.template.fill = series.stroke
        valueAxis.renderer.opposite = opposite
      }

      settings.series.forEach((serie, index) =>
        createAxisAndSeries(
          serie.field,
          serie.name,
          settings.axisY[index] ? settings.axisY[index] : settings.axisY[index - 1],
          serie.opposite
        )
      )

      chart.data = dataGraph

      chart.legend = new am4charts.Legend();

      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      chart.cursor = new am4charts.XYCursor()
    }

    return () => {
      chart.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
      </div>

      <div className='card-body pb-0 pt-4'>
        {!loading ? (
          <>
            {dataGraph ? (
              <div id={id} style={{width: width, height: height}} />
            ) : (
              <h3>Sin datos</h3>
            )}
          </>
        ) : (
          <>
            <div className='flex-column pb-4'>
              <span className='spinner-border spinner-border-sm align-middle text-primary'></span>
              <span className='px-2 text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {DobleBarChart}
