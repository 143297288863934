import { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

export function ChannelSelectList({ options, channelSelected, setChannelSelected }) {
  const animatedComponents = makeAnimated()

  const [optionList, setOptionList] = useState()

  const handleChange = (value) => {
    setChannelSelected(value)
  }

  useEffect(() => {
    setOptionList(options)
  }, [options])

  return (
    <Select
      classNamePrefix='filter'
      components={animatedComponents}
      // options={optionList}
      options={optionList}
      value={channelSelected}
      formatOptionLabel={channel => (
        <div className="country-option">
          <img src={channel.url} className='channel-icon' alt={channel.label} />
          <span>{channel.label}</span>
        </div>
      )}
      onChange={handleChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
        ...theme.colors,
        text: '#000',
        font:'#000',
        primary25: '#f1f1f4',
        primary: '#3615af',
        neutral80: '#000',
        color: '#000',
        },
      })}
    />
  )
}