import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

const SlicedChart = ({
  id,
  title,
  subtitle,
  noDataMessage,
  className,
  dataGraph = null,
  width,
  height,
  settings,
}) => {
  useEffect(() => {
    if (dataGraph) {
      let chart = am4core.create(id, am4charts.SlicedChart)

      am4core.useTheme(am4themes_animated)

      chart.hiddenState.properties.opacity = 0
      chart.colors.list = settings.colors

      chart.data = dataGraph.map((el, index) => ({
        ...el,
        color: settings.colors[index] || am4core.color('black'),
      }))

      let series = chart.series.push(new am4charts.FunnelSeries())
      series.colors.step = 1
      series.dataFields.value = 'value'
      series.dataFields.category = 'category'
      series.labels.template.disabled = true
      series.orientation = 'horizontal'
      series.bottomRatio = 1
      series.slices.template.propertyFields.fill = 'color'

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'right'
      chart.legend.valign = 'top'
      return () => {
        chart.dispose()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {dataGraph.length > 0 && <span className='text-muted fw-semobold fs-7'>{subtitle}</span>}
        </h3>

        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          ></button>
        </div>
      </div>

      <div className='card-body pt-0'>
        {dataGraph.length > 0 ? (
          <div id={id} style={{ width: width, height: height }}></div>
        ) : (
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 text-center">

            <div className=" fw-semibold">
              <div className="text-gray-900 fw-bold">{noDataMessage}</div>
            </div>

          </div>
        )}
      </div>
    </div>
  )
}

export { SlicedChart }
