import {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { trimString } from 'app/Utilities/Utils'

const BarChart = ({
  id,
  title,
  className,
  dataGraph = null,
  width,
  height,
  settings,
  info,
  typeAxisX = 'category',
}) => {
  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart)
    chart.dateFormatter.dateFormat = 'dd-MM-yy'

    if (dataGraph) {
      am4core.useTheme(am4themes_animated)
      chart.colors.list = settings.colors

      if (typeAxisX === 'category') {
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.startLocation = 0
        categoryAxis.renderer.cellStartLocation = 0.3
        categoryAxis.renderer.cellEndLocation = 0.7
        categoryAxis.dataFields.category = settings.categoryField
        let label = trimString(categoryAxis.renderer.labels.template, 20)
        label.wrap = true
        label.maxWidth = 120
        categoryAxis.tooltip.label.maxWidth = 120
        categoryAxis.tooltip.label.wrap = true
        categoryAxis.renderer.minGridDistance = 50
      } else if (typeAxisX === 'date') {
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.renderer.grid.template.location = 0.5
        dateAxis.renderer.labels.template.location = 0.5
        dateAxis.dateFormatter = new am4core.DateFormatter()
        dateAxis.dateFormatter.dateFormat = 'dd-MM-yy'

        dateAxis.renderer.minGridDistance = 50
      }

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0

      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = 'value'
      if (typeAxisX === 'category') {
        series.dataFields.categoryX = settings.categoryField
        series.tooltipText = `{categoryX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`

        if (settings.transformValueForTooltip) {
          series.adapter.add('tooltipText', function (text, target) {
            if (target.dataItem) {
              const value = target.dataItem.values.valueY.sum
              return `{categoryX}: [bold]${settings.transformValueForTooltip(value)}[/] ${
                settings.tooltipAf || ''
              }`
            }
            return text
          })
        } else {
          series.tooltipText = `{categoryX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`
        }
      } else if (typeAxisX === 'date') {
        series.dataFields.dateX = settings.categoryField
        series.tooltipText = `{dateX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`
      }
      series.strokeWidth = 2
      series.columns.template.maxWidth = 80
      series.tensionX = 0.8
      series.showOnInit = true

      chart.data = dataGraph
      chart.cursor = new am4charts.XYCursor()
    }

    return () => {
      chart.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>

        {info ?? <></>}
      </div>

      <div className='card-body pt-0 pb-0'>
        <div id={id} style={{width: width, height: height}} />
      </div>
    </div>
  )
}

export {BarChart}
