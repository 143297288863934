import {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { trimString } from 'app/Utilities/Utils'

const LineChart = ({
  id,
  title,
  subtitle,
  className,
  dataGraph = null,
  width,
  height,
  settings,
  info,
  typeAxisX = 'category',
  loading,
}) => {
  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart)
    chart.dateFormatter.dateFormat = 'dd-MM-yy'

    if (dataGraph) {
      am4core.useTheme(am4themes_animated)
      chart.colors.list = settings.colors

      if (typeAxisX === 'category') {
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.startLocation = 0
        categoryAxis.renderer.cellStartLocation = 0.3
        categoryAxis.renderer.cellEndLocation = 0.7
        categoryAxis.dataFields.category = settings.categoryField
        let label = trimString(categoryAxis.renderer.labels.template, 20)
        label.wrap = true
        label.maxWidth = 120
        categoryAxis.tooltip.label.maxWidth = 120
        categoryAxis.tooltip.label.wrap = true
        categoryAxis.renderer.minGridDistance = 50
      } else if (typeAxisX === 'date') {
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.renderer.grid.template.location = 0.5
        dateAxis.renderer.labels.template.location = 0.5
        dateAxis.dateFormatter = new am4core.DateFormatter()
        dateAxis.dateFormatter.dateFormat = 'dd-MM-yy'
        dateAxis.renderer.grid.template.strokeWidth = 0

        dateAxis.renderer.minGridDistance = 50
      }

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0

      let series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      if (typeAxisX === 'category') {
        series.dataFields.categoryX = settings.categoryField
        series.tooltipText = `{categoryX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`
      } else if (typeAxisX === 'date') {
        series.dataFields.dateX = settings.categoryField
        series.tooltipText = `{dateX}: [bold]{valueY}[/] ${settings.tooltipAf || ''}`
      }
      series.strokeWidth = 2
      // series.columns.template.maxWidth = 80
      series.tensionX = 0.8
      series.showOnInit = true

      chart.data = dataGraph
      chart.cursor = new am4charts.XYCursor()
    }

    return () => {
      chart.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraph])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-gray-400 mt-1 fw-semibold fs-6'>{subtitle}</span>
        </h3>
      </div>

      <div className='card-body pb-0 pt-4'>
        {!loading ? (
          <>
            {info ?? <></>}
            {dataGraph ? (
              <div id={id} style={{width: width, height: height}} />
            ) : (
              <h3>Sin datos</h3>
            )}
          </>
        ) : (
          <>
            <div className='flex-column pb-4'>
              <span className='spinner-border spinner-border-sm align-middle text-primary'></span>
              <span className='px-2 text-muted fs-6 fw-semibold mt-5'>Cargando... </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {LineChart}
