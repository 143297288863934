import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import * as Api from 'app/api/Api'
import {useState, useEffect, useMemo} from 'react'
import {Table} from '../../../components/table/Table'
import defaultProduct from '../../../../media/default-product.jpg'
const DetailNotification = () => {
  const company = JSON.parse(useParams().company)
  const campaing = JSON.parse(useParams().campaing)
  const [tableData, setTableData] = useState([])

  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  const userId = user.id.toString()
  const isAdmin = user.role.some(item => item.name === 'Administrator')

  const getNotificationsData = async (params) => {
    const tableDataResponse = await Api.TableNotifications(params)
    setTableData(tableDataResponse.data)
  }

  const handleRemove = async (notificationId) => {
    const responseRemove = await Api.RemoveNotification({
      notificationId,
      token: user.api_token
    })
    if (company?.value && campaing?.id) {
      getNotificationsData({
        token: user.api_token,
        campaingId: campaing.id,
        id_user: userId,
        typeView: '1',
      })
    }
    return responseRemove
  }
  
  const handleDownloadRecipients = async (notificationId, notificationName) => {
    const reponseDownload = await Api.DownloadRecients({
      notificationId,
      token: user.api_token
    })

    const currentDate = new Date()
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const fileName = `Destinatarios_Notificacion_${currentDate.getFullYear()}${currentMonth}${currentDate.getDate()}`
    const blob = new Blob([reponseDownload.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click()
    return reponseDownload
  }

  useEffect(() => {
    if (company?.value && campaing?.id) {
      getNotificationsData({
        token: user.api_token,
        campaingId: campaing.id,
        id_user: userId,
        typeView: '1',
      })
      // ;(async () => {
      //   const tableDataResponse = await Api.TableNotifications({
      //     token: user.api_token,
      //     campaingId: campaing.id,
      //     id_user: userId,
      //     typeView: '1',
      //   })
      //   setTableData(tableDataResponse.data)
      // })()
    }
  }, [company?.value, campaing?.id, user.api_token, userId])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        className: 'min-w-100px',
        show: false,
      },
      {
        Header: 'IMAGEN',
        accessor: 'image_url',
        className: '',
        Cell: ({cell}) => {
          let urlValidate = cell.row.values.image_url
          if (!!cell.row.values.image_url)
            if (
              !cell.row.values.image_url.includes('.jpg') &&
              !cell.row.values.image_url.includes('.png') &&
              !cell.row.values.image_url.includes('.jpge')
            )
              urlValidate = null

          return <div className="symbol symbol-50px">
          <img src={urlValidate || defaultProduct} alt='' width='40' height='40'></img>
       </div>
        },
      },
      {
        Header: 'NOMBRE NOTIFICACIÓN',
        accessor: 'name',
        className: '',
      },
      {
        Header: 'TIPO',
        accessor: 'type',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <div className="badge badge-light fw-bold" style={{textTransform:'capitalize' }}>{cell.row.values.type}</div>
        ),
       
      },
      {
        Header: 'CREACIÓN',
        accessor: 'date_created',
        className: '',
        Cell: ({cell}) => (
          <div className="" >{cell.row.values.date_created}</div>
        ),
      },
      {
        Header: 'ALCANCE',
        accessor: 'alcance',
        className: 'min-w-100px text-center',
        Cell: ({cell}) => (
          <div className="text-center" >   <i className="fa-solid fa-bullhorn me-3"></i>  {cell.row.values.alcance}</div>
        ),
      },
      {
        Header: 'ALCANCE 24H',
        accessor: 'interacccion_usuarios_24h',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <div className="text-center" >   <i className="fa-solid fa-bullhorn me-3"></i>  {cell.row.values.interacccion_usuarios_24h}</div>
        ),
      },
      {
        Header: 'Es programado',
        accessor: 'is_schedule',
        className: '',
      },
      {
        Header: 'Fecha programado',
        accessor: 'date_schedule',
        className: '',
      },
      {
        Header: 'Estado',
        accessor: 'statusSend',
        className: 'min-w-100px',
        Cell: ({cell}) => (
          <span>
            {cell.row.values.statusSend === 'E'
              ? <div className="badge badge-light-success" style={{textTransform:'capitalize' }}>Enviado</div>
              : cell.row.values.statusSend === 'P'
              ? <div className="badge badge-light-warning" style={{textTransform:'capitalize' }}>Pendiente</div>
              : ''}
          </span>
        ),
      },
      {
        Header: 'Acciones',
        accessor: '',
        className: 'min-w-100px',
        Cell: ({cell}) => {
          return (
            <div className='d-flex'>
              <button type='button' className='btn btn-sm fw-bold btn-primary' onClick={() => handleDownloadRecipients(cell.row.values.id, cell.row.values.name)}>
                Descargar
              </button>
              {
                (cell.row.values.is_schedule === "SI" && isAdmin) ?
                  <button type='button' className='btn btn-sm fw-bold btn-danger' onClick={() => handleRemove(cell.row.values.id)}>
                    Eliminar
                  </button>
                : ''
              }
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className=''>
          <Link
            to={`/apps/notification-management/notification`}
            className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
            onClick={() => sessionStorage.setItem('defaultActiveKey', 'cursos')}
          >
        <i className="fa-solid fa-arrow-left"></i>
            <span className='fw-bold '>Regresar</span>
          </Link>
        </div>
        <div>
          <Link to={`/apps/template-list/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}>
            <button type='button' className='btn btn-sm fw-bold btn-success'>
              <span className='fw-bold '>Lista de templates</span>
            </button>
          </Link>

          <Link
            to={`/apps/notification-create/${JSON.stringify(company)}/${JSON.stringify(campaing)}`}
          >
            <button
              style={{marginLeft: 15}}
              type='button'
              className='btn btn-sm fw-bold btn-primary'
            >
              <span className='fw-bold '>Crear Notificación</span>
            </button>
          </Link>
        </div>
      </div>
      <>
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-6'>
          Notificaciones: {company.label} / {campaing.name}
        </h1>
        {tableData && <Table className='' data={tableData} columns={columns} />}
      </>
    </>
  )
}

export {DetailNotification}
