export default function Filter({ filter, setFilter, autoFocus = false, searchTypeInput }) {

  return (
    <div className="d-flex align-items-center position-relative my-1">

      <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
          <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
        </svg>
      </span>

      <input autoFocus={autoFocus} type={searchTypeInput || "text"} className="form-control form-control-solid w-250px ps-14" name="search" value={filter || ''} onChange={e => {
        setFilter(e.target.value)
      }}
        placeholder="Buscar" />
    </div>

  )
}