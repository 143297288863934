const ThanksPage = () => {

  return (
    <>
<div className="card border-transparent">

<div className="card-body">
<div className="text-center pb-15 px-5">
<img src='/media/cargando.png' alt='' /> 
</div>
</div>
</div>
    </>
  )
}

export {ThanksPage}
