import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as Api from 'app/api/Api'
import { removeDecimals } from 'app/Utilities/Utils'

const DetailStudent = () => {
  const { id, campaingLabel, campaingId } = useParams()
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const [profileData, setProfileData] = useState(null)

  useEffect(() => {
    ; (async () => {
      const profileDataResponse = await Api.ProfileStudent({
        idStudent: id,
        campaingId: campaingId,
        token: user.api_token,
      })
      setProfileData(profileDataResponse.data)
    })()
  }, [campaingId, id, user.api_token])

  return (
    <>
      <Link
        to={`/estudiantes`}
        className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
        onClick={() => sessionStorage.setItem('defaultActiveKey', 'estudiantes')}
      >
        <i className='fa-solid fa-arrow-left'></i>
        <span className='fw-bold '>Regresar</span>
      </Link>

      {profileData && (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-60px symbol-lg-60px symbol-fixed position-relative'>
                    <img src='/media/avatars/avatar.jpg' alt='' />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='/#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          {profileData.fullName}
                        </a>
                        <a href='/#'>
                          <i className='ki-duotone ki-verify fs-1 text-primary'></i>
                        </a>
                        <a
                          href='/#'
                          className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                        >
                          Oficial
                        </a>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <label className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className='ki-duotone ki-profile-circle fs-4 me-1'></i>Campaña:{' '}
                          <span className='fw-bolder fs-6 text-dark ms-2'> {campaingLabel}</span>
                        </label>
                        <label className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className='ki-duotone ki-geolocation fs-4 me-1'></i>Empresa:{' '}
                          <span className='fw-bolder fs-6 text-dark ms-2'>
                            {profileData.company}
                          </span>
                        </label>
                        <label className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                          <i className='ki-duotone ki-sms fs-4 me-1'></i>Email:
                          <span className='fw-bolder fs-6 text-dark ms-2'>{profileData.email}</span>
                        </label>
                      </div>
                      <div className="d-flex flex-wrap flex-stack">
                        <div className="d-flex flex-column flex-grow-1 pe-8">
                          <div className="d-flex flex-wrap">
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                              <div className="d-flex align-items-center">
                                <i className="fa-light fa-pencil fs-3 text-success me-2"></i>
                                <div className="fs-2 fw-bold counted">{profileData.cursos_inscritos}</div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-500">Cursos inscritos</div>

                            </div>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                              <div className="d-flex align-items-center">
                                <i className="fa-solid fa-flag-checkered fs-3 text-success me-2"></i>
                                <div className="fs-2 fw-bold counted">  {profileData.cursos_finalizados}</div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-500">Cursos finalizados</div>

                            </div>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                              <div className="d-flex align-items-center">
                                <i className="fa-regular fa-clock fs-3 text-success me-2"></i>
                                <div className="fs-2 fw-bold counted">  {profileData.tiempo_estudio} min</div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-500">Tiempo de estudio</div>

                            </div>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

                              <div className="d-flex align-items-center">
                                <i className="fa-duotone fa-star-sharp-half-stroke fs-3 text-success me-2"></i>
                                <div className="fs-2 fw-bold counted">{removeDecimals(profileData.avance)} %</div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-500">Avance</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row g-5 g-xl-10'>
          <div className='col-xl-6 '>
                  <div className='card '>
                    <div className='card-body'>
                    <div className='text-dark fw-bold fs-3 my-0 flex-column justify-content-center pb-2'> Detalles</div>
                     
                      <div className='m-0'>
                        <div className='d-flex flex-stack pb-3'>
                    
                            <span className='fw-bold fs-6 text-gray-800'>
                             Canal
                            </span>
                            <span className='text-success fs-5 fw-semibold'>

            <i
              className='text-success fa-regular fa-comments  text-success '
              style={{paddingRight: 5}}
            ></i>
   +{profileData.whatsapp}
          </span>
                    
                        </div>
                        <div className='d-flex flex-stack pb-3'>
                    
                    <span className='fw-bold fs-6 text-gray-800'>
                    Ultima conexión
                    </span>
                    <span className='text-gray-600 fs-5 fw-semibold'>
                    {profileData.ultima_conexion}
  </span>
            
                </div>
                <div className='d-flex flex-stack pb-3'>
                    
                    <span className='fw-bold fs-6 text-gray-800'>
                    Tiempo promedio
                    </span>
                    <span className='text-gray-600 fs-5 fw-semibold'>
                    {profileData.tiempo_promedio} min
  </span>
            
                </div>
                        
                        
                       
                      </div>
                    </div>
                  </div>
                </div>
            <div className='col-xl-6'>
              <div className='card'>
                <div className='card-body'>
                  <div className='text-dark fw-bold fs-3 my-0 flex-column justify-content-center'> Números de cursos en actividad: {profileData.cursos_activos}</div>
                  <div className='m-0'>
                    <table className='table align-middle table-row-dashed'>
                      <>
                        {profileData?.cursos?.length > 0 ? (
                          <>
                            <thead>
                              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 '>
                                <th className='min-w-100px'>Curso</th>
                                <th className='text-center min-w-100px'>Avance</th>
                                <th className='text-center min-w-50px'>Certificado</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profileData.cursos?.map((not, ind) => (
                                <tr key={`${not.nombre - ind}`}>
                                  <td className='min-w-100px text-gray-800 fw-bolder' maxlength="12">{not.nombre.substring(0, 45)}...</td>
                                  <td className='align-items-center text-gray-600 fw-semibold text-center'>
                                    {removeDecimals(not.avance)} %
                                  </td>
                                  <td className='text-center'>
                                    {!!not.certificate ? (
                                      <a
                                        target='_blank'
                                        href={not.certificate}
                                        download={not.nombre}
                                        rel='noreferrer'
                                      >
                                        <button type='button' className='btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3'>
                                          <i className="fa-duotone fa-down-to-line"></i>
                                        </button>
                                      </a>
                                    ) : (
                                      <span className='badge badge-light-danger'>
                                        No disponible
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <span>Sin Datos</span>
                          </>
                        )}
                      </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { DetailStudent }
