import {useEffect, useState} from 'react'
import * as Api from 'app/api/Api'
import * as am4 from '@amcharts/amcharts4/core'
import {BarChart} from 'app/components/charts/barChart'

const FinalitationCharts = ({id}) => {
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const [graphDataHorasFin, setGraphDataHorasFin] = useState(null)
  const [graphDataDiasFin, setGraphDataDiasFin] = useState(null)

  useEffect(() => {
    ;(async () => {
      const apiResponse = await Api.HorasFinalizacionByCourse({courseId: id, token: user.api_token})
      if (apiResponse.success) setGraphDataHorasFin(apiResponse.data?.graph)
    })()
    ;(async () => {
      const apiResponse = await Api.DiasFinalizacionByCourse({courseId: id, token: user.api_token})
      setGraphDataDiasFin(apiResponse.data?.graph)
    })()
  }, [id, user.api_token])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          {graphDataDiasFin ? (
            <BarChart
              id={'diasFinalizacionChart-Course'}
              title={'Días de Finalización'}
              className={''}
              height={'350px'}
              settings={{categoryField: 'day', colors: [am4.color('#3615af')]}}
              dataGraph={graphDataDiasFin}
            />
          ) : (
            <h4>Dias de Finalizacion: No hay datos para este curso</h4>
          )}
        </div>
        <div className='col-xl-6'>
          {graphDataHorasFin ? (
            <BarChart
              id={'horasFinalizacionChart-Course'}
              title={'Horas de Finalización'}
              className={''}
              height={'350px'}
              settings={{categoryField: 'hour', colors: [am4.color('#00CA92')]}}
              dataGraph={graphDataHorasFin}
            />
          ) : (
            <h4>Horas de Finalizacion: No hay datos para este curso</h4>
          )}
        </div>
      </div>
    </>
  )
}

export {FinalitationCharts}
