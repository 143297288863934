import {useParams} from 'react-router-dom'
import {InfoCardsByCourse} from './infoCardsByCourse'
import {FinalitationCharts} from './finalitationCharts'
import {Link} from 'react-router-dom'
import {StudientsByCourseTable} from './studientsByCourseTable'
import {OpenAndCloseQuestions} from './openAndCloseQuestions'

const DetailCourse = () => {
  const {id} = useParams()

  return (
    <>
      <Link
        to={`/cursos`}
        className='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary mb-6'
        onClick={() => sessionStorage.setItem('defaultActiveKey', 'cursos')}
      >
          <i className="fa-solid fa-arrow-left"></i>
        <span className='fw-bold '>Regresar</span>
      </Link>
      <InfoCardsByCourse id={id} />
      <FinalitationCharts id={id} />
      <StudientsByCourseTable id={id} />
      <OpenAndCloseQuestions id={id} />
    </>
  )
}

export {DetailCourse}
