import {useEffect, useState} from 'react'
import * as Api from 'app/api/Api'
import {InfoCard} from 'app/components/infoCard/infoCard'

const InfoCardsByCourse = ({id}) => {
  const user = JSON.parse(localStorage.getItem('kt-auth-react-v'))

  const [cardData, setCardData] = useState(null)

  useEffect(() => {
    ;(async () => {
      const cardDataResponse = await Api.CardsInfoByCourse({courseId: id, token: user.api_token})
      setCardData(cardDataResponse.data)
    })()
  }, [id, user.api_token])

  return (
    <>
      {cardData && (
        <>
          <div className='d-flex fs-4' style={{marginBottom: 20}}>
            <span className='fs-4 text-dark' style={{marginRight: 5}}>
              Curso:
            </span>
            <div className='position-relative d-inline-block'>
              {' '}
              <span className='link-black fw-bold d-block mb-1'>{cardData.courseName}</span>
            </div>
          </div>
          <div className='row g-5 g-xl-8 mb-10'>
            <div className='col-xl-4'>
              <InfoCard
                value={cardData.finalizacion.value}
                aditionalChart={'%'}
                title='Finalizacion'
                link='#'
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-solid fa-flag-checkered text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-4'>
              <InfoCard
                value={cardData.satisfaccion.value}
                title='Satisfacción'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-regular fa-heart text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>

            <div className='col-xl-4'>
              <InfoCard
                value={cardData.notaPromedio.value}
                aditionalChart={'%'}
                title='Enganche'
                link={'#'}
                classes={{
                  cardClasses: 'card bg-body-white',
                  iconClasses: 'fa-regular fa-heart-up text-primary fs-2',
                  titleClasses: 'fw-bold mb-1 fs-6',
                  value1Classes: 'text-gray-900 fw-bolder',
                  value2Classes: 'text-gray-400 fw-bold',
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {InfoCardsByCourse}
